import { forwardRef, useEffect, useMemo } from "react"
import { AhoyEventTypeEnum, TierLevelEnum } from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { Button, buttonVariants } from "~/shadcn/ui/button"
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "~/shadcn/ui/card"
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from "~/ui/DescriptionList"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { Skeleton } from "~/shadcn/ui/skeleton"
import { formatCalendarDate } from "~/common/formatDate"
import { formatDate } from "date-fns"
import { stripePortalPath } from "~/common/paths"
import { Link } from "~/ui/Link"
import { useSubscription } from "./SubscriptionProvider"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { SubscriptionOverview } from "./SubscriptionOverview"
import { useTiers } from "~/tiers/TiersProvider"

export const SubscriptionManagementModule = forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { logEvent } = useLogEvent()
    const { subscription, setSubscription, openSubscriptionWizard } =
      useSubscription()
    const { currentUser } = useCurrentUser()
    const { data, loading } = useQuery(FETCH_SUBSCRIPTION, {
      variables: { id: currentUser.id },
    })
    const { formatTierName } = useTiers()

    useEffect(() => {
      setSubscription(data?.user?.activeStripeSubscription || null)
    }, [data, setSubscription])

    const startDate = useMemo(() => {
      if (loading) return <Skeleton />
      if (!subscription?.startDate) return "N/A"

      return formatCalendarDate(subscription.startDate, "MMM d, yyyy")
    }, [subscription, loading])

    const handleManageSubscriptionClicked = () => {
      logEvent(AhoyEventTypeEnum.ManageSubscriptionClicked)
      openSubscriptionWizard("IntakeStep")
    }

    const handleUpgradeClicked = () => {
      openSubscriptionWizard("PricingTableStep", {
        source: "AccountSettingsScreen",
      })
    }

    return (
      <Card ref={ref}>
        <CardHeader>
          <CardTitle>Manage Membership</CardTitle>
        </CardHeader>
        <CardContent className="pt-4 flex flex-col gap-4 text-xs">
          <DescriptionList>
            <DescriptionTerm>Member Since</DescriptionTerm>
            <DescriptionDetails>{startDate}</DescriptionDetails>
            <DescriptionTerm>Membership Tier</DescriptionTerm>
            <DescriptionDetails>
              {formatTierName(currentUser.tier, currentUser.tierInterval)}
            </DescriptionDetails>
            {subscription?.cancelAt && (
              <>
                <DescriptionTerm>Expires On</DescriptionTerm>
                <DescriptionDetails>
                  {formatDate(subscription.cancelAt, "MMM d, yyyy")}
                </DescriptionDetails>
              </>
            )}
          </DescriptionList>
          {currentUser.tier?.level !== TierLevelEnum.Free && (
            <SubscriptionOverview />
          )}
        </CardContent>
        <CardFooter>
          <div className="flex gap-4 items-center">
            {subscription && currentUser.tier?.level !== TierLevelEnum.Free && (
              <Button
                variant="default"
                onClick={handleManageSubscriptionClicked}
                disabled={loading}
              >
                {loading ? "Loading..." : "Manage Subscription"}
              </Button>
            )}
            {!subscription && (
              <Link
                className={buttonVariants({ variant: "default" })}
                href={stripePortalPath.pattern}
                method="POST"
                onClick={() => {
                  logEvent(AhoyEventTypeEnum.ManageSubscriptionClicked)
                }}
                requestBody={{ return_url: window.location.href }}
              >
                Manage on Stripe
              </Link>
            )}

            {currentUser.tier?.level !== TierLevelEnum.Pro && (
              <Button
                type="button"
                onClick={handleUpgradeClicked}
                disabled={loading}
                variant="success"
              >
                Upgrade
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
    )
  }
)

const FETCH_SUBSCRIPTION = gql(`
  query FetchSubscription($id: ID!) {
    user(userId: $id) {
      activeStripeSubscription {
        ...StripeSubscriptionWithoutStatusHistory
      }
    }
  }
`)
