import { User } from "~/__generated__/graphql"
import CoachHatIcon from "~/images/icons/coach-hat.svg?react"

type UserFragment = Pick<User, "name" | "coach" | "admin">

interface UserNameProps {
  user: UserFragment
  formatter?: (userName: string) => React.ReactNode
  customName?: React.ReactNode | string
}

export const UserName = ({ user, formatter, customName }: UserNameProps) => {
  const name = customName || user.name
  return (
    <span className="inline-flex gap-2 items-center">
      {formatter ? formatter(user.name!) : name}
      {user.coach && !user.admin && <CoachHatIcon />}
    </span>
  )
}
