import React, {
  Children,
  createContext,
  forwardRef,
  isValidElement,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import { Transition } from "@headlessui/react"
import { Dialog, DialogContent } from "~/shadcn/ui/dialog"
import { cn } from "~/common/shadcn-utils"
import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons"
import { Button } from "~/shadcn/ui/button"

type WizardContextProps = {
  currentStep: number
  next: () => void
  previous: () => void
  back: () => void
  goToStep: (
    step: number | string,
    direction?: "forward" | "backward",
    saveInHistory?: boolean
  ) => void
  open: boolean
  close: () => void
  meta: { [key: string]: any }
  addToMeta: (key: string, value: any) => void
}
const WizardContext = createContext<WizardContextProps | null>(null)

export const useWizard = () => {
  const context = useContext(WizardContext)
  if (!context) {
    throw new Error("useWizard must be used within a Wizard")
  }
  return context
}

export interface WizardProps extends React.ComponentProps<typeof Dialog> {
  children: React.ReactNode
  step?: number | string
  dialogContentProps?: React.ComponentProps<typeof DialogContent>
  withKeyboardControl?: boolean
  withArrowNavigation?: boolean
}

export type WizardHandle = {
  open: boolean
  next: () => void
  previous: () => void
  back: () => void
  goToStep: (
    step: number | string,
    direction?: "forward" | "backward",
    saveInHistory?: boolean
  ) => void
  close: () => void
  meta: { [key: string]: any }
  addToMeta: (key: string, value: any) => void
}

export const Wizard = forwardRef<WizardHandle, WizardProps>(
  (
    {
      children,
      open,
      step = 0,
      dialogContentProps,
      withKeyboardControl = false,
      withArrowNavigation = false,
      ...props
    },
    ref
  ) => {
    const currentStepRef = useRef<HTMLDivElement>(null)
    const dialogContentRef = useRef<HTMLDivElement>(null)
    const steps = useMemo(
      () => Children.toArray(children).filter((child) => isValidElement(child)),
      [children]
    )
    const [meta, setMeta] = useState<{ [key: string]: any }>({})
    const [history, setHistory] = useState<number[]>([])
    const stepIndex = useMemo(() => {
      if (typeof step === "string") {
        return steps.findIndex(
          (child) =>
            isValidElement(child) && (child.type as any).displayName === step
        )
      }
      return step
    }, [step, steps])
    const [currentStep, setCurrentStep] = useState(stepIndex)
    const [currentStepDirection, setCurrentStepDirection] = useState<
      "forward" | "backward" | null
    >(null)
    const [isTransitionEnabled, setIsTransitionEnabled] = useState(false)

    useEffect(() => {
      if (open && step !== undefined) {
        setCurrentStep(stepIndex)
      }
    }, [step, open, stepIndex])

    const addToMeta = useCallback((key: string, value: any) => {
      setMeta((meta) => ({ ...meta, [key]: value }))
    }, [])
    const goToStep = useCallback(
      (
        step: number | string,
        direction: "forward" | "backward" = "forward",
        saveInHistory: boolean = true,
        enableTransition: boolean = true
      ) => {
        let stepIndex = typeof step === "string" ? -1 : step
        if (typeof step === "string") {
          stepIndex = steps.findIndex(
            (child) =>
              isValidElement(child) && (child.type as any).displayName === step
          )
        }

        setIsTransitionEnabled(enableTransition)
        setCurrentStepDirection(direction)
        setCurrentStep((currentStep) => {
          if (saveInHistory && stepIndex !== currentStep) {
            setHistory((history) => {
              if (direction === "forward") {
                return [...history, stepIndex]
              } else {
                const mostRecentStepIndex = history.findLastIndex(
                  (historicalStep) => historicalStep === stepIndex
                )
                return mostRecentStepIndex > -1
                  ? history.slice(0, mostRecentStepIndex + 1)
                  : [...history, mostRecentStepIndex]
              }
            })
          }

          return stepIndex
        })
      },
      [steps]
    )
    const next = useCallback(() => {
      goToStep(Math.min(currentStep + 1, steps.length - 1))
    }, [steps, currentStep, goToStep])
    const previous = useCallback(() => {
      goToStep(Math.max(currentStep - 1, 0), "backward")
    }, [goToStep, currentStep])
    const back = useCallback(() => {
      if (history.length > 1) {
        goToStep(history[history.length - 2], "backward")
      }
    }, [goToStep, history])
    const close = useCallback(() => {
      if (props.onOpenChange) props.onOpenChange(false)
    }, [props])

    const setDimensions = useCallback(() => {
      if (
        !currentStepRef.current ||
        !dialogContentRef.current ||
        dialogContentProps?.variant === "gallery"
      )
        return

      dialogContentRef.current.style.width = `${currentStepRef.current.offsetWidth}px`
      dialogContentRef.current.style.height = `${currentStepRef.current.offsetHeight}px`
    }, [dialogContentProps])

    useEffect(() => {
      if (!open) return
      setImmediate(setDimensions)
    }, [currentStep, open, setDimensions])

    useEffect(() => {
      const handleWindowResize = () => {
        setImmediate(setDimensions)
      }

      window.addEventListener("resize", handleWindowResize)
      return () => {
        window.removeEventListener("resize", handleWindowResize)
      }
    }, [setDimensions])

    const [observer] = useState(() => {
      return new ResizeObserver(() => {
        setDimensions()
      })
    })

    useEffect(() => {
      // the ref is not updated in the same react lifecycle event that the state changes and the ref cannot be observed
      setImmediate(() => {
        if (!currentStepRef.current) return
        observer.observe(currentStepRef.current)
      })
    }, [currentStep, observer, open])

    useEffect(() => {
      return observer.disconnect()
    }, [observer])

    const closingTimeout = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
      if (!open) {
        setIsTransitionEnabled(false)
        if (closingTimeout.current) clearTimeout(closingTimeout.current)
        closingTimeout.current = setTimeout(() => {
          setHistory([stepIndex])
          setCurrentStep(stepIndex)
        }, 300)
      } else {
        if (closingTimeout.current) {
          clearTimeout(closingTimeout.current)
          closingTimeout.current = null
        }
        setIsTransitionEnabled(false)
        dialogContentRef.current?.focus()
      }
    }, [open, stepIndex])

    useEffect(() => {
      if (!withKeyboardControl) return

      const handleKeyDown = (event: KeyboardEvent) => {
        switch (event.key) {
          case "ArrowRight":
            next()
            break
          case "ArrowLeft":
            previous()
            break
        }
      }

      window.addEventListener("keydown", handleKeyDown)
      return () => {
        window.removeEventListener("keydown", handleKeyDown)
      }
    }, [withKeyboardControl, next, previous])

    useEffect(() => {
      if (!open) {
        setMeta({})
      }
    }, [open])

    useImperativeHandle(ref, () => ({
      open: !!open,
      next,
      previous,
      back,
      goToStep,
      close,
      meta,
      addToMeta,
    }))

    return (
      <WizardContext.Provider
        value={{
          currentStep,
          next,
          back,
          previous,
          goToStep,
          open: !!open,
          close,
          meta,
          addToMeta,
        }}
      >
        <Dialog open={open} {...props}>
          <DialogContent
            ref={dialogContentRef}
            variant="wizard"
            {...dialogContentProps}
          >
            {withArrowNavigation && currentStep > 0 && (
              <div className="absolute top-0 -left-10 h-full w-6 bg-transparent flex items-center justify-center">
                <Button variant="invisible" onClick={previous}>
                  <CaretLeftIcon className="w-16 h-16 text-white/50" />
                </Button>
              </div>
            )}
            <div
              ref={currentStepRef}
              className={cn(
                "h-fit grid place-content-center items-center p-0",
                dialogContentProps?.variant === "gallery" ? "w-fit" : "w-max"
              )}
            >
              {steps.map((step, index) => (
                <Transition
                  key={index}
                  show={index === currentStep}
                  enter={cn(
                    isTransitionEnabled &&
                      "transition-all ease-in-out duration-300"
                  )}
                  enterFrom={
                    currentStepDirection === "forward"
                      ? "translate-x-full opacity-0"
                      : "-translate-x-full opacity-0"
                  }
                  enterTo="translate-x-0 opacity-100"
                  leave={cn(
                    isTransitionEnabled &&
                      "transition-all ease-in-out duration-300"
                  )}
                  leaveFrom="translate-x-0 opacity-100"
                  leaveTo={
                    currentStepDirection === "forward"
                      ? "-translate-x-full opacity-0"
                      : "translate-x-full opacity-0"
                  }
                >
                  <div
                    className={cn(
                      dialogContentProps?.variant !== "gallery" &&
                        "w-fit min-w-0 md:min-w-2xl md:max-w-5xl p-6",
                      "flex flex-col gap-4"
                    )}
                    style={{ gridArea: "1 / 1" }}
                  >
                    {step}
                  </div>
                </Transition>
              ))}
            </div>
            {withArrowNavigation && currentStep < steps.length - 1 && (
              <div className="absolute top-0 -right-10 h-full w-6 bg-transparent flex items-center justify-center">
                <Button variant="invisible" onClick={next}>
                  <CaretRightIcon className="w-16 h-16 text-white/50" />
                </Button>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </WizardContext.Provider>
    )
  }
)
