import { useCallback, useMemo, useState } from "react"
import { cn } from "~/common/shadcn-utils"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/shadcn/ui/dialog"
import { useTiers } from "~/tiers/TiersProvider"
import { PricingTableTier, PricingTier } from "./wizard/PricingTableStep"
import { TierIntervalEnum, TierLevelEnum } from "~/__generated__/graphql"
import { Button } from "~/shadcn/ui/button"
import invariant from "tiny-invariant"

export const NewUserPricingModal = ({
  open,
  setOpen,
  onTierSelect,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  onTierSelect: (opts: {
    tier: PricingTableTier
    interval: TierIntervalEnum
  }) => void
}) => {
  const { tiers } = useTiers()
  const presentableTiers = useMemo(() => {
    return tiers.filter((tier) => {
      if (tier.level === TierLevelEnum.Free) return false
      return tier.active
    })
  }, [tiers])
  const [selectedTier, setSelectedTier] = useState<PricingTableTier | null>(
    null
  )
  const [selectedInterval, setSelectedInterval] = useState<TierIntervalEnum>(
    TierIntervalEnum.Year
  )
  const selectTier = useCallback(
    (tier: PricingTableTier, interval: TierIntervalEnum) => {
      setSelectedTier(tier)
      setSelectedInterval(interval)
    },
    [setSelectedTier]
  )

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="overflow-y-auto max-h-[95vh]">
        <DialogHeader>
          <DialogTitle>Choose Membership</DialogTitle>
        </DialogHeader>

        <div
          className={cn(
            "grid grid-cols-1 gap-4 w-full mx-auto",
            presentableTiers.length === 3 && "sm:grid-cols-3 sm:w-[896px]",
            presentableTiers.length === 2 && "sm:grid-cols-2 sm:w-[592px]"
          )}
        >
          {presentableTiers.map((tier) => (
            <PricingTier
              tier={tier}
              selectable
              quarterly={false}
              key={tier.id}
              selected={selectedTier?.id === tier.id}
              onClick={selectTier}
            />
          ))}
        </div>

        <DialogFooter className="sm:flex-col gap-6 items-center space-x-0">
          <Button
            type="button"
            onClick={() => {
              setOpen(false)
              invariant(selectedTier)
              onTierSelect({ tier: selectedTier, interval: selectedInterval })
            }}
            disabled={!selectedTier}
          >
            Continue
          </Button>
          <Button
            type="button"
            variant="link"
            size="inline"
            onClick={() => setOpen(false)}
          >
            Nevermind
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
