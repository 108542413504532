import { ReactNode } from "react"
import { cn } from "~/common/shadcn-utils"

export const HeaderCell = ({
  children,
  className,
}: {
  children?: ReactNode
  className?: string
}) => (
  <th className={cn("border text-left py-2 px-2 whitespace-nowrap", className)}>
    {children}
  </th>
)

export const TableCell = ({
  children,
  className,
}: {
  children?: ReactNode
  className?: string
}) => (
  <td className={cn("border text-left py-2 px-2 whitespace-nowrap", className)}>
    {children}
  </td>
)
