import { gql } from "~/__generated__"

export const CELEBRATION_CREATE_MUTATION = gql(`
  mutation CelebrationCreate($input: CelebrationCreateInput!) {
    subject: celebrationCreate(input: $input) {
      celebration {
        id
        celebrationType
        customCelebrationType
        date
      }
    }
  }
`)
