import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { gql } from "~/__generated__"
import { Channel, CommunitySlug } from "~/__generated__/graphql"
import { useQuery } from "@apollo/client"
import { Button } from "~/shadcn/ui/button"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import House from "../images/icons/house.svg?react"
import Cup from "../images/icons/cup.svg?react"
import Driver from "../images/icons/driver.svg?react"
import People from "../images/icons/people.svg?react"
import VideoAdd from "../images/icons/video-add.svg?react"
import Messages from "../images/icons/messages.svg?react"
import ChatBubbles from "../images/icons/messages-2.svg?react"
import ChevronDown from "../images/icons/chevron-down.svg?react"
import {
  feedPath,
  channelPath,
  benchmarkPath,
  libraryPath,
  directoryPath,
  eventsPath,
  askTheBotPath,
} from "~/common/paths"
import { ReactNode, useState } from "react"
import {
  useCommunityClassname,
  useCommunityFeatures,
} from "~/community/useCommunity"
import { FeatureFlag } from "~/common/FeatureFlag"
import { cn } from "~/common/shadcn-utils"
import { SimpleTooltip } from "~/ui/SimpleTooltip"
import { EyeOffIcon } from "lucide-react"
import { useTranslation } from "react-i18next"

const Indicator = ({
  value,
  size = "default",
}: {
  value: number
  size?: "sm" | "default"
}) => {
  if (value === 0) return null

  return (
    <div
      className={cn(
        "bg-highlight rounded-full text-2xs flex items-center justify-center text-white px-1",
        size === "default" && "min-w-[24px] h-[24px] text-2xs",
        size === "sm" && "min-w-[16px] h-[16px] text-4xs"
      )}
    >
      {value}
    </div>
  )
}

const SidebarItem = ({
  icon,
  title,
  selected,
  onClick,
  beta = false,
  expanded = null,
  indicator = 0,
}: {
  icon: ReactNode
  title: string
  selected: boolean
  beta?: boolean
  expanded?: boolean | null
  indicator?: number
  onClick: () => void
}) => {
  const ccls = useCommunityClassname()
  return (
    <li>
      <div
        onClick={onClick}
        className={cn(
          "flex items-center justify-between text-xl px-5 py-3 rounded-full cursor-pointer",
          selected &&
            ccls({
              [CommunitySlug.Marketingland]:
                "bg-marketingland-purple text-white font-semibold",
              default: "bg-white text-black font-semibold",
            })
        )}
      >
        <div className="flex flex-1 items-center">
          <div className="w-[24px] text-center flex justify-center relative mr-5">
            {icon}
          </div>
          <div className="flex-1">{title}</div>
          {beta && (
            <div className="border border-primary rounded-full text-2xs py-1 px-2 italic font-medium tracking-wide text-primary">
              Beta
            </div>
          )}
          <Indicator value={indicator} />
          <ChevronDown
            className={cn("ml-2", {
              invisible: expanded === null,
              "rotate-180": expanded,
            })}
          />
        </div>
      </div>
    </li>
  )
}

const SidebarChannel = ({
  channel,
  selected,
  routeCallback,
  indicator,
}: {
  channel: Pick<Channel, "slug" | "name" | "active">
  selected: boolean
  routeCallback?: () => void
  indicator: number
}) => {
  const navigate = useNavigate()
  const handleClick = () => {
    routeCallback && routeCallback()
    navigate(channelPath({ channelSlug: channel.slug }))
  }
  const ccls = useCommunityClassname()

  return (
    <div
      onClick={handleClick}
      className={cn(
        "flex items-center gap-2 cursor-pointer px-5 py-1 text-sm rounded-full",
        {
          "text-foreground/60": !channel.active,
        },
        selected &&
          ccls({
            [CommunitySlug.Marketingland]: "bg-marketingland-purple text-white",
            default: "bg-white text-foreground",
          })
      )}
    >
      {!channel.active && (
        <SimpleTooltip content="Channel is inactive; you can see this because you are an admin">
          <EyeOffIcon className="w-4 h-4" />
        </SimpleTooltip>
      )}
      {channel.name}
      <Indicator value={indicator} size="sm" />
    </div>
  )
}

const SidebarAction = ({
  routeCallback,
  title,
  path,
}: {
  title: string
  path: string
  routeCallback?: () => void
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    routeCallback && routeCallback()
    navigate(path)
  }

  return (
    <div className="w-full px-6 mt-3">
      <Button className="w-full" onClick={handleClick}>
        {title}
      </Button>
    </div>
  )
}

export const NavItems = ({ routeCallback }: { routeCallback?: () => void }) => {
  const { currentUser } = useCurrentUserMaybe()
  const { data, loading, error } = useQuery(CHANNELS_QUERY_DOCUMENT)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { channelSlug } = useParams()
  const [channelsExpanded, setChannelsExpanded] = useState<boolean>(
    localStorage.getItem("collapseChannels") !== "true"
  )

  const navigateTo = (path: string) => {
    routeCallback && routeCallback()
    navigate(path)
  }

  const toggleChannels = () => {
    localStorage.setItem("collapseChannels", channelsExpanded.toString())
    setChannelsExpanded(!channelsExpanded)
  }
  const features = useCommunityFeatures()
  const { t } = useTranslation("community")

  return (
    <div className="flex flex-col gap-2 text-foreground">
      <ul>
        <SidebarItem
          icon={<House />}
          title="Feed"
          onClick={() => navigateTo(feedPath.pattern)}
          selected={!!matchPath({ path: feedPath.pattern }, pathname)}
        />
        {features.benchmark && (
          <SidebarItem
            icon={<Cup />}
            title="Benchmark"
            onClick={() => navigateTo(benchmarkPath.pattern)}
            selected={!!matchPath({ path: benchmarkPath.pattern }, pathname)}
          />
        )}
        <SidebarItem
          icon={<Driver />}
          title="Library"
          onClick={() => navigateTo(libraryPath.pattern)}
          indicator={currentUser?.unviewedLibraryCount}
          selected={
            !!matchPath({ path: libraryPath.pattern, end: false }, pathname)
          }
        />
        <SidebarItem
          icon={<People />}
          title={t("directoryLabel")}
          onClick={() => navigateTo(directoryPath.pattern)}
          indicator={currentUser?.unviewedDirectoryCount}
          selected={!!matchPath({ path: directoryPath.pattern }, pathname)}
        />
        <SidebarItem
          icon={<VideoAdd />}
          title="Events"
          onClick={() => navigateTo(eventsPath.pattern)}
          indicator={currentUser?.unviewedEventsCount}
          selected={!!matchPath({ path: eventsPath.pattern }, pathname)}
        />

        {FeatureFlag.get("delphiBot") && features.ask_the_bot && (
          <SidebarItem
            icon={<ChatBubbles />}
            title="Ask The Bot"
            beta={true}
            onClick={() => navigateTo(askTheBotPath.pattern)}
            selected={!!matchPath({ path: askTheBotPath.pattern }, pathname)}
          />
        )}

        <SidebarItem
          icon={<Messages />}
          title="Channels"
          onClick={toggleChannels}
          expanded={channelsExpanded}
          selected={
            !!matchPath({ path: channelPath.pattern, end: false }, pathname)
          }
          indicator={currentUser?.unreadChannelPostsCount}
        />

        {!loading && !error && data && channelsExpanded && (
          <div className="py-3">
            {data.channels.nodes.map((c) => (
              <SidebarChannel
                channel={c}
                key={`c-${c.id}`}
                selected={channelSlug === c.slug}
                routeCallback={routeCallback}
                indicator={c.unreadFeedPostCount}
              />
            ))}
          </div>
        )}

        <SidebarAction
          title="Post"
          path={feedPath.pattern + "?post=focus"}
          routeCallback={routeCallback}
        />
      </ul>
    </div>
  )
}

export const CHANNELS_QUERY_DOCUMENT = gql(`
  query Channels {
    channels {
      nodes {
        id
        name
        slug
        active
        unreadFeedPostCount
      }
    }
  }
`)
