import { useCallback } from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { Article } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { ConfirmContextProviderProps, useConfirm } from "~/ui/Confirm"

export type ToggleHiddenConfirmProps = {
  article: Pick<Article, "id" | "draftLesson">
} & Partial<ConfirmContextProviderProps>

export const useToggleHiddenConfirm = ({
  article,
  ...confirmProps
}: ToggleHiddenConfirmProps) => {
  const showConfirm = useConfirm()
  const [runToggleHidden] = useSafeMutation(UPDATE_LESSON_MUTATION)

  const toggleHidden = useCallback(() => {
    invariant(article.draftLesson, "Article must have a lesson")
    const isHidden = article.draftLesson.hidden

    showConfirm({
      title: isHidden ? "Un-hide Lesson" : "Hide Lesson",
      body: isHidden
        ? "Un-hiding this lesson will make it visible to end users in the course once it is published. Are you sure you want to un-hide this lesson?"
        : "Hiding this lesson will cause this lesson to not be displayed within the course if it is already published and will prevent this lesson from being shown in the course when it is published. Are you sure you want to hide this lesson?",
      confirmText: isHidden ? "Un-hide" : "Hide",
      onConfirm: async () => {
        const { errors } = await runToggleHidden({
          variables: {
            input: {
              lessonId: article.draftLesson!.id,
              hidden: !isHidden,
            },
          },
        })

        if (errors) {
          displayErrors(errors)
          console.log(errors)
        } else {
          toast.success(isHidden ? "Un-hid lesson" : "Hid lesson")
        }
      },
      ...confirmProps,
    })
  }, [article, confirmProps, runToggleHidden, showConfirm])

  return toggleHidden
}

export const UPDATE_LESSON_MUTATION = gql(`
  mutation UpdateLesson($input: LessonUpdateInput!) {
    lessonUpdate(input: $input) {
      lesson {
        ...Lesson_EditorContent
      }
    }
  }
`)
