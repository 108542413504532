import { formatDate } from "~/common/formatDate"
import { celebrationTypesByValue } from "./celebrationTypes"
import Edit from "../images/icons/edit.svg?react"
import Trash from "../images/icons/trash-2.svg?react"
import { useMemo, useState } from "react"
import { UpdateCelebration } from "./UpdateCelebration"
import { Celebration } from "~/__generated__/graphql"
import { Button } from "~/shadcn/ui/button"
import { useDestroyCelebration } from "./useDestroyCelebration"

type ShowCelebrationProps = {
  celebration: Celebration
  editable?: boolean
  onUpdated?: (celebration: Celebration) => void
  onDestroyed?: (celebration: Celebration) => void
  currentCelebrations?: Partial<Celebration>[]
}

export const ShowCelebration = ({
  celebration,
  editable,
  onUpdated,
  onDestroyed,
  currentCelebrations,
}: ShowCelebrationProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isDestroyed, setIsDestroyed] = useState(false)
  const [celebrationType, setCelebrationType] = useState(
    celebration.celebrationType
  )
  const [customCelebrationType, setCustomCelebrationType] = useState(
    celebration.customCelebrationType
  )
  const [date, setDate] = useState(celebration.date)
  const formattedDate = useMemo(
    () =>
      celebrationType === "birthday"
        ? formatDate(date, "MMM d")
        : formatDate(date),
    [date, celebrationType]
  )

  const { destroy: destroyCelebration } = useDestroyCelebration()

  const onCelebrationUpdated = (celebration: Celebration) => {
    setCustomCelebrationType(celebration.customCelebrationType)
    setCelebrationType(celebration.celebrationType)
    setDate(celebration.date)
    setIsEditing(false)
    if (onUpdated) {
      onUpdated(celebration)
    }
  }

  const onEditClicked = () => {
    setIsEditing(true)
  }

  const onDestroyClicked = () => {
    destroyCelebration({ id: celebration.id })
    setIsDestroyed(true)
    if (onDestroyed) {
      onDestroyed(celebration)
    }
  }

  if (isDestroyed) {
    return null
  }

  return isEditing ? (
    <div className="py-4">
      <UpdateCelebration
        celebration={celebration}
        onCelebrationUpdated={onCelebrationUpdated}
        currentCelebrations={currentCelebrations || []}
      />
    </div>
  ) : (
    <div className="flex space-x-2 items-center text-sm">
      <div>{celebrationTypesByValue[celebrationType!].emoji}</div>
      <div className="mr-2 flex-grow font-medium">
        {celebrationType === "other"
          ? customCelebrationType
          : celebrationTypesByValue[celebrationType!].label}
      </div>
      <div className="mr-2">{formattedDate}</div>
      {editable && (
        <div className="flex">
          <Button onClick={onEditClicked} variant="ghost" size="icon">
            <Edit className="text-highlight" />
          </Button>
          <Button onClick={onDestroyClicked} variant="ghost" size="icon">
            <Trash className="text-highlight" />
          </Button>
        </div>
      )}
    </div>
  )
}
