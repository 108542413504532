import { CELEBRATION_DESTROY_MUTATION } from "~/celebrations"
import { useSafeMutation } from "~/common/useSafeMutation"

type CelebrationDestroyInput = {
  id: string
}

export const useDestroyCelebration = () => {
  const [destroyCelebration, { loading }] = useSafeMutation(
    CELEBRATION_DESTROY_MUTATION
  )

  const destroy = async ({ id }: CelebrationDestroyInput) => {
    return await destroyCelebration({
      variables: {
        input: {
          celebrationId: id,
        },
      },
    })
  }
  return { destroy, loading }
}
