import {
  ArticleActionToolbar,
  ArticleActionToolbarProps,
} from "~/articles/ArticleActionToolbar"
import { useCourseEditorContext } from "./CourseEditor"

export const CourseActionToolbar = ({
  edit,
  preview,
  isPreviewing,
  save,
  isSaving,
  isLocked,
}: Pick<
  ArticleActionToolbarProps,
  "preview" | "isPreviewing" | "save" | "isSaving" | "isLocked" | "edit"
>) => {
  const { article, hasChanges } = useCourseEditorContext()

  return (
    <ArticleActionToolbar
      preview={preview}
      edit={edit}
      save={save}
      isPreviewing={isPreviewing}
      article={article}
      isSaving={isSaving}
      isDirty={hasChanges}
      isLocked={isLocked}
      options={{
        approve: true,
        submitForReview: true,
        requestEdits: true,
        changeVisibility: true,
        edit: true,
        preview: true,
        toggleFeatured: true,
        toggleHidden: false,
        togglePremium: true,
      }}
      approveContentConfirmOptions={{
        body: "Would you like to approve this draft version of the course? Please note that this will approve all modules and lessons nested within the course.",
      }}
    />
  )
}
