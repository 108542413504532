import { gql } from "~/__generated__"

export const CELEBRATION_DESTROY_MUTATION = gql(`
  mutation CelebrationDestroy($input: CelebrationDestroyInput!) {
    subject: celebrationDestroy(input: $input) {
      celebration {
        id
        celebrationType
        customCelebrationType
        date
      }
    }
  }
`)
