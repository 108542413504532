import { User_AdminFragment } from "~/__generated__/graphql"
import { useCommunity } from "~/community/useCommunity"
import { getURL, isLink } from "~/components/FormattedContent"
import {
  ExperienceFields,
  FieldsForCommunity,
} from "~/onboarding/OnboardingExperienceQuestions"

export const FitProfileDisplay = ({ user }: { user: User_AdminFragment }) => {
  const community = useCommunity()
  const fitProfile = user.fitProfile

  if (!fitProfile) return null

  return (
    <div className="text-2xs">
      {FieldsForCommunity[community.slug].map((field) => {
        const value = fitProfile[field]
        return (
          <div key={field}>
            {camelCaseToHumanReadable(field)}:{" "}
            {field === ExperienceFields.LinkedinProfileUrl &&
            value &&
            isLink(value) ? (
              <a
                className="underline"
                href={getURL(value)?.toString() || "#"}
                target="_blank"
                rel="nofollow noreferrer"
              >
                {value}
              </a>
            ) : (
              <strong>{value}</strong>
            )}
          </div>
        )
      })}
    </div>
  )
}

const camelCaseToHumanReadable = (str: string) => {
  const formattedString = str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .toLowerCase()
    .replace(/_/g, " ")

  // capitalize first letter
  return formattedString.charAt(0).toUpperCase() + formattedString.slice(1)
}
