import {
  AhoyEventTypeEnum,
  ArticleContentTypeEnum,
  Article_CardFragment,
  Article_MyContentTableFragment,
  Scalars,
} from "~/__generated__/graphql"

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/shadcn/ui/tooltip"

import { cn } from "~/common/shadcn-utils"

import { useLocation, useNavigate } from "react-router-dom"
import { compareAsc, format, parseISO } from "date-fns"

import { Card, CardHeader, CardContent, CardFooter } from "~/shadcn/ui/card"

import { TagAndContentType } from "~/ui/TagBubble"

import { articlePath, editArticlePath, libraryPath } from "~/common/paths"
import { HighlightContent } from "~/components/HighlightContent"
import { gql } from "~/__generated__"
import { StackedUserAvatars } from "./StackedUserAvatars"
import { useCallback, useMemo } from "react"
import { Separator } from "~/shadcn/ui/separator"
import {
  ARTICLE_STATE_LABELS,
  ARTICLE_VISIBILITY_LABELS,
} from "~/common/enumTranslations"
import { Badge } from "~/shadcn/ui/badge"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { UserName } from "~/directory/UserName"

const formatDate = (date: Scalars["ISO8601DateTime"]["input"]) => {
  return format(date, "MMM do, yyyy")
}

const hasDraft = (
  article: Article_CardFragment | Article_MyContentTableFragment
): article is Article_MyContentTableFragment => {
  return article.hasOwnProperty("draftRevision")
}

const hasApprovedRevision = (
  article: Article_CardFragment | Article_MyContentTableFragment
): article is Article_CardFragment => {
  return article.hasOwnProperty("approvedRevision")
}

const AUTHOR_TRUNCATION = 28
const AUTHOR_LINE_LIMIT = 2
const TITLE_TRUNCATION = 65
const TITLE_LINE_LIMIT = 2
const DESCRIPTION_TRUNCATION = 150
const DESCRIPTION_LINE_LIMIT = 3

export type ArticleCardProps = {
  article: Article_CardFragment | Article_MyContentTableFragment
  authorNamesTruncation?: number
  authorNamesLineLimit?: number
  titleTruncation?: number
  titleLineLimit?: number
  descriptionTruncation?: number
  descriptionLineLimit?: number
  query?: string
  wideVersion?: boolean
  openInNewWindow?: boolean
  header?: React.ReactNode
  editMode?: boolean
  highlight?: boolean
}

export const ArticleCard = ({
  article,
  header,
  authorNamesTruncation = AUTHOR_TRUNCATION,
  authorNamesLineLimit = AUTHOR_LINE_LIMIT,
  descriptionTruncation = DESCRIPTION_TRUNCATION,
  descriptionLineLimit = DESCRIPTION_LINE_LIMIT,
  titleTruncation = TITLE_TRUNCATION,
  titleLineLimit = TITLE_LINE_LIMIT,
  query = "",
  wideVersion = false,
  openInNewWindow = false,
  editMode = false,
  highlight = false,
}: ArticleCardProps) => {
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  const revision = useMemo(() => {
    if (hasDraft(article)) {
      return article.draftRevision
    }

    if (hasApprovedRevision(article)) {
      return article.approvedRevision
    }
  }, [article])

  const description = revision?.description || ""
  const title = revision?.title || ""
  const tag = revision?.tag?.name || ""
  const contentType = revision?.contentType || null
  const formattedDate = formatDate(article.markedLiveAt || article.createdAt)
  const authors = article.collaborators
  const authorNames = authors
    .map((c) => {
      return c.user ? `${c.user.firstName} ${c.user.lastName}` : ""
    })
    .join(", ")
  const path = editMode
    ? editArticlePath({ articleId: article.id })
    : articlePath({ articleId: article.id })
  const location = useLocation()
  const { logEvent } = useLogEvent()
  const { openSubscriptionWizard } = useSubscription()

  const needsSubscriptionUpgrade = useMemo(() => {
    return (
      (contentType === ArticleContentTypeEnum.Course &&
        !currentUser.permissions.canViewCourses) ||
      (article.premium && !currentUser.permissions.canViewPremiumArticles)
    )
  }, [
    contentType,
    currentUser.permissions.canViewCourses,
    article.premium,
    currentUser.permissions.canViewPremiumArticles,
  ])

  const cardContentOnClick = useCallback(
    (e: any) => {
      if (
        e.target.tagName.toLowerCase() === "a" ||
        e.target.parentElement.tagName.toLowerCase() === "a" ||
        e.target.tagName.toLowerCase() === "button" ||
        e.target.parentElement.tagName.toLowerCase() === "button"
      ) {
        return
      }

      if (needsSubscriptionUpgrade) {
        e.preventDefault()
        logEvent(AhoyEventTypeEnum.PremiumContentAccessed, {
          content_id: article.id,
        })
        openSubscriptionWizard("PricingTableStep", { source: "ArticleCard" })
        return
      }

      if (location.pathname === libraryPath.pattern) {
        logEvent(AhoyEventTypeEnum.LibraryLinkClicked, { url_clicked: path })
      }

      if (openInNewWindow) {
        window.open(path, "_blank")?.focus()
      } else {
        navigate(path)
      }
    },
    [
      location,
      path,
      openInNewWindow,
      navigate,
      logEvent,
      needsSubscriptionUpgrade,
      openSubscriptionWizard,
      article,
    ]
  )

  const isFeatured = useMemo(
    () =>
      article.featuredUntil &&
      compareAsc(parseISO(article.featuredUntil), new Date()) === 1,
    [article.featuredUntil]
  )

  return (
    <Card
      className={cn(
        "flex flex-col justify-between hover:shadow-lg transition-all duration-300 hover:cursor-pointer",
        {
          "h-[250px] aspect-[5/4]": !wideVersion,
          "border-highlight bg-card-highlight-background": highlight,
        }
      )}
      onClick={cardContentOnClick}
    >
      <div className="flex flex-col flex-grow">
        {article.premium && (
          <CardHeader
            variant="cardLabel"
            locked={!currentUser.permissions.canViewPremiumArticles}
          >
            Premium Content
          </CardHeader>
        )}
        {header}
        <CardHeader className="border-none">
          <div className="flex flex-row items-center space-x-2">
            <StackedUserAvatars
              users={authors.map((a) => a.user)}
              opensUserDialog={true}
            />
            <div className="font text-2xs font-light flex flex-row flex-grow">
              {authors.length === 1 ? (
                <UserName
                  user={authors[0].user}
                  formatter={(userName) => (
                    <HighlightContent
                      query={query}
                      content={userName}
                      truncationLimit={authorNamesTruncation}
                      lineLimit={authorNamesLineLimit}
                    />
                  )}
                />
              ) : (
                <HighlightContent
                  query={query}
                  content={authorNames}
                  truncationLimit={authorNamesTruncation}
                  lineLimit={authorNamesLineLimit}
                />
              )}
            </div>
            {isFeatured && (
              <Badge
                variant="highlight"
                className="uppercase tracking-widest text-3xs px-1 font-normal overflow-visible"
              >
                Featured
              </Badge>
            )}
          </div>
        </CardHeader>
        <CardContent className="pb-0">
          <div className="flex flex-col">
            <div className="leading-tight font-medium text-md">
              <TooltipProvider>
                <Tooltip delayDuration={40}>
                  <TooltipTrigger
                    asChild
                    className={cn("text-left hover:underline")}
                  >
                    <HighlightContent
                      query={query}
                      content={title}
                      truncationLimit={titleTruncation}
                      lineLimit={titleLineLimit}
                    />
                  </TooltipTrigger>
                  <TooltipContent
                    side="bottom"
                    className={cn(
                      "ArticleTooltipContent bg-black text-white leading-tight font-light text-2xs"
                    )}
                  >
                    {title}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="leading-tight text-[#666666] font-light text-2xs mt-1 tracking-wide">
              <TooltipProvider>
                <Tooltip delayDuration={40}>
                  <TooltipTrigger
                    asChild
                    className={cn("text-left hover:underline")}
                  >
                    <HighlightContent
                      query={query}
                      content={description}
                      truncationLimit={descriptionTruncation}
                      lineLimit={
                        descriptionLineLimit -
                        (contentType === ArticleContentTypeEnum.Course ? 1 : 0)
                      }
                    />
                  </TooltipTrigger>
                  <TooltipContent
                    side="bottom"
                    className={cn(
                      "ArticleTooltipContent bg-black leading-tight text-2xs tracking-wide font-light text-white"
                    )}
                  >
                    {description}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            {!wideVersion && !editMode && (
              <div className="text-[#666666] font-light text-2xs mt-2">
                {formattedDate}
              </div>
            )}
          </div>
        </CardContent>
      </div>
      <CardFooter className="border-none">
        <div className="flex flex-col flex-grow gap-2 items-start">
          <div className="flex flex-row gap-2">
            <TagAndContentType
              tagName={tag}
              colorClass={"text-highlight"}
              contentType={contentType}
            />

            {wideVersion && !editMode && (
              <div className="text-[#666666] font-light text-2xs ml-auto">
                {formattedDate}
              </div>
            )}
          </div>

          {editMode && (
            <div className="w-full mt-2">
              <Separator />

              <div className="flex flex-col gap-2 mt-4 text-[#666666] font-light text-2xs">
                <div>Date Created: {formattedDate}</div>

                <div>Status: {ARTICLE_STATE_LABELS[article.state]}</div>

                <div>
                  Visibility: {ARTICLE_VISIBILITY_LABELS[article.visibility]}
                </div>
              </div>
            </div>
          )}
        </div>
      </CardFooter>
    </Card>
  )
}

gql(`
  fragment Article_Card on Article {
    id
    createdAt
    markedLiveAt
    state
    visibility
    featuredUntil
    premium
    approvedRevision {
      id
      title
      description
      contentType
      contentVersion
      tag {
        id
        name
      }
    }
    collaborators {
      id
      user {
        ...User_Avatar
      }
    }
    currentUserBookmark {
      ...Bookmark
    }
  }
`)
