import { UserSortEnum } from "~/__generated__/graphql"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/ui/select"
import { SearchInput } from "~/ui/SearchInput"

type UsersSearchProps = {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  sort: UserSortEnum
  setSort: (sort: UserSortEnum) => void
}

export const UsersSearch = ({
  searchTerm,
  setSearchTerm,
  sort,
  setSort,
}: UsersSearchProps) => {
  return (
    <div className="flex gap-2">
      <div className="flex-grow">
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      <div>
        <Select
          onValueChange={(value) => setSort(value as UserSortEnum)}
          value={sort}
        >
          <SelectTrigger className="text-sm min-w-[162px]">
            <SelectValue placeholder="" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={UserSortEnum.Recent}>Recent</SelectItem>
            <SelectItem value={UserSortEnum.Name}>Name (A-Z)</SelectItem>
            <SelectItem value={UserSortEnum.ApplicationPriority}>
              Application Priority
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
