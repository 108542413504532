import { Transition } from "@headlessui/react"
import { cn } from "~/common/shadcn-utils"

interface CalloutProps {
  inverted?: boolean
  medium: React.ReactNode
  children: React.ReactNode
}

export const Callout = ({
  inverted = false,
  medium,
  children,
}: CalloutProps) => {
  return (
    <div className={cn("sm:grid sm:grid-cols-12 gap-4 items-center")}>
      <div
        className={cn(
          "flex max-w-[66vw] sm:mr-none sm:ml-none sm:max-w-none sm:col-span-7",
          inverted ? "ml-auto sm:order-2 justify-end" : "mr-auto justify-start"
        )}
      >
        <Transition
          show
          enter="transition-all ease-in-out duration-200"
          enterFrom={cn(
            "opacity-0",
            inverted ? "translate-x-4" : "-translate-x-4"
          )}
          enterTo="opacity-100"
          leave="transition-all ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo={cn(
            "opacity-0",
            inverted ? "translate-x-4" : "-translate-x-4"
          )}
        >
          {medium}
        </Transition>
      </div>
      <div
        className={cn(
          "flex sm:col-span-5",
          inverted ? "sm:order-1 justify-start" : "justify-end"
        )}
      >
        {children}
      </div>
    </div>
  )
}
