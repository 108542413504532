import { useQuery } from "@apollo/client"
import { useCallback } from "react"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { Card } from "~/shadcn/ui/card"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { TagAndContentType } from "~/ui/TagBubble"

export const CollaborationInvites = ({
  afterAccept,
}: {
  afterAccept: () => void
}) => {
  const { data, previousData, refetch } = useQuery(
    PENDING_COLLABORATION_INVITES_QUERY_DOCUMENT
  )
  const { currentUser } = useCurrentUser()

  const [runCollaboratorAccept] = useSafeMutation(
    ACCEPT_COLLABORATION_INVITE_MUTATION
  )
  const [runCollaboratorReject] = useSafeMutation(
    REJECT_COLLABORATION_INVITE_MUTATION
  )

  const articles =
    data?.pendingContent.nodes || previousData?.pendingContent.nodes || []

  const accept = useCallback(
    async (articleId: string) => {
      const { errors } = await runCollaboratorAccept({
        variables: { input: { articleId } },
      })

      if (errors) {
        displayErrors(errors)
      } else {
        toast.success("Invitation accepted!")
        afterAccept()
        refetch()
      }
    },
    [runCollaboratorAccept, refetch, afterAccept]
  )

  const reject = useCallback(
    async (articleId: string) => {
      const { errors } = await runCollaboratorReject({
        variables: { input: { articleId } },
      })

      if (errors) {
        displayErrors(errors)
      } else {
        refetch()
      }
    },
    [runCollaboratorReject, refetch]
  )

  if (articles.length === 0) return null

  return (
    <div className="mt-4 px-4 tracking-wide flex flex-col gap-4">
      {articles.map((article) => {
        const collaboration = article.pendingCollaborators.find(
          (c) => c.user.id === currentUser.id
        )
        return collaboration?.pending ? (
          <Card
            className="p-4 flex items-center gap-2 shadow-md"
            key={collaboration.id}
          >
            <div className="flex-1">
              {collaboration.invitedBy && (
                <div className="flex items-center gap-2 mb-2">
                  <AvatarWithFallback user={collaboration.invitedBy} />
                  <div className="text-2xs">
                    <strong>{collaboration.invitedBy.name}</strong> invited you
                    to become a collaborator.
                  </div>
                </div>
              )}
              <div className="font-semibold text-sm mb-1">
                {article.draftRevision.title}
              </div>
              <div className="text-placeholder text-2xs mb-3 line-clamp-2">
                {article.draftRevision.description}
              </div>
              <TagAndContentType
                tagName={article.draftRevision.tag.name}
                contentType={article.draftRevision.contentType}
              />
            </div>
            <Button
              className="text-xs"
              variant="outline"
              onClick={() => reject(article.id)}
            >
              Reject
            </Button>
            <Button className="text-xs" onClick={() => accept(article.id)}>
              Accept Invite
            </Button>
          </Card>
        ) : null
      })}
    </div>
  )
}

const PENDING_COLLABORATION_INVITES_QUERY_DOCUMENT = gql(`
  query PendingCollaborationInvites {
    pendingContent: myContent(pending: true) {
      nodes {
        id
        draftRevision {
          id
          title
          description
          tag {
            id
            name
          }
          contentType
        }
        pendingCollaborators: collaborators(includePending: true) {
          id
          pending
          invitedBy {
            name
            ...User_Avatar
          }
          user {
            id
          }
        }
      }
    }
  }
`)

const ACCEPT_COLLABORATION_INVITE_MUTATION = gql(`
  mutation CollaboratorAccept($input: CollaboratorAcceptInput!) {
    collaboratorAccept(input: $input) {
      article {
        ...Article_MyContentTable
      }
    }
  }
`)

const REJECT_COLLABORATION_INVITE_MUTATION = gql(`
  mutation CollaboratorReject($input: CollaboratorRejectInput!) {
    collaboratorReject(input: $input) {
      article {
        ...Article_MyContentTable
      }
    }
  }
`)
