import { ArticleContentTypeEnum, CommunitySlug } from "~/__generated__/graphql"

export const CONTENT_TYPES_FOR_COMMUNITY: Record<
  CommunitySlug,
  ArticleContentTypeEnum[]
> = {
  [CommunitySlug.Safespace]: [
    ArticleContentTypeEnum.Course,
    ArticleContentTypeEnum.Exercise,
    ArticleContentTypeEnum.Guide,
    ArticleContentTypeEnum.Replay,
    ArticleContentTypeEnum.Report,
    ArticleContentTypeEnum.Template,
  ],
  [CommunitySlug.Boardroom]: [
    ArticleContentTypeEnum.Course,
    ArticleContentTypeEnum.FinancialAnalysis,
    ArticleContentTypeEnum.Research,
    ArticleContentTypeEnum.Replay,
    ArticleContentTypeEnum.DeepDive,
    ArticleContentTypeEnum.Note,
  ],
  [CommunitySlug.Marketingland]: [
    ArticleContentTypeEnum.Course,
    ArticleContentTypeEnum.Replay,
    ArticleContentTypeEnum.Guide,
    ArticleContentTypeEnum.Playbook,
    ArticleContentTypeEnum.Template,
    ArticleContentTypeEnum.SwipeFile,
    ArticleContentTypeEnum.CaseStudy,
  ],
}
