import { CELEBRATION_UPDATE_MUTATION } from "~/celebrations"
import { useSafeMutation } from "~/common/useSafeMutation"

type CelebrationUpdateInput = {
  id: string
  date: string
  celebrationType: string
  customCelebrationType?: string
}

export const useUpdateCelebration = () => {
  const [updateCelebration, { loading }] = useSafeMutation(
    CELEBRATION_UPDATE_MUTATION
  )

  const update = async ({
    id,
    date,
    celebrationType,
    customCelebrationType,
  }: CelebrationUpdateInput) => {
    return await updateCelebration({
      variables: {
        input: {
          celebrationId: id,
          date,
          celebrationType,
          customCelebrationType,
        },
      },
    })
  }
  return { update, loading }
}
