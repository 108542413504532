import { HrisBackend } from "~/__generated__/graphql"
import { useManualHrisDataContext } from "~/merge/manualData"
import { HRIS_BACKENDS } from "~/merge/hris_backends"
import { ChevronDown } from "lucide-react"

import { Base } from "./Base"

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/ui/select"

const SelectIcon = () => {
  return <ChevronDown className="h-4 w-4" />
}

export const ManualHrisBackend = () => {
  const { next, prev, hrisBackend, setHrisBackend } = useManualHrisDataContext()

  return (
    <Base
      title="What Human Resource Information System (HRIS) do you use?"
      prev={prev}
      next={next}
      disabled={hrisBackend === undefined}
    >
      <div className="text-xs font-medium tracking-wide text-textLight mb-1">
        System<span className="text-red-400">*</span>
      </div>
      <div className="mb-6">
        <Select
          onValueChange={(value: HrisBackend) => setHrisBackend(value)}
          defaultValue={hrisBackend}
        >
          <SelectTrigger Icon={SelectIcon} className="focus:ring-offset-0">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {HRIS_BACKENDS.map(({ value, label }) => {
                return (
                  <SelectItem key={value} value={value}>
                    {label}
                  </SelectItem>
                )
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </Base>
  )
}
