import { Outlet } from "react-router-dom"
import { NavItems } from "~/components/NavItems"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunityClassname } from "~/community/useCommunity"
import { UserDialogContextProvider } from "~/directory/UserDialogContext"
import { cn } from "~/common/shadcn-utils"

export const ExternalShareLayout = () => {
  const ccls = useCommunityClassname()

  return (
    <UserDialogContextProvider>
      <div className={`flex-1 flex flex-col`}>
        <nav role="navigation" aria-label="Main menu" aria-controls="main-menu">
          <div
            className={cn(
              "p-4 border-b h-[75px]",
              ccls({
                [CommunitySlug.Marketingland]: "border-primary",
                default: "border-mercury",
              })
            )}
          ></div>
        </nav>

        <div className="flex flex-1 flex-col">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 container mx-auto my-5">
            <div className="hidden lg:block blur pointer-events-none">
              <NavItems />
            </div>
            <div className={cn("md:col-span-3")}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </UserDialogContextProvider>
  )
}
