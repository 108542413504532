import { useCallback } from "react"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { AhoyEventTypeEnum, Article } from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { ConfirmContextProviderProps, useConfirm } from "~/ui/Confirm"

export type ApproveContentConfirmProps = {
  article: Pick<Article, "id">
} & Partial<ConfirmContextProviderProps>

export const useApproveContentConfirm = ({
  article,
  ...confirmProps
}: ApproveContentConfirmProps) => {
  const showConfirm = useConfirm()
  const { logEvent } = useLogEvent()
  const [runApproveContent] = useSafeMutation(APPROVE_ARTICLE_MUTATION)

  const approveContent = useCallback(() => {
    showConfirm({
      title: "Approve Content",
      body: "Would you like to approve this draft version of the content? Please note that the title, description, or body may have changed.",
      confirmText: "Approve",
      onConfirm: async () => {
        const { errors } = await runApproveContent({
          variables: { input: { articleId: article.id } },
        })

        if (errors) {
          displayErrors(errors)
          console.log(errors)
        } else {
          toast.success("Content approved")
          logEvent(AhoyEventTypeEnum.ContentSubmissionApproved, {
            content_id: article.id,
          })
        }
      },
      ...confirmProps,
    })
  }, [article.id, confirmProps, logEvent, runApproveContent, showConfirm])

  return approveContent
}

export const APPROVE_ARTICLE_MUTATION = gql(`
  mutation ArticleApprove($input: ArticleApproveInput!) {
    articleApprove(input: $input) {
      article {
        ...Article_EditorContent
      }
    }
  }
`)
