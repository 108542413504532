import { useQuery } from "@apollo/client"
import { addDays, setHours, setMinutes, setSeconds } from "date-fns"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { AdminHeader } from "~/admin/AdminHeader"
import { adminIntroductionsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import {
  INTRODUCTION_CYCLES_ADMIN_CREATE_MUTATION,
  INTRODUCTION_CYCLES_ADMIN_COUNT_QUERY_DOCUMENT,
} from "~/introduction-cycles/api"
import {
  IntroductionCycleForm,
  IntroductionCycleFormValues,
  parseIntroductionsDateField,
  utcDateToIntroductionsDateField,
} from "~/introduction-cycles/IntroductionCycleForm"
import { Error } from "~/ui/Error"
import { LoadingIndicator } from "~/ui/LoadingIndicator"

import People from "~/images/icons/people.svg?react"

export const AdminNewIntroductionCycleScreen = () => {
  const navigate = useNavigate()
  const { data, loading } = useQuery(
    INTRODUCTION_CYCLES_ADMIN_COUNT_QUERY_DOCUMENT
  )
  const [runIntroductionCycleCreate, introductionCycleCreateResult] =
    useSafeMutation(INTRODUCTION_CYCLES_ADMIN_CREATE_MUTATION)

  const onSubmit = async (values: IntroductionCycleFormValues) => {
    const { errors } = await runIntroductionCycleCreate({
      variables: {
        input: {
          name: values.name,
          sendAt: parseIntroductionsDateField(values.sendAt).toISOString(),
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      toast.success("Created introduction cycle")
      navigate(adminIntroductionsPath.pattern)
    }
  }

  return (
    <div>
      <AdminHeader>
        <div className="flex gap-3 items-center text-xs">
          <People height={20} width={20} />
          New Introduction Cycle
        </div>
      </AdminHeader>
      <div className="px-5 pb-10 mt-4">
        {loading ? (
          <LoadingIndicator />
        ) : data?.introductionCycles ? (
          <IntroductionCycleForm
            defaultValues={{
              name: `Cycle ${data.introductionCycles.totalCount + 1}`,
              sendAt: utcDateToIntroductionsDateField(
                setSeconds(
                  setMinutes(setHours(addDays(new Date(), 7), 9), 0),
                  0
                )
              ),
            }}
            onSubmit={onSubmit}
            loading={introductionCycleCreateResult.loading}
          />
        ) : (
          <Error message="Error loading data." />
        )}
      </div>
    </div>
  )
}
