import { zodResolver } from "@hookform/resolvers/zod"
import { Info } from "lucide-react"
import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Alert, AlertDescription } from "~/shadcn/ui/alert"
import { Button } from "~/shadcn/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/shadcn/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Input } from "~/shadcn/ui/input"

const airTableFormEmbedModalFormSchema = z.object({
  url: z
    .string()
    .min(1, {
      message: "URL is required",
    })
    .url("Invalid URL")
    .includes("airtable.com", {
      message: "URL must be an AirTable form",
    })
    .trim(),
})

type AirTableFormEmbedEditorModalFormValues = z.infer<
  typeof airTableFormEmbedModalFormSchema
>

type AirTableFormEmbedEditorModalProps = {
  onSave: (url: string) => void
  open: boolean
  url: string
  setIsOpen: (value: boolean) => void
}

export const AirTableFormEmbedEditorModal = ({
  onSave,
  open,
  url,
  setIsOpen,
}: AirTableFormEmbedEditorModalProps) => {
  const form = useForm<AirTableFormEmbedEditorModalFormValues>({
    resolver: zodResolver(airTableFormEmbedModalFormSchema),
    defaultValues: {
      url: url,
    },
  })

  useEffect(() => {
    form.reset({
      url: url,
    })
  }, [form, url])

  const onSubmit = useCallback(
    async (values: AirTableFormEmbedEditorModalFormValues) => {
      onSave(values.url)
      setIsOpen(false)
    },
    [onSave, setIsOpen]
  )

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <DialogHeader>
          <DialogTitle className="text-center">
            {url ? "Edit AirTable Form" : "Add AirTable Form"}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-8"
          >
            <FormField
              control={form.control}
              name="url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>URL</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="https://airtable.com/embed/..."
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Alert variant="info">
              <Info className="w-4 h-4 stroke-info" />
              <AlertDescription>
                <div className="text-xs text-muted-foreground">
                  If your form contains the following field names, they will be
                  prefilled with the user's information:
                </div>
                <ul className="list-disc list-inside text-xs pl-4 text-muted-foreground">
                  <li>UserId</li>
                  <li>Email Address</li>
                  <li>Name</li>
                  <li>First Name</li>
                  <li>Last Name</li>
                </ul>
              </AlertDescription>
            </Alert>

            <DialogFooter className="sm:justify-between items-center">
              <Button
                type="button"
                variant="link"
                size="inline"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button type="submit">
                {url ? "Save AirTable Form" : "Add AirTable Form"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
