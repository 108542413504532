export const INDUSTRIES = [
  {
    value: "accounting",
    label: "Accounting",
  },
  {
    value: "agriculture",
    label: "Agriculture",
  },
  {
    value: "automotive",
    label: "Automotive",
  },
  {
    value: "aviation",
    label: "Aviation",
  },
  {
    value: "beauty",
    label: "Beauty",
  },
  {
    value: "biotechnology",
    label: "Biotechnology",
  },
  {
    value: "cannabis",
    label: "Cannabis",
  },
  {
    value: "climate",
    label: "Climate",
  },
  {
    value: "construction",
    label: "Construction",
  },
  {
    value: "consulting",
    label: "Consulting",
  },
  {
    value: "consumer_packaged_goods",
    label: "Consumer Packaged Goods",
  },
  {
    value: "consumer_tech",
    label: "Consumer Tech",
  },
  {
    value: "cybersecurity",
    label: "Cybersecurity",
  },
  {
    value: "design",
    label: "Design",
  },
  {
    value: "education",
    label: "Education",
  },
  {
    value: "energy",
    label: "Energy",
  },
  {
    value: "engineering",
    label: "Engineering",
  },
  {
    value: "enterprise_tech",
    label: "Enterprise Tech",
  },
  {
    value: "entertainment",
    label: "Entertainment",
  },
  {
    value: "events",
    label: "Events",
  },
  {
    value: "fashion",
    label: "Fashion",
  },
  {
    value: "finance_financial_services",
    label: "Finance / Financial Services",
  },
  {
    value: "fintech",
    label: "Fintech",
  },
  {
    value: "food_beverage",
    label: "Food / Beverage",
  },
  {
    value: "government",
    label: "Government",
  },
  {
    value: "hardware",
    label: "Hardware",
  },
  {
    value: "health_wellness_fitness",
    label: "Health / Wellness / Fitness",
  },
  {
    value: "healthcare",
    label: "Healthcare",
  },
  {
    value: "home_services",
    label: "Home Services",
  },
  {
    value: "hospitality",
    label: "Hospitality",
  },
  {
    value: "information_technology",
    label: "Information Technology (IT)",
  },
  {
    value: "insurance",
    label: "Insurance",
  },
  {
    value: "legal",
    label: "Legal",
  },
  {
    value: "logistics_supply_chain",
    label: "Logistics / Supply Chain",
  },
  {
    value: "luxury_goods_jewelry",
    label: "Luxury Goods / Jewelry",
  },
  {
    value: "manufacturing",
    label: "Manufacturing",
  },
  {
    value: "marketing_advertising",
    label: "Marketing / Advertising",
  },
  {
    value: "media",
    label: "Media",
  },
  {
    value: "music",
    label: "Music",
  },
  {
    value: "nonprofit",
    label: "Nonprofit",
  },
  {
    value: "real_estate",
    label: "Real Estate",
  },
  {
    value: "restaurant",
    label: "Restaurant",
  },
  {
    value: "retail",
    label: "Retail",
  },
  {
    value: "software",
    label: "Software",
  },
  {
    value: "sports",
    label: "Sports",
  },
  {
    value: "staffing_recruiting",
    label: "Staffing / Recruiting",
  },
  {
    value: "student",
    label: "Student",
  },
  {
    value: "telecommunications",
    label: "Telecommunications",
  },
  {
    value: "transportation",
    label: "Transportation",
  },
  {
    value: "travel_tourism",
    label: "Travel / Tourism",
  },
  {
    value: "utilities",
    label: "Utilities",
  },
  {
    value: "venture_capital_private_equity",
    label: "Venture Capital / Private Equity",
  },
  {
    value: "other",
    label: "Other",
  },
]
