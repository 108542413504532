import React from "react"
import { CommunitySlug } from "~/__generated__/graphql"
import { cn } from "~/common/shadcn-utils"
import { useCommunityClassname } from "~/community/useCommunity"

export const AdminHeader = ({ children }: { children: React.ReactNode }) => {
  const ccls = useCommunityClassname()

  return (
    <>
      <div
        className={cn(
          "py-4 px-6",
          ccls({
            [CommunitySlug.Boardroom]: "text-white",
            default: "text-primary",
          })
        )}
      >
        {children}
      </div>
      <div
        className={cn(
          "opacity-10 w-full h-[1px]",
          ccls({
            [CommunitySlug.Boardroom]: "bg-white",
            default: "bg-primary",
          })
        )}
      />
    </>
  )
}
