import { cn } from "~/common/shadcn-utils"

export const ClickableIconWrapper = ({
  onClick,
  border,
  blue,
  primary,
  children,
}: {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  border?: boolean
  blue?: boolean
  primary?: boolean
  children: React.ReactNode
}) => {
  return (
    <div
      className={cn(
        "rounded-full p-2 flex justify-center items-center text-2xs min-h-[34px] min-w-[34px]",
        "card-clickable bg-white hover:bg-gray-50 hover:text-black cursor-pointer text-foreground",
        {
          "border border-mercury": border,
          "border-blue-500 bg-blue-100": blue,
          "bg-primary border-primary text-white": primary,
        }
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
