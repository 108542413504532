import { useState } from "react"
import { gql } from "~/__generated__"
import {
  Article,
  ArticleRevision,
  Course,
  Lesson,
  Section,
} from "~/__generated__/graphql"
import { TagBubble } from "~/ui/TagBubble"
import TriangleUp from "~/images/icons/triangle-up.svg?react"
import TriangleDown from "~/images/icons/triangle-down.svg?react"
import { ArrowRight, Check } from "lucide-react"
import { Link } from "react-router-dom"
import { articlePath, editArticlePath } from "~/common/paths"
import { cn } from "~/common/shadcn-utils"
import { SimpleTooltip } from "~/ui/SimpleTooltip"

export type CourseIndexCourse = Pick<Course, "id"> & {
  sections: SectionModuleSection[]
}

type SectionModuleSection = Pick<Section, "id" | "title" | "description"> & {
  lessons: SectionModuleLesson[]
}

type SectionModuleLesson = Pick<Lesson, "id" | "currentUserCompleted"> & {
  article: Pick<Article, "id"> & {
    approvedRevision?: Pick<ArticleRevision, "id" | "title"> | null
    draftRevision?: Pick<ArticleRevision, "id" | "title"> | null
  }
}

export const CourseIndex = ({
  course,
  compactView = false,
  currentArticleId,
  preview = false,
}: {
  course: CourseIndexCourse
  compactView?: boolean
  currentArticleId?: string
  preview?: boolean
}) => {
  return (
    <div className="mb-20 flex flex-col gap-4 tracking-wide">
      {course.sections.map((section, ix) => (
        <SectionModule
          compactView={compactView}
          section={section}
          key={section.id}
          currentArticleId={currentArticleId}
          preview={preview}
          defaultOpen={
            (!compactView && ix === 0) ||
            (currentArticleId &&
              section.lessons?.some(
                (l) => l.article?.id === currentArticleId
              )) ||
            false
          }
        />
      ))}
    </div>
  )
}

const SectionModule = ({
  section,
  compactView = false,
  currentArticleId,
  preview = false,
  defaultOpen,
}: {
  section: SectionModuleSection
  compactView?: boolean
  currentArticleId?: string
  preview?: boolean
  defaultOpen: boolean
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const isComplete =
    section.lessons?.every((l) => l.currentUserCompleted) || false

  const tagBubble = (
    <TagBubble
      tagName="COMPLETE"
      colorClass="text-black"
      borderClass="border-black"
    />
  )

  return (
    <div className="border border-borderColor rounded-lg bg-white">
      <SimpleTooltip
        content={section.description}
        align="start"
        sideOffset={-10}
        alignOffset={10}
      >
        <div
          className={cn("cursor-pointer", compactView ? "p-4" : "p-6")}
          onClick={() => setIsOpen((currentValue) => !currentValue)}
        >
          <div className="flex items-center">
            <div className="flex items-center gap-4 font-semibold flex-1 mr-4">
              {isOpen ? <TriangleUp /> : <TriangleDown />}
              {section.title}
            </div>

            {isComplete && !compactView && tagBubble}
          </div>
        </div>
      </SimpleTooltip>

      {isOpen && (
        <div
          className={cn(
            "pb-6 flex flex-col gap-2",
            compactView ? "px-4 pb-4" : "px-6 pb-6"
          )}
        >
          {section.lessons?.map((lesson) => (
            <LessonRow
              key={lesson.id}
              lesson={lesson}
              compactView={compactView}
              active={currentArticleId === lesson.article?.id}
              preview={preview}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const LessonRow = ({
  lesson,
  compactView = false,
  active = false,
  preview = false,
}: {
  lesson: SectionModuleLesson
  compactView?: boolean
  active?: boolean
  preview?: boolean
}) => {
  const revision = preview
    ? lesson.article?.draftRevision
    : lesson.article?.approvedRevision
  const path = preview
    ? editArticlePath({ articleId: lesson.article!.id })
    : articlePath({ articleId: lesson.article!.id })

  return (
    <Link
      className={cn(
        "flex items-center group cursor-pointer text-sm text-pretext-light-gray p-3",
        "hover:text-foreground hover:bg-porcelain rounded-full",
        active && "text-foreground"
      )}
      to={path}
    >
      <div
        className={cn(
          "rounded-full bg-mantis text-white w-[20px] h-[20px] flex items-center justify-center",
          compactView ? "mr-2" : "mr-4",
          !lesson.currentUserCompleted && "invisible"
        )}
      >
        <Check size={14} />
      </div>
      <div className="flex-1">{revision?.title}</div>
      {!compactView && (
        <ArrowRight className="hidden group-hover:block" size={16} />
      )}
    </Link>
  )
}

gql(`
  fragment Lesson_LiveContent on Lesson {
    id
    currentUserCompleted
    article {
      id
      approvedRevision {
        id
        title
      }
    }
  }
`)

gql(`
  fragment Section_LiveContent on Section {
    id
    title
    description
    lessons {
      ...Lesson_LiveContent
    }
  }
`)

gql(`
  fragment Course_LiveContent on Course {
    id
    articleId
    currentUserCompletionPercentage
    currentUserCompleted
    article {
      id
      approvedRevision {
        id
        title
      }
    }
    sections {
      ...Section_LiveContent
    }
  }
`)
