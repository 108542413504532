import { useState } from "react"

import { useManualHrisDataContext } from "~/merge/manualData"

import { Input } from "~/shadcn/ui/input"

import { Base } from "./Base"
import { getMetaVarMaybe } from "~/common/getMetaVar"

const ARTICLE_ID = getMetaVarMaybe("hris-tenure-article-id")

export const ManualTenure = ({
  editContext = false,
  closeDialog,
}: {
  editContext?: boolean
  closeDialog?: () => void
}) => {
  const { next, prev, tenure, setTenure, onSuccessCallback } =
    useManualHrisDataContext()

  const [inputValue, setInputValue] = useState(tenure?.toString() || "")

  return (
    <Base
      header="Tenure"
      title="Tenure (months)"
      articleId={ARTICLE_ID && ARTICLE_ID.length > 0 ? ARTICLE_ID : null}
      prev={prev}
      next={next}
      submit={() => {
        onSuccessCallback()
        closeDialog && closeDialog()
      }}
      disabled={tenure === undefined}
      editContext={editContext}
    >
      <div className="text-xs font-medium tracking-wide text-textLight mb-1">
        Over the past 6 months, what has your company's typical tenure been?
        <span className="text-red-400">*</span>
      </div>
      <div className="mb-6">
        <Input
          type="text"
          value={inputValue}
          placeholder={"49"}
          onChange={(event) => {
            const value = event.target.value

            if (/^\d*$/.test(value)) {
              setInputValue(value)

              if (value === "") {
                setTenure(undefined)
              } else {
                setTenure(Number(value))
              }
            }
          }}
        />
      </div>
    </Base>
  )
}
