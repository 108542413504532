import { useEffect } from "react"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCommunity } from "~/community/useCommunity"
import { Alert } from "~/shadcn/ui/alert"
import { Button } from "~/shadcn/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/shadcn/ui/dialog"
import { Separator } from "~/shadcn/ui/separator"
import { useWizard } from "~/ui/Wizard"

export const SoGladYouStayedStep = () => {
  const community = useCommunity()
  const { close, meta } = useWizard()
  const { logEvent } = useLogEvent()

  useEffect(() => {
    logEvent(AhoyEventTypeEnum.CommunitySummaryMembershipKeptViewed, {
      cancellation_reason: meta.cancellationReason,
      other_cancellation_reason: meta.otherReason,
    })
  }, [logEvent, meta.cancellationReason, meta.otherReason])

  return (
    <>
      <DialogHeader>
        <DialogTitle>We're so glad you'll stay.</DialogTitle>
      </DialogHeader>
      <Alert className="flex flex-col gap-2 justify-center items-center text-center">
        <div>
          We’ve sent you an email with a note{" "}
          {community.creator.firstName && `from ${community.creator.firstName}`}{" "}
          on how to make the most of your membership as well.
        </div>
        <div className="flex gap-2 justify-center items-center">
          <div>🎉</div>
          <div>Cheers to another year of {community.name}</div>
        </div>
      </Alert>

      <Separator />

      <DialogFooter className="justify-center">
        <Button onClick={close}>Back to Membership</Button>
      </DialogFooter>
    </>
  )
}

SoGladYouStayedStep.displayName = "SoGladYouStayedStep"
