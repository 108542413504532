import { FeedSidebar } from "~/feed/FeedSidebar"
import { PageWithRightSidebar } from "~/layouts/PageWithRightSidebar"
import { Outlet } from "react-router-dom"
import { Card, CardContent, CardHeader } from "~/shadcn/ui/card"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { Mention, MentionsInput } from "react-mentions"
import { TagSelector } from "~/post-composer/TagSelector"
import { ChannelSelector } from "~/post-composer/ChannelSelector"
import { Button } from "~/shadcn/ui/button"
import AddPhoto from "../images/icons/add-photo.svg?react"
import AddVideo from "../images/icons/add-video.svg?react"
import Attachment from "../images/icons/attachment.svg?react"
import { Skeleton } from "~/shadcn/ui/skeleton"

export const FeedSkeletonLayout = () => {
  const phonyPosts = new Array(5).fill(null)

  return (
    <PageWithRightSidebar sidebar={<FeedSidebar />}>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="px-6 py-4">
            <div className="text-sm font-semibold tracking-wide">
              Community Feed
            </div>
          </CardHeader>
          <CardContent className="pt-4 pb-0 px-0">
            <div className="flex flex-col divide-y">
              <div className="px-6 pb-2">
                <div className="flex gap-2 items-start">
                  <AvatarWithFallback
                    user={{ id: "anonymous", firstName: "Anonymous" }}
                  />

                  <div className="mt-1 ml-1 flex-1">
                    <MentionsInput
                      value={""}
                      className="post-composer-content-area"
                      placeholder={"Say something!"}
                      allowSpaceInQuery
                      style={MENTION_STYLES}
                      disabled
                    >
                      <Mention
                        trigger="@"
                        data={[
                          { id: "1", display: "John Doe" },
                          { id: "2", display: "Jane Doe" },
                        ]}
                      />
                    </MentionsInput>
                  </div>
                </div>
                <TagSelector
                  tags={{ nodes: [] }}
                  tagId={null}
                  setTagId={() => {}}
                  disabled
                />
              </div>
              <ChannelSelector
                channels={{ nodes: [] }}
                channelId={null}
                setChannelId={() => {}}
                disabled
              />
              <div className="flex items-center py-2 px-6">
                <div className="cursor-pointer p-2 pl-0 text-loulou">
                  <AddVideo />
                </div>
                <div className="cursor-pointer p-2 text-loulou">
                  <AddPhoto />
                </div>
                <div className="cursor-pointer p-2 mr-auto text-loulou">
                  <Attachment />
                </div>

                <Button size="sm" className="ml-2 px-4" disabled>
                  Post
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>

        {phonyPosts.map((_, index) => (
          <Card key={index}>
            <CardContent>
              <div className="flex px-4 py-4">
                <div className="mr-3 card-clickable cursor-pointer shrink-0">
                  <Skeleton className="w-[30px] h-[30px] rounded-full" />
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <div className="flex items-center">
                    <div className="text-sm tracking-wide font-semibold mt-0.5 mr-2 card-clickable hover:underline">
                      <Skeleton className="w-[100px] h-[20px]" />
                    </div>
                    <div className="text-xs tracking-wide text-gray-400 mt-0.5">
                      <Skeleton className="w-[40px] h-[20px]" />
                    </div>
                  </div>

                  <Skeleton className="w-[120px] h-[20px] mb-4 rounded-full" />

                  <div className="flex flex-col gap-2">
                    <Skeleton className="w-full h-[20px]" />
                    <Skeleton className="w-full h-[20px]" />
                    <Skeleton className="w-full h-[20px]" />
                  </div>

                  <div className="w-full h-1 border-b border-mercury" />

                  <div className="flex justify-between items-center">
                    <div className="flex gap-2">
                      <Skeleton className="w-[34px] h-[34px] rounded-full" />
                      <Skeleton className="w-[34px] h-[34px] rounded-full" />
                    </div>
                    <div className="flex gap-2">
                      <Skeleton className="w-[34px] h-[34px] rounded-full" />
                      <Skeleton className="w-[34px] h-[34px] rounded-full" />
                      <Skeleton className="w-[34px] h-[34px] rounded-full" />
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      <Outlet />
    </PageWithRightSidebar>
  )
}

const MENTION_STYLES = {
  suggestions: {
    zIndex: 30,
    list: {
      backgroundColor: "white",
      border: "1px solid #E5E5E5",
      borderBottom: 0,
      fontSize: 14,
    },
    item: {
      padding: "5px 10px",
      borderBottom: "1px solid #E5E5E5",
      "&focused": {
        backgroundColor: "#f1f5f9",
      },
    },
  },
}
