import { useQuery } from "@apollo/client"
import { formatInTimeZone } from "date-fns-tz"
import { AdminHeader } from "~/admin/AdminHeader"
import { HeaderCell, TableCell } from "~/admin/Table"
import {
  adminNewIntroductionCyclePath,
  adminIntroductionCyclePath,
  adminEditIntroductionCyclePath,
} from "~/common/paths"
import { INTRODUCTION_CYCLES_ADMIN_LIST_QUERY_DOCUMENT } from "~/introduction-cycles/api"
import { LinkButton } from "~/shadcn/ui/button"
import { Error } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"

import People from "~/images/icons/people.svg?react"
import { INTRODUCTIONS_FIXED_TIMEZONE } from "~/introduction-cycles/IntroductionCycleForm"

export const AdminIntroductionsScreen = () => {
  const { data, loading, error, fetchMore } = useQuery(
    INTRODUCTION_CYCLES_ADMIN_LIST_QUERY_DOCUMENT
  )

  const introductionCycles =
    data?.introductionCycles?.edges.map((e) => e.node) || []

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data)
    return <Error message="Error loading introduction cycles." />

  return (
    <div>
      <AdminHeader>
        <div className="flex gap-3 items-center text-xs">
          <People height={20} width={20} />
          Introduction Cycles
        </div>
      </AdminHeader>
      <div className="px-5 pb-10 mt-4">
        <div className="flex justify-end">
          <LinkButton to={adminNewIntroductionCyclePath.pattern}>
            New Cycle
          </LinkButton>
        </div>
        <table className="bg-white table-auto">
          <tbody>
            <tr>
              <HeaderCell>Name</HeaderCell>
              <HeaderCell>Send Date</HeaderCell>
              <HeaderCell>Created</HeaderCell>
              <HeaderCell>Actions</HeaderCell>
            </tr>

            {introductionCycles.map((cycle) => (
              <tr key={cycle.id}>
                <TableCell>{cycle.name}</TableCell>
                <TableCell>
                  {formatInTimeZone(
                    cycle.sendAt,
                    INTRODUCTIONS_FIXED_TIMEZONE,
                    "M/d/yyyy p zzz"
                  )}
                </TableCell>
                <TableCell>
                  {formatInTimeZone(
                    cycle.createdAt,
                    INTRODUCTIONS_FIXED_TIMEZONE,
                    "M/d/yyyy p zzz"
                  )}
                </TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    <LinkButton
                      variant="secondary"
                      size="sm"
                      to={adminIntroductionCyclePath({
                        introductionCycleId: cycle.id,
                      })}
                    >
                      View
                    </LinkButton>
                    {!cycle.locked && (
                      <LinkButton
                        variant="secondary"
                        size="sm"
                        to={adminEditIntroductionCyclePath({
                          introductionCycleId: cycle.id,
                        })}
                      >
                        Edit
                      </LinkButton>
                    )}
                  </div>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </table>
        {data.introductionCycles && (
          <InfiniteLoadMore
            onEndReached={() => {
              if (!data.introductionCycles) {
                return
              }
              fetchMore({
                variables: {
                  after: data.introductionCycles.pageInfo.endCursor,
                },
              })
            }}
            canLoadMore={
              !loading && data.introductionCycles.pageInfo.hasNextPage
            }
            loadingText="Loading introduction cycles..."
            loading={loading && introductionCycles.length > 0}
          />
        )}
      </div>
    </div>
  )
}
