import * as React from "react"
import clsx from "clsx"
import { ScrollArea } from "@radix-ui/react-scroll-area"
import { ChevronsUpDown } from "lucide-react"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/shadcn/ui/dropdown-menu"

import { Badge } from "~/shadcn/ui/badge"
import { cn } from "./shadcn-utils"
import { useCommunityClassname } from "~/community/useCommunity"
import { CommunitySlug } from "~/__generated__/graphql"

export type BadgeOptionType = {
  label: string
  value: string
}

interface DropdownMenuBadgesProps {
  title: string
  optionTitle?: string
  options: BadgeOptionType[]
  selected: BadgeOptionType[]
  onChange: (option: BadgeOptionType[]) => void
}

export function DropdownMenuBadges({
  title,
  optionTitle,
  options,
  selected,
  onChange,
}: DropdownMenuBadgesProps) {
  const ccls = useCommunityClassname()
  const isChecked = (value: string) => {
    const checked = selected.find((s) => s.value === value)

    return !!checked
  }

  const onClick = (option: BadgeOptionType, checked: boolean) => {
    return () => {
      if (checked) {
        onChange(selected.filter((s) => s.value !== option.value))
      } else {
        onChange([...selected, option])
      }
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div
          className={clsx(
            "border border-mercury h-10 rounded-md background-white flex items-center justify-between py-2 px-3 text-xs",
            selected.length > 0 &&
              ccls({
                [CommunitySlug.Marketingland]: "text-library-dropdown-badges",
                default: "text-primary",
              })
          )}
          role="button"
        >
          {title}
          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-96 p-4 rounded-lg" align="end">
        {optionTitle && (
          <>
            <div className="text-3xs text-placeholder tracking-wide mb-2">
              {optionTitle}
            </div>
            <DropdownMenuSeparator />
          </>
        )}
        <ScrollArea
          className={clsx(
            "m-h-72 ScrollArea overflow-y-auto overflow-x-hidden"
          )}
        >
          <div className="flex flex-wrap items-center justify-start space-y-1 space-x-1">
            {options.map((option, i) => {
              const checked = isChecked(option.value)

              return (
                <div role="button" key={option.value}>
                  <Badge
                    aria-checked={checked}
                    className={cn(
                      "DropdownMenuBadge text-sm hover:bg-library-dropdown-badges hover:text-white",
                      {
                        "bg-library-dropdown-badges text-white": checked,
                        "bg-white text-library-dropdown-badges border-library-dropdown-badges":
                          !checked,
                      }
                    )}
                    onClick={onClick(option, checked)}
                  >
                    <span className="truncate">{option.label}</span>
                    <span
                      className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onClick(option, checked)()
                        }
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onClick={onClick(option, checked)}
                    ></span>
                  </Badge>
                </div>
              )
            })}
          </div>
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
