import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import toast from "react-hot-toast"
import { useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { EditorMetadataSection } from "~/editor/EditorMetadataSection"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { Button } from "~/shadcn/ui/button"
import { Plus } from "lucide-react"
import { CreateSectionModal } from "~/content/CreateSectionModal"
import { SectionsEditor } from "~/content/SectionsEditor"
import {
  SavedOrUnsavedSection,
  lessonId,
  isSavedSection,
  isSavedLesson,
  isSavedArticleCollaborator,
  SavedOrUnsavedCourse,
  getArticleId,
  sectionId,
  draftRevisionId,
} from "~/types"
import { CourseIndex, CourseIndexCourse } from "~/content/CourseIndex"
import { CourseActionToolbar } from "~/content/CourseActionToolbar"
import { useDirtyTracking } from "~/editor/useDirtyTracking"
import {
  Article_CourseEditorContentFragment,
  Article_EditorContentFragment,
} from "~/__generated__/graphql"

type SetSectionsCallback = (
  sections: SavedOrUnsavedSection[]
) => SavedOrUnsavedSection[]

type CourseEditorContextType = {
  article: Article_EditorContentFragment & Article_CourseEditorContentFragment
  course: SavedOrUnsavedCourse
  hasChanges: boolean
  sections: SavedOrUnsavedSection[]
  setSections: (
    sectionsOrCallback: SavedOrUnsavedSection[] | SetSectionsCallback
  ) => void
}

const CourseEditorContext = createContext<CourseEditorContextType>(
  {} as CourseEditorContextType
)

export const useCourseEditorContext = () => {
  const context = useContext(CourseEditorContext)
  invariant(
    context,
    "useCourseEditorContext must be used within a CourseEditorContextProvider"
  )
  return context
}

const getTrackableSections = (sections: SavedOrUnsavedSection[]) => {
  return sections.map((section) => ({
    title: section.title,
    position: section.position,
    description: section.description,
    hidden: section.hidden,
    lessons: section.lessons.map((lesson) => ({
      title: lesson.article.draftRevision.title,
      position: lesson.position,
      hidden: lesson.hidden,
      collaborators: lesson.article.collaborators?.map(
        (collaborator) => collaborator.user.id
      ),
    })),
  }))
}

export const CourseEditor = ({
  article,
}: {
  article: Article_EditorContentFragment & Article_CourseEditorContentFragment
}) => {
  const { articleId } = useParams()
  invariant(articleId)

  const [isPreviewing, setIsPreviewing] = useState(false)
  const [isCreateSectionModalOpen, setIsCreateSectionModalOpen] =
    useState(false)
  const [searchParams] = useSearchParams()

  const [course, setCourse] = useState<SavedOrUnsavedCourse | undefined>(
    article?.draftCourse || undefined
  )
  const [savedTrackedCourse, setSavedTrackedCourse] = useState<
    SavedOrUnsavedCourse | undefined
  >(article?.draftCourse || undefined)
  useEffect(() => {
    if (article?.draftCourse) {
      setCourse(article.draftCourse)
    }
  }, [article.draftCourse])
  const sections = useMemo(() => course?.sections || [], [course])

  const setSections = useCallback(
    (sectionsOrCallback: SavedOrUnsavedSection[] | SetSectionsCallback) => {
      setCourse((course) => {
        if (!course) {
          return course
        }

        const newSections =
          typeof sectionsOrCallback === "function"
            ? sectionsOrCallback(sections)
            : sectionsOrCallback

        return {
          ...course,
          sections: newSections,
        }
      })
    },
    [sections]
  )

  const savedTrackedObject = useMemo(() => {
    return {
      sections: getTrackableSections(savedTrackedCourse?.sections || []),
      collaborators: article.collaborators,
    }
  }, [savedTrackedCourse, article])

  const currentTrackedObject = useMemo(() => {
    return {
      sections: getTrackableSections(sections),
      collaborators: article.collaborators,
    }
  }, [sections, article])

  const dirtyTracking = useDirtyTracking({
    currentTrackedObject,
    savedTrackedObject,
  })

  const previewCourse: CourseIndexCourse | null = useMemo(() => {
    if (!course) {
      return null
    }

    return {
      ...course,
      sections: course.sections
        .filter((section) => !section.hidden)
        .map((section) => ({
          ...section,
          id: sectionId(section),
          lessons: section.lessons
            .filter((lesson) => !lesson.hidden)
            .map((lesson) => ({
              ...lesson,
              id: lessonId(lesson),
              currentUserCompleted: false,
              article: {
                ...lesson.article,
                id: getArticleId(lesson.article),
                approvedRevision: lesson.article.approvedRevision
                  ? {
                      id: lesson.article.approvedRevision.id,
                      title: lesson.article.approvedRevision.title,
                    }
                  : null,
                draftRevision: {
                  id: draftRevisionId(lesson.article.draftRevision),
                  title: lesson.article.draftRevision.title,
                },
              },
            })),
        })),
    }
  }, [course])

  const [runSaveCourse, saveCourseResult] = useSafeMutation(
    UPDATE_COURSE_MUTATION
  )

  const saveCourse = async () => {
    const { data, errors } = await runSaveCourse({
      variables: {
        input: {
          articleId,
          sections: sections.map((section, index) => ({
            id: isSavedSection(section) ? section.id : undefined,
            position: index,
            title: section.title,
            hidden: section.hidden,
            description: section.description,
            lessons: section.lessons.map((lesson, lessonIndex) => ({
              id: isSavedLesson(lesson) ? lesson.id : undefined,
              position: lessonIndex,
              title: lesson.article.draftRevision.title,
              tagId: lesson.article.draftRevision.tag.id,
              description: lesson.article.draftRevision.description,
              hidden: lesson.hidden,
              editorjsContent: lesson.article.draftRevision.editorjsContent,
              collaborators: lesson.article.collaborators?.map(
                (collaborator) => ({
                  id: isSavedArticleCollaborator(collaborator)
                    ? collaborator.id
                    : undefined,
                  userId: collaborator.user.id,
                  pending: collaborator.pending,
                })
              ),
            })),
          })),
        },
      },
    })

    dirtyTracking.onSave(errors)

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else if (data?.courseUpdate) {
      toast.success("Course saved")
      invariant(data.courseUpdate.article.draftCourse)
      setCourse(data.courseUpdate.article.draftCourse)
      setSavedTrackedCourse(data.courseUpdate.article.draftCourse)
    }
  }

  const openCreateSectionModal = useCallback(() => {
    setIsCreateSectionModalOpen(true)
  }, [])

  const onCreateSection = useCallback(() => {
    setIsCreateSectionModalOpen(false)
  }, [])

  invariant(course, "course must be present")

  return (
    <CourseEditorContext.Provider
      value={{
        course,
        article,
        hasChanges: dirtyTracking.isDirty,
        sections,
        setSections,
      }}
    >
      <div className="flex-1">
        <CourseActionToolbar
          isPreviewing={isPreviewing}
          save={saveCourse}
          preview={() => setIsPreviewing(true)}
          edit={() => setIsPreviewing(false)}
          isSaving={saveCourseResult.loading}
          isLocked={article.isLockedBySomeoneElse}
        />

        <Card className="mt-0 mb-12 flex-1">
          <CardHeader>
            <CardTitle>Course Editor</CardTitle>
          </CardHeader>

          <EditorMetadataSection
            article={article}
            revisionHistoryDefaultOpen={searchParams.get("revisions") !== null}
          />

          <CardContent className="p-6">
            <Button
              pre={<Plus className="w-[12px] h-[12px]" />}
              onClick={openCreateSectionModal}
              size="sm"
              className="text-2xs mb-4"
            >
              Add Module
            </Button>
            <CreateSectionModal
              onCreateSection={onCreateSection}
              open={isCreateSectionModalOpen}
              onOpenChange={setIsCreateSectionModalOpen}
            />
            {!isPreviewing && <SectionsEditor />}
            {isPreviewing && previewCourse && (
              <CourseIndex course={previewCourse} preview={true} />
            )}
          </CardContent>
        </Card>
      </div>
    </CourseEditorContext.Provider>
  )
}

gql(`
  fragment Course_EditorContent on Course {
    id

    sections(includeHidden: true) {
      ...Section_EditorContent
    }
  }
`)

gql(`
  fragment Section_EditorContent on Section {
    id
    title
    description
    hidden
    position

    lessons(includeHidden: true) {
      ...Lesson_EditorContent
    }
  }
`)

gql(`
  fragment Lesson_EditorContent on Lesson {
    id
    hidden
    position
    sectionId

    article {
      ...Article_EditorContent
    }
  }
`)

const UPDATE_COURSE_MUTATION = gql(`
  mutation UpdateCourse($input: CourseUpdateInput!) {
    courseUpdate(input: $input) {
      article {
        ...Article_EditorContent
        ...Article_CourseEditorContent
      }
    }
  }
`)
