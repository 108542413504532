import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/common/shadcn-utils"

const switchVariants = cva(
  "peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
        green:
          "data-[state=checked]:bg-seaGreen data-[state=unchecked]:bg-input",
        selector:
          "bg-primary w-auto relative grid place-content-center items-center",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
    VariantProps<typeof switchVariants> {
  options?: [string, string]
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(
  (
    { className, variant, options, checked, onCheckedChange, ...props },
    ref
  ) => (
    <SwitchPrimitives.Root
      className={cn(switchVariants({ variant }), className)}
      {...props}
      checked={checked}
      onCheckedChange={onCheckedChange}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          "relative pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-full data-[state=unchecked]:translate-x-0",
          variant === "selector" &&
            "grid place-content-center items-center p-1 w-fit text-4xs font-normal"
        )}
        style={{ gridArea: "1 / 1" }}
      >
        {options?.map((option, index) => (
          <span
            key={option}
            className={cn(
              "transition-opacity",
              (checked && index === 0) || (!checked && index === 1)
                ? "opacity-0"
                : "opacity-100"
            )}
            style={{ gridArea: "1 / 1" }}
          >
            {option}
          </span>
        ))}
      </SwitchPrimitives.Thumb>
      {options && (
        <div className="flex text-white" style={{ gridArea: "1 / 1" }}>
          {options?.map((_, outerIndex) => (
            <div
              className="grid place-content-center items-center p-1 h-5 text-4xs font-normal"
              key={outerIndex}
            >
              {options.map((option, innerIndex) => (
                <span
                  key={`${outerIndex}-${innerIndex}`}
                  className={cn(
                    outerIndex === innerIndex ? "opacity-100" : "opacity-0"
                  )}
                  style={{ gridArea: "1 / 1" }}
                >
                  {option}
                </span>
              ))}
            </div>
          ))}
        </div>
      )}
    </SwitchPrimitives.Root>
  )
)
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
