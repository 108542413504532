import { EyeNoneIcon } from "@radix-ui/react-icons"
import { useState } from "react"
import { UserSortEnum, User_AdminFragment } from "~/__generated__/graphql"
import { UserDialog, userLocation } from "~/directory/UserDialog"
import { Button } from "~/shadcn/ui/button"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/shadcn/ui/tooltip"
import { Card, CardContent } from "~/shadcn/ui/card"
import { Skeleton } from "~/shadcn/ui/skeleton"
import { UsersTable } from "./UsersTable"
import { UserName } from "~/directory/UserName"
import { FitProfileDisplay } from "./FitProfileDisplay"
import { formatTime } from "~/common/formatDate"
import { parseISO } from "date-fns"
import { CopyToClipboard } from "~/ui/CopyToClipboard"

const UnfitMembersTableSkeleton = () => (
  <div className="flex flex-col gap-2">
    {Array.from({ length: PAGE_SIZE }).map((_, index) => (
      <div key={index}>
        <Card>
          <CardContent className="pt-4">
            <div className="flex gap-2 items-center">
              <div className="flex gap-2 flex-grow">
                <Skeleton className="rounded-full w-[60px] h-[60px]" />
                <div className="flex flex-col gap-1">
                  <Skeleton className="w-[120px] h-[16px]" />
                  <Skeleton className="w-[100px] h-[16px]" />
                  <Skeleton className="w-[140px] h-[16px]" />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    ))}
  </div>
)

type UserRowProps = {
  user: User_AdminFragment
}

const UserRow = ({ user }: UserRowProps) => {
  const [userDialogOpen, setUserDialogOpen] = useState(false)

  return (
    <>
      {userDialogOpen && (
        <UserDialog
          isOpen={userDialogOpen}
          onClose={() => setUserDialogOpen(false)}
          user={user}
        />
      )}

      <Card>
        <CardContent className="pt-4">
          <div className="flex gap-2 flex-wrap items-center">
            <div className="flex gap-2 flex-grow">
              <div>
                <AvatarWithFallback user={user} size="2xl" />
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex gap-2 items-center">
                  <Button
                    variant="link"
                    size="inline"
                    className="text-primary"
                    onClick={() => setUserDialogOpen(true)}
                  >
                    <UserName user={user} />
                  </Button>
                  {user.hidden && (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <EyeNoneIcon className="text-pretext-grey" />
                        </TooltipTrigger>
                        <TooltipContent>
                          User is hidden from the directory
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </div>

                <div className="text-2xs flex flex-col items-start gap-2">
                  <CopyToClipboard
                    text={user.email!}
                    className="text-2xs text-gray-500"
                  >
                    {user.email}
                  </CopyToClipboard>
                  <div>
                    Tier: <strong>{user.tier?.name}</strong>
                  </div>
                  <div>
                    Application completed at:{" "}
                    <strong>
                      {user.fitApplicationSubmittedAt
                        ? formatTime(parseISO(user.fitApplicationSubmittedAt))
                        : "n/a"}
                    </strong>
                  </div>
                  <div className="text-pretext-light-gray text-2xs">
                    {user.jobTitle} - {user.companyName || "Unknown Company"} -{" "}
                    {userLocation(user.place) || "Unknown Location"}
                  </div>
                  <FitProfileDisplay user={user} />
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  )
}

const PAGE_SIZE = 10
const DEFAULT_SORT = UserSortEnum.ApplicationPriority

export const UnfitMembersTable = () => (
  <UsersTable
    withSearch
    variant="card"
    skeleton={<UnfitMembersTableSkeleton />}
    defaultSort={DEFAULT_SORT}
    queryOptions={{ unfitOnly: true }}
  >
    {(user) => <UserRow key={user.id} user={user} />}
  </UsersTable>
)
