import { QueryResult, useQuery } from "@apollo/client"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { articlePath, editArticlePath } from "~/common/paths"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import { ContributeToCommunityCallout } from "~/articles/ContributeToCommunityCallout"
import {
  ArticleVisibilityEnum,
  Article_MyContentTableFragment,
  Exact,
  InputMaybe,
  MyContentQuery,
} from "~/__generated__/graphql"
import { ReactNode, useCallback, useMemo, useRef, useState } from "react"
import { cn } from "~/common/shadcn-utils"
import {
  ARTICLE_CONTENT_TYPE_LABELS,
  ARTICLE_STATE_LABELS,
  ARTICLE_VISIBILITY_LABELS,
} from "~/common/enumTranslations"
import { compareAsc, format, parseISO } from "date-fns"
import {
  ChevronLeft,
  ChevronRight,
  MoreHorizontal,
  Square,
  SquareCheck,
} from "lucide-react"
import { Card, CardHeader, CardTitle } from "~/shadcn/ui/card"
import Driver from "~/images/icons/driver.svg?react"
import ReactPaginate from "react-paginate"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { StackedUserAvatars } from "~/ui/StackedUserAvatars"
import { CollaborationInvites } from "~/articles/CollaborationInvites"
import { ArticleCard } from "~/ui/ArticleCard"
import { CONTENT_TYPES_FOR_COMMUNITY } from "~/library/contentTypes"
import { useCommunity } from "~/community/useCommunity"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/shadcn/ui/context-menu"
import { useToggleFeaturedConfirm } from "~/content/useToggleFeaturedConfirm"
import { IconButton } from "~/ui/IconButton"
import { SimpleTooltip } from "~/ui/SimpleTooltip"
import { ExternalShareSettingsModal } from "~/articles/ExternalShareSettingModal"
import { useTogglePremiumConfirm } from "~/content/useTogglePremiumConfirm"

const PER_PAGE = 25

export const MyContentScreen = () => {
  const { currentUser } = useCurrentUser()
  const community = useCommunity()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get("page") || "1")
  const myContentResult = useQuery(ARTICLES_QUERY_DOCUMENT, {
    variables: {
      first: PER_PAGE,
      after: btoa(((page - 1) * PER_PAGE).toString()),
      contentTypes: CONTENT_TYPES_FOR_COMMUNITY[community.slug],
    },
  })

  const paginate = useCallback(
    (page: number) => {
      setSearchParams({ page: page.toString() })
    },
    [setSearchParams]
  )

  const pageCount = Math.ceil(
    (myContentResult.data?.myContent.totalCount || 0) / PER_PAGE
  )

  const contentString = currentUser.admin ? "Manage Content" : "My Content"
  const afterInviteAccept = useCallback(() => {
    paginate(1)
    myContentResult.refetch()
  }, [paginate, myContentResult])

  return (
    <>
      <ContributeToCommunityCallout />

      <Card className="mt-5 mb-12 flex-1">
        <CardHeader>
          <CardTitle>{contentString}</CardTitle>
        </CardHeader>

        <CollaborationInvites afterAccept={afterInviteAccept} />

        <MyContentTable result={myContentResult} />
        {pageCount > 1 && (
          <ReactPaginate
            breakLabel={<MoreHorizontal size={18} />}
            nextLabel={<ChevronRight size={18} />}
            previousLabel={<ChevronLeft size={18} />}
            forcePage={page - 1}
            onPageChange={({ selected }) => paginate(selected + 1)}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(
              (myContentResult.data?.myContent.totalCount || 0) / PER_PAGE
            )}
            renderOnZeroPageCount={null}
            containerClassName="flex items-center justify-center gap-4 mb-8 text-sm"
            disabledLinkClassName="cursor-not-allowed text-gray-300"
            activeLinkClassName="text-blue-600 font-semibold"
          />
        )}
      </Card>
    </>
  )
}

const MyContentTable = ({
  result,
}: {
  result: QueryResult<
    MyContentQuery,
    Exact<{
      first?: InputMaybe<number> | undefined
      after?: InputMaybe<string> | undefined
    }>
  >
}) => {
  const { data, loading, error } = result

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading articles." />

  const articles = data.myContent.nodes

  if (articles.length === 0) {
    return (
      <div className="text-primary tracking-wide px-4 py-24 flex flex-col items-center">
        <div className="border-2 rounded-full border-primary w-[84px] h-[84px] flex items-center justify-center">
          <Driver width="28" height="28" />
        </div>

        <div className="my-4 text-center font-semibold text-2xl">
          No Content
        </div>

        <div className="text-center font-medium text-sm max-w-[350px]">
          You have not created any content yet. Use the buttons above to get
          started!
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="px-6">
        <MyContentCardView articles={articles} />

        <div className="hidden lg:block overflow-x-auto">
          <table className="w-full my-9 [overflow-wrap:normal]">
            <tbody>
              <tr>
                <Header>Title/Description</Header>
                <Header>Authors</Header>
                <Header>Topic</Header>
                <Header>Type</Header>
                <Header>Status</Header>
                <Header>Visibility</Header>
                <Header className="whitespace-nowrap">Featured</Header>
                <Header className="whitespace-nowrap">External Share</Header>
                <Header className="min-w-[110px]">Created</Header>
                <Header className="text-center">Actions</Header>
              </tr>

              {articles.map((article) => (
                <ArticleRow key={article.id} article={article} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const ArticleRow = ({
  article,
}: {
  article: Article_MyContentTableFragment
}) => {
  const navigate = useNavigate()
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)
  const [externalSharingModalOpen, setExternalSharingModalOpen] =
    useState(false)

  const isFeatured = useMemo(
    () =>
      article.featuredUntil &&
      compareAsc(parseISO(article.featuredUntil), new Date()) === 1,
    [article]
  )

  const goToEditArticle = () => {
    navigate(editArticlePath({ articleId: article.id }))
  }

  const goToLiveArticle = () => {
    navigate(articlePath({ articleId: article.id }))
  }

  const toggleFeatured = useToggleFeaturedConfirm({ article })

  const togglePremium = useTogglePremiumConfirm({ article })

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  return (
    <>
      <ContextMenu>
        <ContextMenuContent>
          <ContextMenuItem onClick={goToEditArticle} className="cursor-pointer">
            Edit
          </ContextMenuItem>

          {article.visibility === ArticleVisibilityEnum.Live && (
            <ContextMenuItem
              className="cursor-pointer"
              onClick={goToLiveArticle}
            >
              View Live Article
            </ContextMenuItem>
          )}

          {article.currentUserCanMarkAsFeatured.value && (
            <ContextMenuItem
              className="cursor-pointer"
              onClick={toggleFeatured}
            >
              {isFeatured ? "Unfeature" : "Feature"}
            </ContextMenuItem>
          )}

          {article.currentUserCanMarkAsPremium.value && (
            <ContextMenuItem className="cursor-pointer" onClick={togglePremium}>
              {article.premium ? "Unmark as Premium" : "Mark as Premium"}
            </ContextMenuItem>
          )}

          {article.currentUserCanChangeExternalShareSetting.value && (
            <ContextMenuItem
              className="cursor-pointer"
              onClick={() => setExternalSharingModalOpen(true)}
            >
              {article.allowExternalShare ? "Disable" : "Allow"} External
              Sharing
            </ContextMenuItem>
          )}
        </ContextMenuContent>
        <ContextMenuTrigger asChild>
          <tr key={article.id} ref={contextMenuTriggerRef}>
            <Cell className="w-[250px]">
              <Link to={editArticlePath({ articleId: article.id })}>
                <p className="text-xs font-semibold mb-1 line-clamp-2">
                  {article.draftRevision.title}
                </p>
                <p className="line-clamp-2">
                  {article.draftRevision.description}
                </p>
              </Link>
            </Cell>
            <Cell>
              <div className="flex items-center pl-3 pt-3 flex-wrap min-w-[100px]">
                <StackedUserAvatars
                  users={article.collaborators.map((c) => c.user)}
                />
              </div>
            </Cell>
            <Cell>{article.draftRevision.tag.name}</Cell>
            <Cell>
              {article.draftRevision.contentType &&
                ARTICLE_CONTENT_TYPE_LABELS[article.draftRevision.contentType]}
            </Cell>
            <Cell>{ARTICLE_STATE_LABELS[article.state]}</Cell>
            <Cell>{ARTICLE_VISIBILITY_LABELS[article.visibility]}</Cell>
            <Cell className="whitespace-nowrap">
              <SimpleTooltip
                content={
                  isFeatured
                    ? `Featured until ${format(
                        article.featuredUntil,
                        "MMM d, yyyy 'at' h:mm bbb"
                      )}`
                    : ""
                }
              >
                {article.currentUserCanMarkAsFeatured.value ? (
                  <IconButton
                    className={cn(
                      isFeatured && "text-highlight",
                      "cursor-pointer"
                    )}
                    onClick={toggleFeatured}
                  >
                    {isFeatured ? (
                      <SquareCheck size={16} />
                    ) : (
                      <Square size={16} />
                    )}
                  </IconButton>
                ) : (
                  <>
                    {isFeatured ? (
                      <SquareCheck size={16} />
                    ) : (
                      <Square size={16} />
                    )}
                  </>
                )}
              </SimpleTooltip>
            </Cell>
            <Cell>
              <SimpleTooltip
                content={
                  isFeatured
                    ? `Featured until ${format(
                        article.featuredUntil,
                        "MMM d, yyyy 'at' h:mm bbb"
                      )}`
                    : ""
                }
              >
                {article.currentUserCanChangeExternalShareSetting.value ? (
                  <IconButton
                    className={cn(
                      article.allowExternalShare && "text-highlight",
                      "cursor-pointer"
                    )}
                    onClick={() => setExternalSharingModalOpen(true)}
                  >
                    {article.allowExternalShare ? (
                      <SquareCheck size={16} />
                    ) : (
                      <Square size={16} />
                    )}
                  </IconButton>
                ) : (
                  <>
                    {article.allowExternalShare ? (
                      <SquareCheck size={16} />
                    ) : (
                      <Square size={16} />
                    )}
                  </>
                )}
              </SimpleTooltip>
              <ExternalShareSettingsModal
                isOpen={externalSharingModalOpen}
                setIsOpen={setExternalSharingModalOpen}
                articleId={article.id}
                allowExternalShare={article.allowExternalShare}
              />
            </Cell>
            <Cell>{format(parseISO(article.createdAt), "LLL d, yyyy")}</Cell>
            <Cell className="text-center">
              <IconButton onClick={triggerContextMenu}>
                <MoreHorizontal size={16} />
              </IconButton>
            </Cell>
          </tr>
        </ContextMenuTrigger>
      </ContextMenu>
    </>
  )
}

const Header = ({
  children,
  className,
}: {
  children?: ReactNode
  className?: string
}) => (
  <th
    className={cn(
      "border-b text-left font-medium text-xs px-3 py-2",
      className
    )}
  >
    {children}
  </th>
)

const Cell = ({
  children,
  className,
}: {
  children?: ReactNode
  className?: string
}) => (
  <td className={cn("border-b px-3 py-6 text-slate-600 text-2xs", className)}>
    {children}
  </td>
)

type MyContentCardViewProps = {
  articles: Article_MyContentTableFragment[]
}

const MyContentCardView = ({ articles }: MyContentCardViewProps) => {
  return (
    <div className="block lg:hidden flex flex-col gap-4">
      {articles.map((article) => (
        <ArticleCard key={article.id} article={article} editMode wideVersion />
      ))}
    </div>
  )
}

gql(`
  fragment Article_MyContentTable on Article {
    id
    createdAt
    markedLiveAt
    state
    visibility
    featuredUntil
    premium
    allowExternalShare
    collaborators {
      id
      user {
        id
        ...User_Avatar
      }
    }
    draftRevision {
      id
      title
      description
      contentType
      contentVersion
      tag {
        id
        name
      }
    }
    createdAt

    currentUserCanMarkAsFeatured { value }
    currentUserCanMarkAsPremium { value }
    currentUserCanChangeExternalShareSetting { value }
  }
`)

const ARTICLES_QUERY_DOCUMENT = gql(`
  query MyContent($first: Int, $after: String, $contentTypes: [String!]) {
    myContent(first: $first, after: $after, contentTypes: $contentTypes) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ...Article_MyContentTable
      }
    }
  }
`)
