export type CelebrationType = {
  label: string
  value: string
  emoji: string
  customLabel?: string
}

export const otherCelebrationType: CelebrationType = {
  label: "Other",
  value: "other",
  emoji: "✨",
}

export const celebrationTypes: CelebrationType[] = [
  {
    label: "Birthday",
    value: "birthday",
    emoji: "🎂",
  },
  {
    label: "Work Anniversary",
    value: "work_anniversary",
    emoji: "📆",
  },
  {
    label: "Toxic Workplace Breakup Anniversary",
    value: "toxic_workplace_breakup",
    emoji: "🍾",
  },
  otherCelebrationType,
]

export const celebrationTypesByValue = celebrationTypes.reduce(
  (acc, type) => {
    acc[type.value] = type
    return acc
  },
  {} as Record<string, CelebrationType>
)
