import { Dialog, DialogContent } from "~/shadcn/ui/dialog"
import { Button } from "~/shadcn/ui/button"
import { useLazyQuery } from "@apollo/client"
import AsyncSelect from "react-select/async"
import { useCallback, useEffect } from "react"
import { MENTION_USER_QUERY_DOCUMENT } from "~/post-composer/useMentionDataSource"
import { useDebouncedCallback } from "use-debounce"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { UserPill } from "~/ui/UserPill"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { User_AvatarFragment } from "~/__generated__/graphql"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/shadcn/ui/tooltip"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { MAX_GROUP_MEMBERS } from "./StartRoomModal"
import { UserName } from "~/directory/UserName"

type UserOption = {
  value: string
  label: string
  data: {
    id: string
    name: string
    firstName: string
    lastName: string
    photoUrl?: string
  }
}

const formSchema = z.object({
  canSeeFullHistory: z.boolean(),
  selectedUsers: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        photoUrl: z.string().optional(),
      })
    )
    .min(1, { message: "Select at least 1 person to add" }),
})

export type FormValues = z.infer<typeof formSchema>

export const AddMembersModal = ({
  isOpen,
  setIsOpen,
  roomId,
  currentMembers,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  roomId: string
  currentMembers: User_AvatarFragment[]
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      canSeeFullHistory: true,
      selectedUsers: [],
    },
  })

  const [getCollaboratorSearch] = useLazyQuery(MENTION_USER_QUERY_DOCUMENT, {
    variables: { excludeSelf: true },
  })

  const [runMutation, mutationResult] = useSafeMutation(
    ROOM_ADD_MEMBERS_MUTATION
  )

  const onSubmit = async (values: FormValues) => {
    const { data, errors } = await runMutation({
      variables: {
        input: {
          roomId,
          canSeeFullHistory: values.canSeeFullHistory,
          userIds: values.selectedUsers.map((u) => u.id),
        },
      },
    })
    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      invariant(data)
      toast.success("Added members")
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen === false) form.reset()
  }, [isOpen, form])

  const _loadOptions = useCallback(
    (query: string, callback: (options: UserOption[]) => void) => {
      getCollaboratorSearch({
        variables: { query },
      }).then(({ data }) => {
        if (!data) {
          callback([])
        } else {
          callback(
            data.users.nodes
              .map((u) => ({
                value: u.id,
                label: u.name || "",
                data: {
                  id: u.id,
                  name: u.name || "",
                  firstName: u.firstName || "",
                  lastName: u.lastName || "",
                  photoUrl: u.photoUrl || undefined,
                },
              }))
              .filter((u) => !currentMembers.find((cm) => cm.id === u.value))
          )
        }
      })
    },
    [getCollaboratorSearch, currentMembers]
  )
  const loadOptions = useDebouncedCallback(_loadOptions, 400)

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="mb-6 text-center font-serif text-xl">
          Manage Members
        </div>

        <div className="mb-6">
          <div className="text-sm font-medium mb-2">Current Members</div>
          <div className="flex flex-wrap gap-1">
            {currentMembers.map((user) => (
              <TooltipProvider key={user.id}>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <div>
                      <AvatarWithFallback user={user} size="preview" />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <UserName user={user} />
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col mb-4 gap-6">
              <FormField
                control={form.control}
                name="selectedUsers"
                render={() => (
                  <FormItem>
                    <FormLabel required className="block">
                      Add members
                    </FormLabel>
                    <FormControl>
                      <AsyncSelect
                        classNamePrefix="select"
                        className="flex-1"
                        placeholder="Who to add..."
                        loadOptions={loadOptions}
                        value={null}
                        onChange={(selection) => {
                          if (selection?.value) {
                            if (
                              form.watch("selectedUsers").length +
                                currentMembers.length >=
                              MAX_GROUP_MEMBERS
                            ) {
                              toast.error(
                                `Groups may only have up to ${MAX_GROUP_MEMBERS} members`
                              )
                              return
                            }
                            form.setValue("selectedUsers", [
                              selection.data,
                              ...form.getValues()["selectedUsers"],
                            ])
                          }
                        }}
                        styles={{
                          control: (baseStyles, _state) => ({
                            ...baseStyles,
                            outline: "none !important",
                            boxShadow: "none",
                            paddingTop: "0.25rem",
                            paddingBottom: "0.25rem",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            fontSize: 16,
                            borderRadius: 8,
                          }),
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {form.watch("selectedUsers").length > 0 && (
                <div className="flex flex-wrap gap-3">
                  {form.watch("selectedUsers").map((user) => (
                    <UserPill
                      user={user}
                      key={user.id}
                      canRemove
                      onRemove={() => {
                        form.setValue(
                          "selectedUsers",
                          form
                            .getValues("selectedUsers")
                            .filter((item) => item.id !== user.id)
                        )
                      }}
                    />
                  ))}
                </div>
              )}

              <FormField
                control={form.control}
                name="canSeeFullHistory"
                render={({ field }) => (
                  <FormItem className="flex items-center gap-2">
                    <FormControl>
                      <input
                        type="checkbox"
                        className="bg-white border-mercury rounded-md h-[20px] w-[20px]"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    </FormControl>
                    <FormLabel className="block !mt-0">
                      Allow new members to see full group message history?
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="h-[1px] bg-gray-300 my-8" />

            <div className="flex justify-end gap-4">
              <Button
                type="button"
                variant="outline"
                className="px-10"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="px-10"
                isLoading={mutationResult.loading}
                disabled={mutationResult.loading}
              >
                Add Members
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const ROOM_ADD_MEMBERS_MUTATION = gql(`
  mutation RoomAddMembersModal($input: RoomAddMembersInput!) {
    roomAddMembers(input: $input) {
      room {
        id
        ...Room_MessageList
        users {
          ...User_Avatar
          name
        }
      }
    }
  }
`)
