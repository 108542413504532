import {
  Link,
  NavLink,
  NavLinkProps,
  Outlet,
  useNavigate,
} from "react-router-dom"
import safeSpaceLogo from "../images/safe-space-logo.svg"
import boardroomLogo from "../images/boardroom-logo.svg"
import marketinglandLogo from "../images/marketingland-logo.svg"
import People from "../images/icons/people.svg?react"
import Messages from "../images/icons/messages.svg?react"
import Logout from "../images/icons/logout.svg?react"
import CalendarGroup from "../images/icons/calendar-group.svg?react"
import {
  adminChannelsPath,
  adminIntroductionsPath,
  adminMembersPath,
  adminProEventsPath,
  feedPath,
} from "~/common/paths"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useEffect } from "react"
import { cn } from "~/common/shadcn-utils"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { CommunitySlug } from "~/__generated__/graphql"
import { UserDialogContextProvider } from "~/directory/UserDialogContext"

export const AdminLayout = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUser()
  const community = useCommunity()
  const ccls = useCommunityClassname()

  useEffect(() => {
    if (!currentUser.admin) {
      navigate(feedPath.pattern)
    }
  }, [currentUser, navigate])

  const communityLogo: Record<CommunitySlug, string> = {
    [CommunitySlug.Safespace]: safeSpaceLogo,
    [CommunitySlug.Boardroom]: boardroomLogo,
    [CommunitySlug.Marketingland]: marketinglandLogo,
  }

  return (
    <UserDialogContextProvider>
      <div className="flex-1 bg-background flex flex-cols text-primary text-xs">
        <div className="w-[200px] bg-white admin-sidebar-shadow flex flex-col">
          <div
            className={cn(
              "p-4 flex justify-center border-b border-seashell",
              ccls({
                [CommunitySlug.Boardroom]: "bg-background border-none",
                default: "",
              })
            )}
          >
            <Link to={feedPath.pattern}>
              <img
                alt={community.name}
                src={communityLogo[community.slug]}
                className="w-[160px]"
              />
            </Link>
          </div>

          <NavLink
            to={adminMembersPath({ "activeTab?": "applications" })}
            className={navLinkClassFn}
          >
            <People height={20} width={20} />
            Members
          </NavLink>
          <NavLink
            to={adminIntroductionsPath.pattern}
            className={navLinkClassFn}
          >
            <People height={20} width={20} />
            Introductions
          </NavLink>
          <NavLink to={adminChannelsPath.pattern} className={navLinkClassFn}>
            <Messages height={20} width={20} />
            Channels
          </NavLink>
          <NavLink to={adminProEventsPath.pattern} className={navLinkClassFn}>
            <CalendarGroup height={20} width={20} />
            Pro Events
          </NavLink>

          <div className="flex-1" />

          <NavLink to={feedPath.pattern} className={navLinkClassFn}>
            <div className="rotate-180">
              <Logout height={20} width={20} />
            </div>
            Back to community
          </NavLink>
        </div>
        <div className="flex-1 relative">
          <Outlet />
        </div>
      </div>
    </UserDialogContextProvider>
  )
}

const navLinkClassFn: NavLinkProps["className"] = ({ isActive }) =>
  cn("text-xs flex items-center gap-3 py-3 px-4 border-b border-seashell", {
    "bg-background text-foreground": isActive,
  })
