import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { experiencePath, onboardingInterestsPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { useForm, FormProvider, SubmitHandler } from "react-hook-form"
import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { OnboardingCard } from "~/components/onboarding/OnboardingCard"
import { OnboardingBioField } from "~/components/onboarding/OnboardingBioField"
import { OnboardingQuestionCount } from "~/ui/OnboardingQuestionCount"
import { OnboardingTitle } from "~/ui/Onboarding"
import { Trans } from "react-i18next"

interface IFormInput {
  bio: string | null
}

export const OnboardingBioScreen = () => {
  const [runUserUpdate, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)

  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  const form = useForm<IFormInput>()

  useEffect(() => form.reset({ bio: currentUser?.bio }), [currentUser, form])

  const updateUser = async (formData: IFormInput) => {
    return await runUserUpdate({
      variables: {
        input: formData,
      },
    })
  }

  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    const { errors } = await updateUser(formData)

    if (errors) {
      displayErrors(errors)
      return false
    } else {
      navigate(onboardingInterestsPath.pattern)
    }
  }

  const onBack = async () => {
    await form.handleSubmit(updateUser)()
    navigate(experiencePath.pattern)
  }

  return (
    <OnboardingLayout step={1}>
      <OnboardingTitle>
        <Trans i18nKey="bioScreenTitle" ns="onboarding" />
      </OnboardingTitle>
      <OnboardingCard
        user={currentUser}
        formData={{ id: currentUser.id, bio: form.watch("bio") }}
      />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="onboarding-question">
            <OnboardingQuestionCount position={1} />
          </div>
          <OnboardingBioField />
          <div className="onboarding-footer">
            <div
              className="hidden md:block onboarding-footer-link"
              onClick={onBack}
            >
              Back to Your Experience
            </div>
            <Button
              variant="onboarding"
              type="submit"
              disabled={loading || !form.watch("bio")?.length}
            >
              Continue
            </Button>
            <div className="md:hidden onboarding-footer-link" onClick={onBack}>
              Back to All About You
            </div>
          </div>
        </form>
      </FormProvider>
    </OnboardingLayout>
  )
}
