import { User_AvatarFragment } from "~/__generated__/graphql"
import { AvatarWithFallback } from "./AvatarWithFallback"
import { cn } from "~/common/shadcn-utils"

export const StackedUserAvatars = ({
  users,
  avatarSize = "default",
  totalCount: _totalCount = null,
  showCount = 3,
  onClick,
  opensUserDialog = false,
  className,
}: {
  users: User_AvatarFragment[]
  avatarSize?: "default" | "smaller" | "header" | null
  totalCount?: number | null
  showCount?: number
  onClick?: React.MouseEventHandler<HTMLDivElement>
  opensUserDialog?: boolean
  className?: string
}) => {
  const totalCount = _totalCount ? _totalCount : users.length
  const endCount = totalCount > showCount ? showCount - 1 : showCount

  return (
    <div className={cn("stackedImgContainer", className)} onClick={onClick}>
      {users.slice(0, endCount).map((author) => (
        <AvatarWithFallback
          user={author}
          size={avatarSize}
          key={author.id}
          opensUserDialog={opensUserDialog}
        />
      ))}
      {totalCount > showCount && (
        <AvatarWithFallback
          user={{ id: "placeholder" }}
          textOverride={`+${totalCount - showCount + 1}`}
          size={avatarSize}
        />
      )}
    </div>
  )
}
