import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useInterval } from "usehooks-ts"
import { activatePath } from "~/common/paths"
import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { ProgressBar } from "~/ui/ProgressBar"

import goodVibesOnly from "~/images/good-vibes-only.png"
import neonPulse from "~/images/neon-pulse.svg"

import MicrophoneStand from "~/images/icons/onboarding/microphone-stand.svg?react"
import Favourite from "~/images/icons/onboarding/favourite.svg?react"
import Backpack from "~/images/icons/onboarding/backpack.svg?react"
import People from "~/images/icons/onboarding/people.svg?react"
import Lightbulb from "~/images/icons/onboarding/lightbulb.svg?react"
import Lightbulb2 from "~/images/icons/onboarding/lightbulb2.svg?react"
import Growth from "~/images/icons/onboarding/growth.svg?react"
import Laugh from "~/images/icons/onboarding/laugh.svg?react"
import { OnboardingValue } from "~/ui/Onboarding"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunity } from "~/community/useCommunity"
import { castleCircle } from "~/images/__generated__/castle-circle"

const SafeSpaceValuesComponent = () => (
  <>
    <img
      src={goodVibesOnly}
      alt="Good Vibes Only"
      className="h-[162px] w-auto"
    />
    <div className="font-serif text-[46px] mt-2 font-medium">Our Values</div>
    <div className="grid md:grid-cols-3 gap-4 mt-4">
      <OnboardingValue>
        <MicrophoneStand />
        What's said here, stays here!
      </OnboardingValue>

      <OnboardingValue>
        <Favourite />
        Relationships, not networking
      </OnboardingValue>
      <OnboardingValue>
        <Backpack />
        Shared knowledge &gt;&nbsp;siloed research
      </OnboardingValue>
    </div>
  </>
)

const BoardRoomValuesComponent = () => (
  <>
    <div className="bg-highlight rounded-full h-[160px] w-[160px]">
      <img src={neonPulse} alt="" className="w-[160px] h-auto" />
    </div>
    <div className="font-serif text-[46px] mt-2 font-medium">Our Values</div>
    <div className="grid md:grid-cols-4 gap-4 mt-4">
      <OnboardingValue className="lg:px-4 text-xs">
        <MicrophoneStand width={30} className="flex-shrink-0" />
        What's said here, stays here!
      </OnboardingValue>
      <OnboardingValue className="lg:px-4 text-xs">
        <Favourite width={30} className="flex-shrink-0" />
        Relationships, not networking
      </OnboardingValue>
      <OnboardingValue className="lg:px-4 text-xs">
        <Backpack width={30} className="flex-shrink-0" />
        Shared knowledge &gt;&nbsp;siloed research
      </OnboardingValue>
      <OnboardingValue className="lg:px-4 text-xs">
        <Lightbulb width={30} className="flex-shrink-0" />
        Diversity of perspectives leads to unlocked insights
      </OnboardingValue>
    </div>
  </>
)

const MarketinglandValuesComponent = () => (
  <>
    <img
      src={castleCircle.src}
      width={castleCircle.width}
      height={castleCircle.height}
      srcSet={castleCircle.srcSet}
      alt=""
    />
    <div className="font-serif text-[46px] mt-2 font-medium">
      OUR CHARTER (so fancy)
    </div>
    <div className="grid md:grid-cols-4 gap-4 mt-4">
      <OnboardingValue className="lg:px-4 text-xs bg-marketingland-blue">
        <People width={30} className="flex-shrink-0" />
        Friendship is mandatory
      </OnboardingValue>
      <OnboardingValue className="lg:px-4 text-xs bg-marketingland-blue">
        <Lightbulb2 width={30} className="flex-shrink-0" />
        As is marketing excellence
      </OnboardingValue>
      <OnboardingValue className="lg:px-4 text-xs bg-marketingland-blue">
        <Growth width={30} className="flex-shrink-0" />
        Growth is required
      </OnboardingValue>
      <OnboardingValue className="lg:px-4 text-xs bg-marketingland-blue">
        <Laugh width={30} className="flex-shrink-0" />
        As are memes.
        <br />
        Sorry, we’re strict
      </OnboardingValue>
    </div>
  </>
)

const VALUES_COMPONENT: Record<CommunitySlug, React.FC> = {
  [CommunitySlug.Safespace]: SafeSpaceValuesComponent,
  [CommunitySlug.Boardroom]: BoardRoomValuesComponent,
  [CommunitySlug.Marketingland]: MarketinglandValuesComponent,
}

export const WelcomeScreen = () => {
  const navigate = useNavigate()
  const [progress, setProgress] = useState(0)
  const community = useCommunity()

  const ValuesComponent = VALUES_COMPONENT[community.slug]

  useInterval(() => {
    if (progress >= 100) {
      navigate(activatePath.pattern)
    } else {
      setProgress(progress + 0.5)
    }
  }, 25)

  return (
    <OnboardingLayout step={-2} fullWidth>
      <div className="flex flex-col items-center pb-4 md:w-[740px] lg:w-[1000px] mx-auto">
        <ValuesComponent />

        <div className="mt-10 w-[420px]">
          <ProgressBar progress={progress} />
        </div>
      </div>
    </OnboardingLayout>
  )
}
