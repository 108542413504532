import { displayErrors } from "~/common/validations"
import { useCallback, useRef, useState, useEffect } from "react"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useLazyQuery } from "@apollo/client"
import { useManualHrisDataContext } from "~/merge/manualData"
import { INDUSTRIES } from "~/merge/industries"
import explainerPlaceholder from "~/images/explainer-placeholder.png"
import { useMergeLink } from "@mergeapi/react-merge-link"
import { useSafeMutation } from "~/common/useSafeMutation"

import { Industry, AhoyEventTypeEnum } from "~/__generated__/graphql"
import { DialogClose } from "~/shadcn/ui/dialog"
import { useLogEvent } from "~/analytics/EventsContext"
import clsx from "clsx"

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/ui/select"

import { Button } from "~/shadcn/ui/button"

import { ChevronDown } from "lucide-react"
import { GENERATE_MERGE_LINK_TOKEN_QUERY_DOCUMENT } from "~/merge/generateMergeLinkTokenQuery"
import { CREATE_MERGE_LINK_MUTATION } from "~/merge/createMergeLinkMutation"
import { DialogVideoPlayer } from "~/components/DialogVideoPlayer"

const SelectIcon = () => {
  return <ChevronDown className="h-4 w-4" />
}

enum MergeCategories {
  Hris = "hris",
}

const category = MergeCategories.Hris

export const Connect = ({
  onExitCallback,
  showManualSetup,
}: {
  onExitCallback?: (success: boolean | undefined) => void
  showManualSetup: boolean
}) => {
  const [showExplainerVideo, setShowExplainerVideo] = useState(false)

  return (
    <>
      <div className={clsx({ hidden: !showExplainerVideo })}>
        <DialogVideoPlayer
          setShowVideo={setShowExplainerVideo}
          showVideo={showExplainerVideo}
          url="https://workweek-public.s3.us-east-2.amazonaws.com/connect_hris.mp4"
        />
      </div>
      <div className={clsx({ hidden: showExplainerVideo })}>
        <ConnectContent
          onExitCallback={onExitCallback}
          showManualSetup={showManualSetup}
          setShowExplainerVideo={setShowExplainerVideo}
        />
      </div>
    </>
  )
}

const ConnectContent = ({
  onExitCallback,
  showManualSetup,
  setShowExplainerVideo,
}: {
  onExitCallback?: (success: boolean | undefined) => void
  showManualSetup: boolean
  setShowExplainerVideo: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { next, industry, setIndustry, setDialogOpen } =
    useManualHrisDataContext()

  const { refetch } = useCurrentUser()
  const { logEvent } = useLogEvent()

  // Create a unique identifier for the merge integration. Update this to use
  // the value passed in if one already exists.
  const identifier = useRef(crypto.randomUUID())
  const [tempLinkToken, setTempLinkToken] = useState<string | undefined>()

  const [
    generateMergeLinkToken,
    {
      loading: generateMergeLinkTokenLoading,
      data: generateMergeLinkTokenData,
    },
  ] = useLazyQuery(GENERATE_MERGE_LINK_TOKEN_QUERY_DOCUMENT, {
    variables: {
      category,
      identifier: identifier.current,
      magicLink: false,
    },
    onCompleted: (data) => {
      logEvent(AhoyEventTypeEnum.HrisConnectionInitiated, {
        industry: industry,
      })
      setTempLinkToken(data.generateMergeLinkToken.tempToken)
    },
  })

  const [runCreateMergeLink] = useSafeMutation(CREATE_MERGE_LINK_MUTATION)

  const [mergeSuccessful, setMergeSuccessful] = useState<boolean | undefined>()

  const onSuccess = useCallback(
    (token: string) => {
      industry &&
        runCreateMergeLink({
          variables: {
            input: {
              token,
              identifier: identifier.current,
              category,
              industry,
            },
          },
        }).then(
          (data) => {
            if (data.errors) {
              displayErrors(data.errors)
            } else {
              setMergeSuccessful(true)

              // Setting a URL param here to indicate the connection was a
              // success. This is take advantage of state outside of React
              let currentUrl = new URL(window.location.href)
              currentUrl.searchParams.set("connect", "true")
              window.history.pushState(
                { path: currentUrl.href },
                "",
                currentUrl.href
              )

              refetch()
            }
          },
          (_) => {
            setMergeSuccessful(false)
            console.error("Failed to integrate with Merge")
          }
        )
    },
    [runCreateMergeLink, refetch, industry, setMergeSuccessful]
  )

  const onExit = useCallback(() => {
    onExitCallback && onExitCallback(mergeSuccessful)
  }, [onExitCallback, mergeSuccessful])

  const { open, isReady } = useMergeLink({
    linkToken: tempLinkToken,
    onSuccess,
    onExit,
  })

  // The open function is called in a use effect hook to avoid race conditions
  // with the isReady state of the useMergeLink hook.
  useEffect(() => {
    if (tempLinkToken && isReady) {
      open()
      setDialogOpen(false)
    }
  }, [isReady, tempLinkToken, open, setDialogOpen])

  return (
    <div className="flex flex-wrap justify-between items-end">
      <div className="w-full p-6 md:w-1/2">
        <div className="font-serif text-lg mb-4">
          Why you should connect your HRIS data explainer video.
        </div>
        <div className="text-textLight text-sm font-medium">
          Select Industry<span className="text-red-400">*</span>
        </div>
        <div className="mb-4">
          <Select
            onValueChange={(value: Industry) => setIndustry(value)}
            defaultValue={industry}
          >
            <SelectTrigger Icon={SelectIcon} className="focus:ring-offset-0">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {INDUSTRIES.map(({ value, label }) => {
                  return (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  )
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center space-x-2">
          <DialogClose asChild>
            <Button
              disabled={
                !!(
                  industry === undefined ||
                  generateMergeLinkTokenLoading ||
                  generateMergeLinkTokenData
                )
              }
              variant="onboarding"
              className="py-2 px-4 rounded-full"
              onClick={() => {
                generateMergeLinkToken()
              }}
            >
              {generateMergeLinkTokenLoading || generateMergeLinkTokenData
                ? "Loading"
                : "Connect HRIS"}
            </Button>
          </DialogClose>
        </div>
        {showManualSetup && (
          <button
            className="mt-8 text-xs text-textLight"
            disabled={industry === undefined}
            onClick={() => {
              logEvent(AhoyEventTypeEnum.ManualDataSubmissionInitiated, {
                industry: industry,
              })
              next()
            }}
          >
            Manually Add
          </button>
        )}
      </div>
      <div className="w-full md:w-1/2 flex justify-between items-end h-full">
        <div>
          <img
            height={"100%"}
            width={"100%"}
            className="w-full md:h-full h-1/2 mb-auto cursor-pointer"
            onClick={() => {
              setShowExplainerVideo(true)
            }}
            src={explainerPlaceholder}
            aria-label="Play Video"
            alt="HRIS data explainer video"
          />
        </div>
      </div>
    </div>
  )
}
