import copy from "copy-to-clipboard"
import { useState, useEffect } from "react"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useLogEvent } from "~/analytics/EventsContext"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import { useCommunity, useCommunityFeatures } from "~/community/useCommunity"

export const ReferralCode = () => {
  const community = useCommunity()
  const features = useCommunityFeatures()
  const { currentUser } = useCurrentUser()
  const [copied, setCopied] = useState(false)
  const { logEvent } = useLogEvent()

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false)
      }, 2000)
      return () => clearTimeout(timeout)
    }
  }, [copied])

  const onCopy = () => {
    copy(
      community.referralsClipboardBlurb
        .replaceAll("{{REFERRAL_CODE}}", currentUser.referralCode || "")
        .replaceAll("{{SIGN_UP_URL}}", community.signUpUrl)
        .replaceAll("{{REFERRAL_URL}}", community.referralUrl)
    )
    setCopied(true)
    logEvent(AhoyEventTypeEnum.ReferralCodeCopied)
  }

  if (!currentUser.referralCode || !features.referrals) {
    return null
  }

  return (
    <div className="max-w-sm mt-8">
      <Card>
        <CardContent className="pt-4 flex flex-col gap-4">
          <h2 className="font-semibold text-center text-xl">
            ✨ Refer a friend ✨
          </h2>
          <div>{community.referralsProfileBlurb}</div>

          <div className="flex gap-2 flex-grow-1">
            <span className="pt-1">🔗</span>
            <div className="">
              <a
                href={community.referralUrl}
                target="_blank"
                rel="noreferrer"
                className="tracking-wide underline"
              >
                {community.referralUrl}
              </a>
            </div>
          </div>

          <div className="flex gap-2 flex-grow-1">
            <span className="pt-1">🤑</span>
            <div className="flex flex-col flex-1 gap-4">
              <div className="border rounded-full px-4 py-1 text-highlight font-bold">
                {currentUser.referralCode}
              </div>
              <div className="flex gap-2">
                <Button className="flex-1" onClick={onCopy}>
                  Copy & Share
                </Button>
              </div>
              {copied && (
                <div className="font-semibold text-highlight">
                  Copied to clipboard!
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
