import { useQuery } from "@apollo/client"
import { Check, ChevronsUpDown, X } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import { useDebounce } from "use-debounce"
import { gql } from "~/__generated__"
import {
  Place_FilterFragment,
  StripeSubscriptionStatusEnum,
  Tag,
  Tier,
} from "~/__generated__/graphql"
import { TAGS_QUERY_DOCUMENT } from "~/common/queries"
import { cn } from "~/common/shadcn-utils"
import { Badge } from "~/shadcn/ui/badge"
import { Button, buttonVariants } from "~/shadcn/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/shadcn/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "~/shadcn/ui/popover"
import { UserTableFilter } from "./UsersTable"
import { COMPANY_SIZE_OPTIONS } from "~/onboarding/OnboardingExperienceQuestions"
import { useTiers } from "~/tiers/TiersProvider"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/ui/select"

type UsersFiltersProps = {
  enabledFilters: UserTableFilter[]
  placeFilter: Partial<Place_FilterFragment> | undefined
  setPlaceFilter: (
    placeFilter: Partial<Place_FilterFragment> | undefined
  ) => void
  subscriptionStatusFilter: StripeSubscriptionStatusEnum | undefined
  setSubscriptionStatusFilter: (
    subscriptionStatusFilter: StripeSubscriptionStatusEnum | undefined
  ) => void
  expertisesFilter: Partial<Tag>[]
  setExpertisesFilter: (expertiseFilter: Partial<Tag>[]) => void
  interestsFilter: Partial<Tag>[]
  setInterestsFilter: (interestsFilter: Partial<Tag>[]) => void
  companySizeFilter?: string
  setCompanySizeFilter: (companySizeFilter?: string) => void
  tierIdFilter?: Tier["id"]
  setTierIdFilter: (tierIdFilter?: Tier["id"]) => void
  applicationStateFilter?: "completed" | "not_completed" | "not_started"
  setApplicationStateFilter: (
    applicationStateFilter?: "completed" | "not_completed" | "not_started"
  ) => void
}

const PlaceFilter = ({
  placeFilter,
  setPlaceFilter,
}: Pick<UsersFiltersProps, "placeFilter" | "setPlaceFilter">) => {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState("")

  const [debouncedQuery] = useDebounce(query, 300)

  const { data, loading } = useQuery(PLACES_QUERY_DOCUMENT, {
    variables: {
      query: debouncedQuery,
      first: 5,
    },
    fetchPolicy: "no-cache",
  })
  const places = useMemo(() => data?.places.nodes ?? [], [data])

  useEffect(() => {
    if (!data) return

    if (placeFilter?.id && !placeFilter.full) {
      const hydratedPlace = places.find((place) => place.id === placeFilter.id)
      if (hydratedPlace) {
        setPlaceFilter(hydratedPlace)
      }
    }
  }, [data, placeFilter, places, setPlaceFilter])

  const onSelect = (place: Place_FilterFragment) => {
    setPlaceFilter(place)
    setOpen(false)
  }

  const clear = (e: Pick<Event, "preventDefault" | "stopPropagation">) => {
    e.preventDefault()
    e.stopPropagation()
    setPlaceFilter(undefined)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="input"
          role="combobox"
          aria-expanded={open}
          className=""
        >
          <span className="overflow-hidden text-ellipsis whitespace-nowrap !inline-block">
            {placeFilter ? placeFilter.full : "Location"}
          </span>

          <div className="flex items-right items-center justify-center">
            <div
              className={cn(
                buttonVariants({ variant: "subtle", size: "icon" }),
                "ml-2 shrink-0",
                placeFilter ? "opacity-100" : "opacity-0"
              )}
              onClick={clear}
            >
              <X className="w-5 h-5" />
            </div>

            <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search location"
            value={query}
            onValueChange={setQuery}
          />
          <CommandEmpty>
            {loading ? "Loading..." : "No locations found"}
          </CommandEmpty>
          <CommandGroup>
            {places.map((place) => (
              <CommandItem key={place.id} onSelect={() => onSelect(place)}>
                <Check
                  className={cn(
                    "w-5 h-5 mr-2",
                    placeFilter?.id === place.id ? "opacity-100" : "opacity-0"
                  )}
                />
                {place.full}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

const SUBSCRIPTION_STATUSES: StripeSubscriptionStatusEnum[] = [
  StripeSubscriptionStatusEnum.Active,
  StripeSubscriptionStatusEnum.Canceled,
  StripeSubscriptionStatusEnum.Incomplete,
  StripeSubscriptionStatusEnum.IncompleteExpired,
  StripeSubscriptionStatusEnum.PastDue,
  StripeSubscriptionStatusEnum.Trialing,
  StripeSubscriptionStatusEnum.Unpaid,
]

const SubscriptionStatusFilter = ({
  subscriptionStatusFilter,
  setSubscriptionStatusFilter,
}: Pick<
  UsersFiltersProps,
  "subscriptionStatusFilter" | "setSubscriptionStatusFilter"
>) => {
  const [open, setOpen] = useState(false)

  const onSelect = (status: StripeSubscriptionStatusEnum) => {
    setSubscriptionStatusFilter(status)
    setOpen(false)
  }

  const clear = (e: Pick<Event, "preventDefault" | "stopPropagation">) => {
    e.preventDefault()
    e.stopPropagation()
    setSubscriptionStatusFilter(undefined)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="input" role="combobox" aria-expanded={open}>
          <span className="overflow-hidden text-ellipsis whitespace-nowrap !inline-block">
            {subscriptionStatusFilter || "Subscription Status"}
          </span>
          <div className="flex items-right items-center justify-center">
            <div
              className={cn(
                buttonVariants({ variant: "subtle", size: "icon" }),
                "ml-2 shrink-0",
                subscriptionStatusFilter ? "opacity-100" : "opacity-0"
              )}
              onClick={clear}
            >
              <X className="w-5 h-5" />
            </div>

            <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Command>
          <CommandInput />
          <CommandEmpty>{"No statuses found"}</CommandEmpty>
          <CommandGroup>
            {SUBSCRIPTION_STATUSES.map((status) => (
              <CommandItem key={status} onSelect={() => onSelect(status)}>
                <Check
                  className={cn(
                    "w-5 h-5 mr-2",
                    subscriptionStatusFilter === status
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
                {status}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

const CompanySizeFilter = ({
  companySizeFilter,
  setCompanySizeFilter,
}: Pick<UsersFiltersProps, "companySizeFilter" | "setCompanySizeFilter">) => {
  const Icon = () => (
    <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
  )

  return (
    <Select
      onValueChange={setCompanySizeFilter}
      value={companySizeFilter}
      clearable
    >
      <SelectTrigger Icon={Icon}>
        <SelectValue placeholder="Company Size" />
      </SelectTrigger>
      <SelectContent>
        {COMPANY_SIZE_OPTIONS.map((companySize) => (
          <SelectItem key={companySize} value={companySize}>
            {companySize}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

const TierIdFilter = ({
  tierIdFilter,
  setTierIdFilter,
}: Pick<UsersFiltersProps, "tierIdFilter" | "setTierIdFilter">) => {
  const { tiers } = useTiers()

  const Icon = () => (
    <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
  )

  return (
    <Select onValueChange={setTierIdFilter} value={tierIdFilter} clearable>
      <SelectTrigger Icon={Icon}>
        <SelectValue placeholder="Membership Tier" />
      </SelectTrigger>
      <SelectContent>
        {tiers.map((tier) => (
          <SelectItem key={tier.id} value={tier.id}>
            {tier.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

const ApplicationStateFilter = ({
  applicationStateFilter,
  setApplicationStateFilter,
}: Pick<
  UsersFiltersProps,
  "applicationStateFilter" | "setApplicationStateFilter"
>) => {
  const Icon = () => (
    <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
  )

  return (
    <Select
      onValueChange={(value) => setApplicationStateFilter(value as any)}
      value={applicationStateFilter}
      clearable
    >
      <SelectTrigger Icon={Icon}>
        <SelectValue placeholder="Application State" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="completed">Completed</SelectItem>
        <SelectItem value="not_completed">Not Completed</SelectItem>
        <SelectItem value="not_started">Not Started</SelectItem>
      </SelectContent>
    </Select>
  )
}

type TagFilterProps = {
  tagsFilter: Partial<Tag>[]
  setTagsFilter: (tagsFilter: Partial<Tag>[]) => void
  tagType: "expertise" | "interest"
}

const TagFilter = ({ tagsFilter, setTagsFilter, tagType }: TagFilterProps) => {
  const [open, setOpen] = useState(false)

  const clear = (e: Pick<Event, "preventDefault" | "stopPropagation">) => {
    e.preventDefault()
    e.stopPropagation()
    setTagsFilter([])
    setOpen(false)
  }

  const onSelect = (tag: Tag) => {
    setTagsFilter(
      tagsFilter.find((t) => t.id === tag.id)
        ? tagsFilter.filter((t) => t.id !== tag.id)
        : [...tagsFilter, tag]
    )
  }

  const hasTags = tagsFilter.length > 0

  const { data, loading } = useQuery(TAGS_QUERY_DOCUMENT, {
    fetchPolicy: "cache-first",
  })

  const tags = useMemo(() => data?.tags.nodes ?? [], [data])

  useEffect(() => {
    if (!data) return

    let hasHydratedTags = false
    const hydratedTags: Partial<Tag>[] = []
    tagsFilter.forEach((tag) => {
      if (tag.id && !tag.name) {
        const hydratedTag = tags.find((t) => t.id === tag.id)
        if (hydratedTag) hydratedTags.push(hydratedTag)
        hasHydratedTags = true
      } else if (tag.id && tag.name) {
        hydratedTags.push(tag)
      }
    })

    if (hasHydratedTags) {
      setTagsFilter(hydratedTags)
    }
  }, [tagsFilter, data, tags, setTagsFilter])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="input"
          role="combobox"
          aria-expanded={open}
          className="whitespace-nowrap text-ellipsis overflow-hidden"
        >
          <span className="overflow-hidden text-ellipsis whitespace-nowrap !inline-block">
            {hasTags ? (
              <div className="flex gap-2">
                <Badge
                  key={tagsFilter[0].id}
                  className="mr-1 text-3xs"
                  variant={tagType === "expertise" ? "secondary" : "highlight"}
                >
                  {tagsFilter[0].name}
                </Badge>
                {tagsFilter.length > 1 && (
                  <span>& {tagsFilter.length - 1} more</span>
                )}
              </div>
            ) : tagType === "expertise" ? (
              "Expertise"
            ) : (
              "Topics of Interest"
            )}
          </span>
          <div className="flex items-right items-center justify-center">
            <div
              className={cn(
                buttonVariants({ variant: "subtle", size: "icon" }),
                "ml-2 shrink-0",
                hasTags ? "opacity-100" : "opacity-0"
              )}
              onClick={clear}
            >
              <X className="w-5 h-5" />
            </div>

            <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Command>
          <CommandInput />
          <CommandEmpty>
            {loading ? "Loading..." : "No tags found"}
          </CommandEmpty>
          <CommandGroup>
            {tags.map((tag) => (
              <CommandItem key={tag.id} onSelect={() => onSelect(tag)}>
                <Check
                  className={cn(
                    "w-5 h-5 mr-2",
                    tagsFilter.some((t) => t.id === tag.id)
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
                {tag.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export const UsersFilters = ({
  enabledFilters,
  placeFilter,
  setPlaceFilter,
  subscriptionStatusFilter,
  setSubscriptionStatusFilter,
  expertisesFilter,
  setExpertisesFilter,
  interestsFilter,
  setInterestsFilter,
  companySizeFilter,
  setCompanySizeFilter,
  tierIdFilter,
  setTierIdFilter,
  applicationStateFilter,
  setApplicationStateFilter,
}: UsersFiltersProps) => {
  return (
    <div
      className={cn(
        "grid grid-cols-1 gap-2 mb-4",
        `md:grid-cols-${enabledFilters.length}`
      )}
    >
      {enabledFilters.includes("place") && (
        <PlaceFilter
          placeFilter={placeFilter}
          setPlaceFilter={setPlaceFilter}
        />
      )}
      {enabledFilters.includes("subscriptionStatus") && (
        <SubscriptionStatusFilter
          subscriptionStatusFilter={subscriptionStatusFilter}
          setSubscriptionStatusFilter={setSubscriptionStatusFilter}
        />
      )}
      {enabledFilters.includes("expertises") && (
        <TagFilter
          tagsFilter={expertisesFilter}
          setTagsFilter={setExpertisesFilter}
          tagType="expertise"
        />
      )}
      {enabledFilters.includes("interests") && (
        <TagFilter
          tagsFilter={interestsFilter}
          setTagsFilter={setInterestsFilter}
          tagType="interest"
        />
      )}
      {enabledFilters.includes("applicationState") && (
        <ApplicationStateFilter
          applicationStateFilter={applicationStateFilter}
          setApplicationStateFilter={setApplicationStateFilter}
        />
      )}
      {enabledFilters.includes("tierId") && (
        <TierIdFilter
          tierIdFilter={tierIdFilter}
          setTierIdFilter={setTierIdFilter}
        />
      )}
      {enabledFilters.includes("companySize") && (
        <CompanySizeFilter
          companySizeFilter={companySizeFilter}
          setCompanySizeFilter={setCompanySizeFilter}
        />
      )}
    </div>
  )
}

gql(`
  fragment Place_Filter on Place {
    id
    full
  }
`)

export const PLACES_QUERY_DOCUMENT = gql(`
  query Places($first: Int, $last: Int, $beforeCursor: String, $afterCursor: String, $query: String) {
    places(first: $first, last: $last, before: $beforeCursor, after: $afterCursor, query: $query) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...Place_Filter
      }
    }
  }
`)
