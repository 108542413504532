import { useNavigate } from "react-router-dom"
import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { Button } from "~/shadcn/ui/button"
import { aboutYouPath } from "~/common/paths"
import { AgreementsSentence } from "~/auth/AgreementsSentence"
import { MembershipCard } from "~/users/MembershipCard"
import { Trans } from "react-i18next"

export const ActivateScreen = () => {
  const navigate = useNavigate()

  return (
    <OnboardingLayout step={-1} fullWidth>
      <div className="flex flex-col items-center pb-4 md:w-[740px] lg:w-[1000px] mx-auto">
        <div className="font-serif text-center text-5xl mb-10">
          <Trans i18nKey="activateScreenTitle" ns="onboarding" />
        </div>

        <div className="flex gap-8 items-center mb-20 flex-wrap">
          <div className="sm:w-full md:flex-1">
            <div className="text-2xl font-serif mb-4">Activate Membership</div>

            <div className="mb-5">
              To ensure a high quality experience for you,{" "}
              <strong>we vet all members via Membership Activation.</strong>
            </div>

            <ul className="mb-5 list-disc ml-7">
              <li className="mb-2">You tell us a little bit about yourself</li>
              <li className="mb-2">We review your responses</li>
              <li className="mb-2">Once you're all set, we'll notify you</li>
            </ul>

            <div>
              If for any reason, we are unable to activate your membership,
              we'll inform and refund you immediately.
            </div>
          </div>
          <MembershipCard />
        </div>
        <div>
          <Button
            variant="onboarding"
            className="py-4 px-8"
            onClick={() => navigate(aboutYouPath.pattern)}
          >
            Activate Membership
          </Button>
        </div>
        <AgreementsSentence preface="By creating an account you agree with our" />
      </div>
    </OnboardingLayout>
  )
}
