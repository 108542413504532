import { ArrowLeft, Check, Eye, EyeOff } from "lucide-react"
import { Link } from "react-router-dom"
import { myContentPath } from "~/common/paths"
import { Button } from "~/shadcn/ui/button"
import {
  Article,
  ArticleRevision,
  ArticleStateEnum,
  ArticleVisibilityEnum,
  Lesson,
} from "~/__generated__/graphql"
import { cn } from "~/common/shadcn-utils"
import { ARTICLE_VISIBILITY_LABELS } from "~/common/enumTranslations"
import {
  ApproveContentConfirmProps,
  useApproveContentConfirm,
} from "~/content/useApproveContentConfirm"
import {
  SubmitForReviewConfirmProps,
  useSubmitForReviewConfirm,
} from "~/content/useSubmitForReviewConfirm"
import {
  RequestEditsConfirmProps,
  useRequestEditsConfirm,
} from "~/content/useRequestEditsConfirm"
import {
  ChangeVisibilityConfirmProps,
  useChangeVisibilityConfirm,
} from "~/content/useChangeVisibilityConfirm"
import {
  ToggleFeaturedConfirmProps,
  useToggleFeaturedConfirm,
} from "~/content/useToggleFeaturedConfirm"
import {
  ToggleHiddenConfirmProps,
  useToggleHiddenConfirm,
} from "~/content/useToggleHiddenConfirm"
import { SimpleTooltip } from "~/ui/SimpleTooltip"
import { compareAsc, parseISO } from "date-fns"
import {
  TogglePremiumConfirmProps,
  useTogglePremiumConfirm,
} from "~/content/useTogglePremiumConfirm"

type RequiredArticleProps =
  | "id"
  | "visibility"
  | "state"
  | "featuredUntil"
  | "premium"
  | "currentUserCanChangeVisibility"
  | "currentUserCanSubmitForApproval"
  | "currentUserCanRequestEdits"
  | "currentUserCanApprove"
  | "currentUserCanMarkAsFeatured"
  | "currentUserCanToggleHidden"
  | "currentUserCanMarkAsPremium"

type ToolbarArticleBase = Pick<Article, RequiredArticleProps> & {
  approvedRevision?: Partial<ArticleRevision> | null
}

type LessonForToggleHidden = Pick<Lesson, "id" | "hidden"> & {
  section: Pick<Lesson["section"], "hidden">
}
type ToolbarArticleForToggleHidden = ToolbarArticleBase & {
  draftLesson: LessonForToggleHidden
}

const isArticleWithLesson = (
  article: ToolbarArticleBase | ToolbarArticleForToggleHidden
): article is ToolbarArticleForToggleHidden => {
  return "draftLesson" in article && article.draftLesson !== null
}

type ToolbarOptions =
  | "approve"
  | "submitForReview"
  | "requestEdits"
  | "changeVisibility"
  | "edit"
  | "preview"
  | "toggleFeatured"
  | "toggleHidden"
  | "togglePremium"

type BackLink = {
  label: string
  path: string
}

interface ArticleActionToolbarPropsBase {
  preview: () => void
  edit: () => void
  save: () => void
  isPreviewing: boolean
  article: ToolbarArticleBase
  isSaving: boolean
  isDirty: boolean
  isLocked: boolean
  options?: Record<ToolbarOptions, boolean>
  approveContentConfirmOptions?: Partial<ApproveContentConfirmProps>
  submitForReviewConfirmOptions?: Partial<SubmitForReviewConfirmProps>
  requestEditsConfirmOptions?: Partial<RequestEditsConfirmProps>
  changeVisibilityConfirmOptions?: Partial<ChangeVisibilityConfirmProps>
  toggleFeaturedConfirmOptions?: Partial<ToggleFeaturedConfirmProps>
  toggleHiddenConfirmProps?: Partial<ToggleHiddenConfirmProps>
  togglePremiumConfirmProps?: Partial<TogglePremiumConfirmProps>
  backLink?: BackLink
}

type ArticleActionToolbarPropsToggleHidden = Omit<
  ArticleActionToolbarPropsBase,
  "article"
> & { article: ToolbarArticleForToggleHidden }
export type ArticleActionToolbarProps =
  | ArticleActionToolbarPropsBase
  | ArticleActionToolbarPropsToggleHidden

const defaultOptions: Record<ToolbarOptions, boolean> = {
  approve: true,
  submitForReview: true,
  requestEdits: true,
  changeVisibility: true,
  edit: true,
  preview: true,
  toggleFeatured: true,
  toggleHidden: false,
  togglePremium: true,
}

const defaultBackLink: BackLink = {
  label: "Back to library",
  path: myContentPath.pattern,
}

export const ArticleActionToolbar = ({
  preview,
  edit,
  isPreviewing,
  save,
  article,
  isSaving,
  isDirty,
  isLocked,
  options: optionProps,
  approveContentConfirmOptions = {},
  submitForReviewConfirmOptions = {},
  requestEditsConfirmOptions = {},
  changeVisibilityConfirmOptions = {},
  toggleFeaturedConfirmOptions = {},
  toggleHiddenConfirmProps = {},
  togglePremiumConfirmProps = {},
  backLink = defaultBackLink,
}: ArticleActionToolbarProps) => {
  const options = {
    ...defaultOptions,
    ...optionProps,
  }

  const approveArticle = useApproveContentConfirm({
    article,
    ...approveContentConfirmOptions,
  })
  const submitForReview = useSubmitForReviewConfirm({
    article,
    ...submitForReviewConfirmOptions,
  })
  const requestEdits = useRequestEditsConfirm({
    article,
    ...requestEditsConfirmOptions,
  })
  const changeVisibility = useChangeVisibilityConfirm({
    article,
    ...changeVisibilityConfirmOptions,
  })
  const toggleFeatured = useToggleFeaturedConfirm({
    article,
    ...toggleFeaturedConfirmOptions,
  })
  const toggleHidden = useToggleHiddenConfirm({
    article,
    ...toggleHiddenConfirmProps,
  })
  const togglePremium = useTogglePremiumConfirm({
    article,
    ...togglePremiumConfirmProps,
  })

  const showEdit = options.edit
  const showPreview = options.preview
  const showChangeVisibility =
    options.changeVisibility &&
    article.currentUserCanChangeVisibility.value &&
    !!article.approvedRevision
  const showSubmitForReview =
    options.submitForReview &&
    article.currentUserCanSubmitForApproval.value &&
    article.state !== ArticleStateEnum.Approved &&
    article.state !== ArticleStateEnum.Submitted
  const showRequestEdits =
    options.requestEdits &&
    article.currentUserCanRequestEdits.value &&
    article.state !== ArticleStateEnum.Approved &&
    article.state !== ArticleStateEnum.EditsRequested
  const showApprove =
    options.approve &&
    article.currentUserCanApprove.value &&
    article.state !== ArticleStateEnum.Approved
  const showFeatured =
    options.toggleFeatured &&
    article.currentUserCanMarkAsFeatured.value &&
    article.visibility === ArticleVisibilityEnum.Live
  const showToggleHidden =
    options.toggleHidden &&
    article.currentUserCanToggleHidden.value &&
    isArticleWithLesson(article)
  const showTogglePremium =
    options.togglePremium && article.currentUserCanMarkAsPremium.value

  return (
    <>
      <div className="flex py-8 items-center sticky top-0 left-0 w-100 z-[30] bg-background -ml-1 -mr-1 pl-1 pr-1">
        <Link
          to={backLink.path}
          className="mr-auto flex items-center font-bold tracking-wide text-foreground"
        >
          <ArrowLeft className="mr-4" />
          {backLink.label}
        </Link>

        {!isLocked && (
          <div className="flex gap-2 items-center">
            {showToggleHidden && (
              <>
                {article.draftLesson.section.hidden ? (
                  <Button
                    variant="backgroundOutline"
                    pseudo="disabled"
                    className="text-xs cursor-not-allowed"
                  >
                    <SimpleTooltip content="Lesson cannot be unhidden because the section is hidden">
                      Un-hide
                    </SimpleTooltip>
                  </Button>
                ) : (
                  <Button
                    onClick={toggleHidden}
                    variant="backgroundOutline"
                    className="text-xs"
                  >
                    {article.draftLesson.hidden ? "Un-hide" : "Hide"}
                  </Button>
                )}
              </>
            )}

            {showFeatured && (
              <Button
                variant="backgroundOutline"
                className="text-xs"
                onClick={toggleFeatured}
              >
                {article.featuredUntil &&
                compareAsc(parseISO(article.featuredUntil), new Date()) === 1
                  ? "Unfeature"
                  : "Feature"}
              </Button>
            )}

            {showTogglePremium && (
              <Button
                variant="backgroundOutline"
                className="text-xs"
                onClick={togglePremium}
              >
                {article.premium ? "Unmark as Premium" : "Mark as Premium"}
              </Button>
            )}

            {showChangeVisibility && (
              <Button
                onClick={changeVisibility}
                variant="backgroundOutline"
                className={cn("mr-6 flex items-center gap-2")}
              >
                {article.visibility === ArticleVisibilityEnum.Hidden ? (
                  <EyeOff size={20} />
                ) : (
                  <Eye size={20} />
                )}
                {ARTICLE_VISIBILITY_LABELS[article.visibility]}
              </Button>
            )}

            {showSubmitForReview && (
              <Button
                variant="backgroundOutline"
                className="text-xs"
                onClick={submitForReview}
              >
                Submit for Review
              </Button>
            )}

            {showRequestEdits && (
              <Button
                variant="backgroundOutline"
                className="text-xs"
                onClick={requestEdits}
              >
                Request Edits
              </Button>
            )}

            {showApprove && (
              <Button
                variant="backgroundOutline"
                className="text-xs"
                onClick={approveArticle}
              >
                Approve
              </Button>
            )}

            {showEdit && (
              <Button
                disabled={!isPreviewing}
                onClick={edit}
                variant="backgroundOutline"
                className="text-xs"
              >
                Edit
              </Button>
            )}

            {showPreview && (
              <Button
                disabled={isPreviewing}
                onClick={preview}
                variant="backgroundOutline"
                className="text-xs"
              >
                Preview
              </Button>
            )}

            <Button
              onClick={save}
              className="text-xs"
              isLoading={isSaving}
              disabled={isSaving || !isDirty}
            >
              {isDirty ? (
                "Save"
              ) : (
                <>
                  Saved
                  <Check size={18} className="ml-2" />
                </>
              )}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}
