import { formatDate } from "date-fns"
import { useRef, useState } from "react"
import {
  User_AdminFragment,
  TierLevelEnum,
  UserSortEnum,
} from "~/__generated__/graphql"
import { UserDialog, userLocation } from "~/directory/UserDialog"
import { Badge } from "~/shadcn/ui/badge"
import { Button } from "~/shadcn/ui/button"
import { TableCell, TableRow } from "~/shadcn/ui/table"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { formatCalendarDate } from "~/common/formatDate"
import { celebrationTypesByValue } from "~/celebrations"
import { UserTableHeader, UsersTable } from "./UsersTable"
import { UserName } from "~/directory/UserName"
import { CopyToClipboard } from "~/ui/CopyToClipboard"
import copy from "copy-to-clipboard"
import toast from "react-hot-toast"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/shadcn/ui/context-menu"

const HEADERS: UserTableHeader[] = [
  { label: "Name", isPinned: true },
  { label: "Date Joined" },
  { label: "Homebase" },
  { label: "Title" },
  { label: "Company" },
  { label: "Tier" },
  { label: "Expertise" },
  { label: "Interests" },
  { label: "Celebrations" },
  { label: "Type" },
  { label: "Onboarded" },
]

const pinnedColumnStyles =
  "sticky left-0 bg-gradient-to-r from-white from-80% to-transparent group-hover:from-muted group-hover:from-80% group-hover:to-transparent"

const UserRow = ({ user }: { user: User_AdminFragment }) => {
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)

  return (
    <>
      {userDialogOpen && (
        <UserDialog
          isOpen={userDialogOpen}
          onClose={() => setUserDialogOpen(false)}
          user={user}
        />
      )}
      <ContextMenu>
        <ContextMenuContent>
          <ContextMenuItem
            onClick={() =>
              user.email && copy(user.email) && toast.success("Email copied")
            }
            className="cursor-pointer"
            disabled={!user.email}
          >
            Copy Email Address
          </ContextMenuItem>
        </ContextMenuContent>
        <ContextMenuTrigger asChild>
          <TableRow ref={contextMenuTriggerRef} className="group">
            <TableCell className={pinnedColumnStyles}>
              <div className="flex gap-2">
                <div className="w-[30px] h-[30px]">
                  <AvatarWithFallback user={user} size="post-autocomplete" />
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-2 items-center">
                    <Button
                      variant="link"
                      size="inline"
                      className="text-primary"
                      onClick={() => setUserDialogOpen(true)}
                    >
                      <UserName user={user} />
                    </Button>
                  </div>
                  {user.email && (
                    <CopyToClipboard
                      className="text-2xs text-gray-500"
                      text={user.email}
                    >
                      <div className="truncate max-w-[140px]">{user.email}</div>
                    </CopyToClipboard>
                  )}
                </div>
              </div>
            </TableCell>
            <TableCell>
              <span className="text-xs">
                {formatDate(user.createdAt, "MMM d, yyyy")}
              </span>
            </TableCell>
            <TableCell>
              <div className="whitespace-nowrap overflow-hidden text-ellipsis text-xs">
                {userLocation(user.place) || "New York, NY"}
              </div>
            </TableCell>
            <TableCell>
              <div className="whitespace-nowrap overflow-hidden text-ellipsis text-xs">
                {user.jobTitle || "N/A"}
              </div>
            </TableCell>
            <TableCell>
              <div className="whitespace-nowrap overflow-hidden text-ellipsis text-xs">
                {user.companyName || "N/A"}
              </div>
            </TableCell>
            <TableCell>
              <span className="text-xs">
                {user.tier ? (
                  <>
                    {user.tier.name}
                    {user.tier.level !== TierLevelEnum.Free &&
                      user.activeStripeSubscription?.plan &&
                      `-${
                        user.activeStripeSubscription.plan.interval === "year"
                          ? "Yearly"
                          : "Quarterly"
                      }`}
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap gap-1">
                {user.expertise.map((tag) => (
                  <Badge key={tag.id} variant="secondary" className="text-3xs">
                    {tag.name}
                  </Badge>
                ))}
              </div>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap gap-1">
                {user.interests.map((tag) => (
                  <Badge key={tag.id} variant="highlight" className="text-3xs">
                    {tag.name}
                  </Badge>
                ))}
              </div>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap gap-1">
                {user.celebrations.map(
                  ({ id, celebrationType, customCelebrationType, date }) => (
                    <Badge
                      key={id}
                      variant="outline"
                      className="text-3xs flex gap-2"
                    >
                      <span>
                        {celebrationTypesByValue[celebrationType].emoji}
                      </span>
                      {celebrationType === "other" && (
                        <span>{customCelebrationType}</span>
                      )}
                      {date && (
                        <span>{formatCalendarDate(date, "MMM d, yyyy")}</span>
                      )}
                    </Badge>
                  )
                )}
              </div>
            </TableCell>
            <TableCell>
              {user.admin ? (
                <Badge variant="primary">Admin</Badge>
              ) : user.coach ? (
                <Badge variant="success">Coach</Badge>
              ) : (
                <Badge variant="default">User</Badge>
              )}
            </TableCell>
            <TableCell>
              <Badge variant={user.hidden ? "warning" : "success"}>
                {user.hidden ? "Hidden" : "Visible"}
              </Badge>
            </TableCell>
            <TableCell>
              <Badge variant={user.onboarded ? "success" : "warning"}>
                {user.onboarded ? "Onboarded" : "Not Onboarded"}
              </Badge>
            </TableCell>
          </TableRow>
        </ContextMenuTrigger>
      </ContextMenu>
    </>
  )
}

export const PastMembersTable = () => {
  return (
    <UsersTable
      withFilters={["place", "expertises", "interests"]}
      withSearch
      defaultSort={UserSortEnum.Recent}
      headers={HEADERS}
      queryOptions={{ inactiveOnly: true }}
    >
      {(user) => <UserRow key={user.id} user={user} />}
    </UsersTable>
  )
}
