import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, SubmitHandler } from "react-hook-form"
import { z } from "zod"
import { Button } from "~/shadcn/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "~/shadcn/ui/form"
import { IntroductionMatch } from "./types"
import { INTRODUCTION_CYCLES_ADMIN_POSSIBLE_MATCHES_QUERY_DOCUMENT } from "./api"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/ui/select"
import { Error } from "~/ui/Error"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { UserName } from "~/directory/UserName"

const formSchema = z.object({
  matchedWithUserId: z.string().min(1, { message: "Required" }),
})

export type IntroductionMatchOverrideFormValues = z.infer<typeof formSchema>

export const IntroductionMatchOverrideForm = ({
  match,
  onOverrideMatch,
}: {
  match: IntroductionMatch
  onOverrideMatch: (userId: string) => void
}) => {
  const { data, loading, error } = useQuery(
    INTRODUCTION_CYCLES_ADMIN_POSSIBLE_MATCHES_QUERY_DOCUMENT,
    {
      variables: {
        introductionMatchId: match.id,
      },
    }
  )
  const possibleMatches = data?.introductionMatchPossibleMatches || []

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      matchedWithUserId: match.matchedWithUser?.id,
    },
  })

  const onSubmit: SubmitHandler<IntroductionMatchOverrideFormValues> = async (
    values
  ) => {
    onOverrideMatch(values.matchedWithUserId)
  }

  if (loading) {
    return <LoadingIndicator />
  } else if (error) {
    return <Error message="Error loading matches." />
  } else if (possibleMatches.length === 0) {
    return (
      <Error message="Unfortunately, there are no available matches for this user" />
    )
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex-1">
        <FormField
          control={form.control}
          name="matchedWithUserId"
          render={({ field }) => (
            <FormItem className="mb-4">
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a match user..." />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {possibleMatches.map((user) => (
                    <SelectItem key={user.id} value={user.id}>
                      <b>
                        <UserName user={user} />
                      </b>{" "}
                      ({user.email})
                    </SelectItem>
                  ))}
                </SelectContent>
                <FormMessage />
              </Select>
            </FormItem>
          )}
        />
        <div className="flex items-center space-x-2">
          <Button size="sm" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Form>
  )
}
