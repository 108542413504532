import {
  forwardRef,
  createRef,
  useEffect,
  useMemo,
  useState,
  useRef,
  useImperativeHandle,
  useCallback,
  MutableRefObject,
  RefObject,
} from "react"
import ReactDOMServer from "react-dom/server"
import { cn } from "~/common/shadcn-utils"
import {
  Map as GoogleMap,
  Marker,
  useMarkerRef,
  InfoWindow,
} from "@vis.gl/react-google-maps"
import MapMarkerIcon from "~/images/icons/map-marker.svg?react"
import { LatLng } from "~/common/useDeviceLocation"
import { useUserDialogContext } from "~/directory/UserDialogContext"
import { CurrentLocation } from "~/__generated__/graphql"

const svgUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
  ReactDOMServer.renderToString(<MapMarkerIcon />)
)}`

interface StaggeredPlace {
  idNames: { id: string; name: string }[]
  lat: number
  lng: number
}

interface MarkerRef {
  closeInfoWindow: () => void
}

type MarkerRefs = MutableRefObject<RefObject<MarkerRef>[]>

interface GroupedMarkerProps {
  position: LatLng
  idNames: { id: string; name: string }[]
  expanded?: boolean
}

const staggerPlaces = (
  places: CurrentLocation[],
  zoom: number
): StaggeredPlace[] => {
  const minJiltBase = 0.001
  const maxJiltBase = 0.01
  const minZoom = 10
  const maxZoom = 20

  const fittedJiltBase = parseFloat(
    (
      maxJiltBase -
      ((zoom - minZoom) / (maxZoom - minZoom)) * (maxJiltBase - minJiltBase)
    ).toFixed(5)
  )

  const pointMap = new Map<string, number[]>()

  // Calculate a map of locations that have conflicts for a given precision
  // Similiar to jilt above, these numbers are largely experimental based on
  // google map's "zoom" levels.
  let precision: number

  if (zoom < 6) {
    precision = 2
  } else if (zoom < 11) {
    precision = 3
  } else if (zoom < 16) {
    precision = 5
  } else {
    precision = 7
  }

  places.forEach(({ lat, lng }, index) => {
    const key = `${lat.toFixed(precision)},${lng.toFixed(precision)}`

    if (!pointMap.has(key)) {
      pointMap.set(key, [])
    }

    pointMap.get(key)!.push(index)
  })

  // Apply jilt to the original mapping for the found conflicts. Conflicts are
  // defined as all the entries in the pointMap that have more than one found
  // index in the original mapping.
  //
  // When more than 5 conflicts exist at given point, the points are aggregated
  // into groupings.
  //
  // To apply the jilt, the points are distributed around a circle of the given
  // conflict.
  const modifiedPlaces: StaggeredPlace[] = []

  const randomOffset = () => {
    const angle = Math.random() * 2 * Math.PI
    const radius = fittedJiltBase * Math.random()

    return {
      latOffset: Math.sin(angle) * radius,
      lngOffset: Math.cos(angle) * radius,
    }
  }

  pointMap.forEach((indices) => {
    if (indices.length > 1) {
      const numGroups = Math.min(5, indices.length)
      const groupSize = Math.ceil(indices.length / numGroups)

      Array.from({ length: numGroups }).forEach((_, groupIndex) => {
        const group = indices.slice(
          groupIndex * groupSize,
          (groupIndex + 1) * groupSize
        )

        if (group.length === 0 || places[group[0]] === undefined) {
          return
        }

        const { latOffset, lngOffset } = randomOffset()

        modifiedPlaces.push({
          idNames: group.map((index) => {
            return { id: places[index].id, name: places[index].name }
          }),
          lat: places[group[0]].lat + latOffset,
          lng: places[group[0]].lng + lngOffset,
        })
      })
    } else {
      modifiedPlaces.push({
        idNames: [{ id: places[indices[0]].id, name: places[indices[0]].name }],
        lat: places[indices[0]].lat,
        lng: places[indices[0]].lng,
      })
    }
  })

  return modifiedPlaces
}

export const UserLocationMap = ({
  mapCenter,
  aspectClass,
  zoomLevel,
  userLocations,
}: {
  mapCenter: LatLng
  aspectClass: string
  zoomLevel: number
  userLocations: CurrentLocation[] | null
}) => {
  // Properties for the map are needed from the window which the react
  // component sets. This polls to wait until the properties are available and
  // functional. There are loaded hooks that should work here as wel, however
  // they didn't appear to work in all cases and this approach seemed more
  // reliable.
  const { openUserDialog } = useUserDialogContext()
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google && window.google.maps) {
        if (typeof window.google.maps.Size === "function") {
          try {
            new window.google.maps.Size(100, 100)
            setGoogleMapsLoaded(true)
            clearInterval(interval)
          } catch (e) {
            // No-op and try again
          }
        }
      }
    }, 100)

    return () => clearInterval(interval)
  }, [])

  const staggeredPlaces = useMemo(() => {
    if (userLocations) {
      return staggerPlaces([...userLocations], zoomLevel)
    }

    return []
  }, [userLocations, zoomLevel])

  const markerRefs: MarkerRefs = useRef([] as RefObject<MarkerRef>[])

  // This stores all markers in an array. A map with id keys could also be used
  // here, however the markers are sometimes aggregates of multiple ids. This
  // can be expanded in the future to support showing a marker on the map when
  // interacting outside of the map.
  useEffect(() => {
    if (!userLocations) return

    markerRefs.current = markerRefs.current.slice(0, userLocations.length)
    userLocations.forEach((_, i) => {
      markerRefs.current[i] = markerRefs.current[i] || createRef()
    })
  }, [markerRefs, userLocations])

  const closeAllInfoWindows = useCallback(() => {
    markerRefs.current?.forEach((ref) => ref.current?.closeInfoWindow())
  }, [markerRefs])

  const GroupedMarker = forwardRef<MarkerRef, GroupedMarkerProps>(
    ({ position, idNames, expanded }, ref) => {
      const [open, setOpen] = useState(false)
      const [markerRef, marker] = useMarkerRef()

      useImperativeHandle(ref, () => {
        return {
          closeInfoWindow: () => {
            setOpen(false)
          },
        }
      })

      return (
        <>
          <Marker
            ref={markerRef}
            onClick={() => {
              closeAllInfoWindows()
              setOpen(!open)
            }}
            key={idNames[0].id}
            position={position}
            icon={{
              url: svgUrl,
              scaledSize: new window.google.maps.Size(20, 20),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(10, 20),
            }}
          />
          {open && (
            <InfoWindow
              className="text-center overflow-y-hidden overflow-x-hidden flex flex-col"
              anchor={marker}
            >
              <>
                {idNames.slice(0, 2).map(({ id, name }) => {
                  return (
                    <button
                      key={id}
                      className="font-medium text-2xs leading-tight truncate hover:text-primary"
                      onClick={() => {
                        open && openUserDialog(id)
                      }}
                    >
                      {name}
                    </button>
                  )
                })}
                {idNames.length > 2 && (
                  <div className="font-medium text-2xs leading-tight truncate">
                    + {idNames.length - 2}
                  </div>
                )}
              </>
            </InfoWindow>
          )}
        </>
      )
    }
  )

  return (
    <GoogleMap
      className={cn("flex-grow", aspectClass)}
      center={mapCenter}
      zoom={zoomLevel}
      mapTypeControl={false}
      zoomControl={false}
      fullscreenControl={false}
      streetViewControl={false}
      onClick={() => {
        closeAllInfoWindows()
      }}
    >
      {googleMapsLoaded &&
        staggeredPlaces.map(({ idNames, lat, lng }, index) => {
          return (
            <GroupedMarker
              ref={markerRefs.current[index]}
              key={idNames[0].id}
              idNames={idNames}
              position={{ lat: lat, lng: lng }}
            />
          )
        })}
    </GoogleMap>
  )
}
