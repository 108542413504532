import { useCallback, useMemo } from "react"
import toast from "react-hot-toast"
import { Article } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { ConfirmContextProviderProps, useConfirm } from "~/ui/Confirm"
import { UPDATE_ARTICLE_MUTATION } from "./ArticleEditor"
import { addWeeks, compareAsc, parseISO } from "date-fns"

export type ToggleFeaturedConfirmProps = {
  article: Pick<Article, "id" | "featuredUntil">
} & Partial<ConfirmContextProviderProps>

export const useToggleFeaturedConfirm = ({
  article,
  ...confirmProps
}: ToggleFeaturedConfirmProps) => {
  const showConfirm = useConfirm()
  const [runFeature] = useSafeMutation(UPDATE_ARTICLE_MUTATION)

  const isFeatured = useMemo(
    () =>
      article.featuredUntil &&
      compareAsc(parseISO(article.featuredUntil), new Date()) === 1,
    [article.featuredUntil]
  )

  const feature = useCallback(() => {
    showConfirm({
      title: isFeatured ? "Remove 'Featured' Flag" : "Feature Content",
      body: isFeatured
        ? "Would you like to remove the 'Featured' flag from this content?"
        : "Would you like to mark this content as featured?",
      confirmText: isFeatured ? "Remove Featured" : "Feature",
      onConfirm: async () => {
        const { errors } = await runFeature({
          variables: {
            input: {
              articleId: article.id,
              featuredUntil: isFeatured ? new Date() : addWeeks(new Date(), 2),
            },
          },
        })

        if (errors) {
          displayErrors(errors)
          console.log(errors)
        } else {
          toast.success(
            isFeatured
              ? "Removed 'Featured' flag"
              : "Content marked as featured"
          )
        }
      },
      ...confirmProps,
    })
  }, [article, confirmProps, runFeature, showConfirm, isFeatured])

  return feature
}
