import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { OnboardingTitle } from "~/ui/Onboarding"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { displayErrors } from "~/common/validations"
import {
  AhoyEventTypeEnum,
  OnboardingStateEnum,
  TierLevelEnum,
} from "~/__generated__/graphql"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { MembershipCard } from "~/users/MembershipCard"
import { useCommunity } from "~/community/useCommunity"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useQuery } from "@apollo/client"
import { CURRENT_USER_QUERY_DOCUMENT } from "~/auth/currentUserQuery"
import { useQaFitHelper } from "~/users/useQaFitHelper"
import { getMetaVar } from "~/common/getMetaVar"
import { Button } from "~/shadcn/ui/button"
import { useEffect, useState } from "react"
import { cn } from "~/common/shadcn-utils"
import ReactPlayer from "react-player"
import { useNavigate } from "react-router-dom"
import { feedPath, unfitPath } from "~/common/paths"
import { Trans, useTranslation } from "react-i18next"
import { useLogEvent } from "~/analytics/EventsContext"

const qaToolsEnabled = getMetaVar("qa-tools-enabled") === "true"

export const FitWaitingScreen = () => {
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser.fit === false) {
      navigate(unfitPath.pattern)
    }
  }, [navigate, currentUser])

  return (
    <OnboardingLayout step={4} hideCommunityFooter>
      {currentUser.fit ? <CardAndVideo /> : <Waiting />}
    </OnboardingLayout>
  )
}

const CardAndVideo = () => {
  const community = useCommunity()
  const [isFlipped, setIsFlipped] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation("community")
  const { logEvent } = useLogEvent()

  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)

  const onFlip = () => {
    logEvent(AhoyEventTypeEnum.PageViewed, {
      sub_page: "You're In",
    })
    setIsFlipped(true)
  }

  const onComplete = async () => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          onboardingState: OnboardingStateEnum.Completed,
        },
      },
    })
    if (errors) {
      displayErrors(errors)
    } else {
      navigate(feedPath.pattern)
    }
  }

  return (
    <>
      <OnboardingTitle>Welcome to {community.name}</OnboardingTitle>

      <div className="flex flex-col items-center">
        <div
          className={cn(
            "flip-card w-full md:w-[533px]",
            isFlipped && "flipped"
          )}
        >
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <MembershipCard containerClassName="md:w-full h-full md:h-full" />
            </div>
            <div className="flip-card-back">
              <div className="rounded-xl overflow-hidden w-full h-full">
                <ReactPlayer
                  height={"100%"}
                  width={"100%"}
                  controls={true}
                  playing={isFlipped}
                  url={
                    community.welcomeVideoUrl ||
                    "https://workweek-public.s3.us-east-2.amazonaws.com/web-app-v1.mp4"
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {isFlipped ? (
          <>
            <div className="mt-12 text-center max-w-[450px]">
              <Trans
                i18nKey="videoIntro"
                ns="onboarding"
                values={{ creatorName: t("creatorFirstName") }}
              />
            </div>
            <Button
              variant="onboarding"
              className="mt-14 py-3 px-7"
              onClick={onComplete}
            >
              Go to {community.name}
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="onboarding"
              className="mt-14 py-3 px-7"
              onClick={onFlip}
            >
              Continue
            </Button>
          </>
        )}
      </div>
    </>
  )
}

const Waiting = () => {
  const { openSubscriptionWizard } = useSubscription()
  const community = useCommunity()
  const { currentUser } = useCurrentUser()
  useQuery(CURRENT_USER_QUERY_DOCUMENT, { pollInterval: 1000 })
  const { qaMarkFit } = useQaFitHelper()

  return (
    <>
      <OnboardingTitle>Let's go! Thank you.</OnboardingTitle>

      <div className="text-center mb-4">
        The {community.name} membership team is reviewing your responses.
      </div>

      <div className="text-center mb-10">
        We'll get back to you via email in{" "}
        <span className="font-bold">{currentUser.tier?.waitPeriodHours}</span>{" "}
        hours to let you know about your membership status.
      </div>

      <div className="flex justify-center">
        <MembershipCard />
      </div>

      {currentUser.tier && currentUser.tier.level !== TierLevelEnum.Pro && (
        <div className="font-medium text-center mt-14">
          Want to skip the line and hear back sooner?
          <br />
          <span
            className="cursor-pointer underline"
            onClick={() =>
              openSubscriptionWizard("PricingTableStep", {
                source: "FitWaitingScreen",
              })
            }
          >
            Upgrade Subscription
          </span>
        </div>
      )}

      {currentUser.tier?.level === TierLevelEnum.Pro && (
        <div className="font-medium text-center mt-6">
          There is so much to look forward to. <br />
          See you in your inbox soon.
        </div>
      )}

      {qaToolsEnabled && (
        <div className="fixed bottom-[20px] right-[20px]">
          <Button
            onClick={() => qaMarkFit({ fit: false })}
            variant="destructive"
          >
            Mark Unfit (QA)
          </Button>
          <Button
            onClick={() => qaMarkFit({ fit: true })}
            variant="success"
            className="ml-2"
          >
            Mark Fit (QA)
          </Button>
        </div>
      )}
    </>
  )
}
