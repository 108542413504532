import { useState } from "react"
import { format, addQuarters, startOfQuarter } from "date-fns"
import { COMPANY_SIZES } from "~/merge/company_sizes"
import { INDUSTRIES } from "~/merge/industries"
import {
  CurrentUserProviderQuery,
  CompanySize,
  Scalars,
  Industry,
} from "~/__generated__/graphql"

import { EditDialog } from "~/benchmarks/EditDialog"
import { ManualCompanySize } from "~/merge/steps/ManualCompanySize"
import { ManualIndustry } from "~/merge/steps/ManualIndustry"

const formatDate = (date: Scalars["ISO8601DateTime"]["input"]) => {
  return format(date, "MMM do, yyyy")
}

const formatCompanySize = (size: CompanySize) => {
  const formatted = COMPANY_SIZES.find(({ value, label }) => {
    return value === size
  })

  return formatted?.label
}

const formatIndustry = (industry: Industry) => {
  const formatted = INDUSTRIES.find(({ value, label }) => {
    return value === industry
  })

  return formatted?.label
}

export const Status = ({
  user,
}: {
  user: CurrentUserProviderQuery["currentUser"]
}) => {
  const [companySizeDialogOpen, setCompanySizeDialogOpen] = useState(false)
  const [industryDialogOpen, setIndustryDialogOpen] = useState(false)

  const EditCompanySizeDialog = ({
    children,
  }: {
    children: React.ReactNode
  }) => {
    return (
      <EditDialog
        editDialog={
          <ManualCompanySize
            editContext={true}
            closeDialog={() => setCompanySizeDialogOpen(false)}
          />
        }
        dialogOpen={companySizeDialogOpen}
        setDialogOpen={setCompanySizeDialogOpen}
      >
        {children}
      </EditDialog>
    )
  }

  const EditIndustryDialog = ({ children }: { children: React.ReactNode }) => {
    return (
      <EditDialog
        editDialog={
          <ManualIndustry
            editContext={true}
            closeDialog={() => setIndustryDialogOpen(false)}
          />
        }
        dialogOpen={industryDialogOpen}
        setDialogOpen={setIndustryDialogOpen}
      >
        {children}
      </EditDialog>
    )
  }

  const hrisIntegration = user?.hrisIntegration
  const manualHrisData = user?.manualHrisData

  const lastUpdated = manualHrisData
    ? formatDate(manualHrisData.updatedAt)
    : hrisIntegration && hrisIntegration.lastSync
    ? formatDate(hrisIntegration.lastSync)
    : "NA"

  const nextUpdate = manualHrisData
    ? formatDate(startOfQuarter(addQuarters(manualHrisData.updatedAt, 1)))
    : hrisIntegration?.nextSync
    ? formatDate(hrisIntegration.nextSync)
    : "NA"

  const status = manualHrisData
    ? "Manual"
    : !hrisIntegration
    ? "No Inputs"
    : !hrisIntegration.initialSyncComplete
    ? "Syncing"
    : "Connected"

  const companySize = manualHrisData
    ? formatCompanySize(manualHrisData.companySize)
    : hrisIntegration?.companySize
    ? formatCompanySize(hrisIntegration.companySize)
    : "NA"

  const industry = manualHrisData
    ? formatIndustry(manualHrisData.industry)
    : hrisIntegration?.industry
    ? formatIndustry(hrisIntegration.industry)
    : "NA"

  return (
    <>
      <div className="grid grid-rows-3 grid-cols-2 gap-y-1 border-mercury tracking-wide">
        <div className="text-gray-400">Status: </div>
        <div>{status}</div>
        <div className="text-gray-400 row-start-2">Last Updated: </div>
        <div className="row-start-2">{lastUpdated}</div>
        <div className="text-gray-400 row-start-3">Next Update: </div>
        <div className="row-start-3">{nextUpdate}</div>
      </div>

      {manualHrisData ? (
        <EditCompanySizeDialog>
          <div className="grid grid-cols-2 text-primary mt-1">
            <div className="text-gray-400">Company Size: </div>
            <div className="truncate">{companySize}</div>
          </div>
        </EditCompanySizeDialog>
      ) : (
        <div className="grid grid-cols-2 mt-1">
          <div className="text-gray-400">Company Size: </div>
          <div className="truncate">{companySize}</div>
        </div>
      )}

      {manualHrisData || hrisIntegration ? (
        <EditIndustryDialog>
          <div className="grid grid-cols-2 mt-1 text-primary">
            <div className="text-gray-400">Industry: </div>
            <div className="truncate">{industry}</div>
          </div>
        </EditIndustryDialog>
      ) : (
        <div className="grid grid-cols-2 mt-1">
          <div className="text-gray-400">Industry: </div>
          <div className="truncate">{industry}</div>
        </div>
      )}
    </>
  )
}
