import React from "react"
import { CommunitySlug } from "~/__generated__/graphql"
import { cn } from "~/common/shadcn-utils"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"

export const OnboardingTitle: React.FC<{
  children?: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  const ccls = useCommunityClassname()

  return (
    <div
      className={cn(
        "text-foreground font-serif text-center mb-10 font-medium leading-[40px] md:leading-[52px] text-[32px] md:text-[42px]",
        ccls({
          [CommunitySlug.Safespace]: "text-titleColor",
          default: "text-foreground",
        }),
        className
      )}
    >
      {children}
    </div>
  )
}

export const OnboardingFieldLabel: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {}
> = ({ className, ...props }) => (
  <div
    className={cn("block mb-2 font-semibold text-foreground", className)}
    {...props}
  />
)

export const OnboardingInterestToggle: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & { checked: boolean }
> = ({ checked, className, ...props }) => {
  return (
    <button
      className={cn(
        "inline-block mr-2 mb-2 rounded-lg border-borderColor px-6 py-4 border bg-white font-semibold cursor-pointer text-dark-gray",
        {
          "bg-highlight border-highlight text-white": checked,
        }
      )}
      {...props}
    />
  )
}

export const OnboardingValue: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <div
      className={cn(
        "bg-highlight rounded-xl text-white text-[15px] p-4 lg:pr-8 lg:pl-7 flex gap-4 items-center font-semibold",
        className
      )}
      {...props}
    />
  )
}

export const SignInContent: React.FC<{ children?: React.ReactNode }> = (
  props
) => {
  const community = useCommunity()
  return (
    <div
      className={cn("sign-in-content bg-white text-dark-gray", {
        "bg-opacity-90": community.slug === CommunitySlug.Safespace,
      })}
      {...props}
    />
  )
}
