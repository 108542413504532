import { useQuery } from "@apollo/client"
import { useCallback, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { AdminHeader } from "~/admin/AdminHeader"
import { useSafeMutation } from "~/common/useSafeMutation"
import { UserDialogContextProvider } from "~/directory/UserDialogContext"
import {
  INTRODUCTION_CYCLES_ADMIN_FIND_QUERY_DOCUMENT,
  INTRODUCTION_CYCLES_ADMIN_OVERRIDE_MATCH_MUTATION,
  INTRODUCTION_CYCLES_ADMIN_TOGGLE_ACCEPTANCE_MUTATION,
  INTRODUCTION_CYCLES_ADMIN_TOGGLE_IGNORANCE_MUTATION,
  INTRODUCTION_CYCLES_ADMIN_ACCEPT_SELECTED_MUTATION,
} from "~/introduction-cycles/api"
import { IntroductionMatchRow } from "~/introduction-cycles/IntroductionMatchRow"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"

import People from "~/images/icons/people.svg?react"
import { HeaderCell } from "~/admin/Table"
import { Button } from "~/shadcn/ui/button"

export const AdminIntroductionCycleScreen = () => {
  const { introductionCycleId } = useParams()
  invariant(introductionCycleId)
  const [overridingMatchId, setOverridingMatchId] = useState<string | null>(
    null
  )
  const [selectedMatchIds, setSelectedMatchIds] = useState<string[]>([])

  const {
    data,
    loading: fetchLoading,
    error: fetchError,
  } = useQuery(INTRODUCTION_CYCLES_ADMIN_FIND_QUERY_DOCUMENT, {
    variables: {
      introductionCycleId: introductionCycleId,
    },
  })
  const [
    runIntroductionMatchToggleAcceptance,
    introductionMatchToggleAcceptanceResult,
  ] = useSafeMutation(INTRODUCTION_CYCLES_ADMIN_TOGGLE_ACCEPTANCE_MUTATION)
  const [
    runIntroductionMatchToggleIgnorance,
    introductionMatchToggleIgnoranceResult,
  ] = useSafeMutation(INTRODUCTION_CYCLES_ADMIN_TOGGLE_IGNORANCE_MUTATION)
  const [
    runIntroductionMatchOverrideMatch,
    introductionMatchOverrideMatchResult,
  ] = useSafeMutation(INTRODUCTION_CYCLES_ADMIN_OVERRIDE_MATCH_MUTATION)
  const [
    runIntroductionMatchAcceptSelected,
    introductionMatchAcceptSelectedResult,
  ] = useSafeMutation(INTRODUCTION_CYCLES_ADMIN_ACCEPT_SELECTED_MUTATION)

  const matches = useMemo(() => data?.introductionCycle?.matches || [], [data])
  const locked = !!data?.introductionCycle?.locked
  const selectableMatches = useMemo(
    () => matches.filter((m) => !m.ignored && !m.accepted),
    [matches]
  )
  const allSelected = useMemo(
    () =>
      selectableMatches.length > 0 &&
      selectedMatchIds.length === selectableMatches.length,
    [selectableMatches, selectedMatchIds]
  )

  const onSelectAllToggle = useCallback(() => {
    if (allSelected) {
      setSelectedMatchIds([])
    } else {
      setSelectedMatchIds(selectableMatches.map((m) => m.id))
    }
  }, [allSelected, selectableMatches])

  const onAcceptSelectedMatches = () => {
    runIntroductionMatchAcceptSelected({
      variables: {
        input: {
          introductionMatchIds: selectedMatchIds,
        },
      },
    })
    setSelectedMatchIds([])
  }

  const matchesLoading =
    introductionMatchToggleAcceptanceResult.loading ||
    introductionMatchToggleIgnoranceResult.loading ||
    introductionMatchOverrideMatchResult.loading ||
    introductionMatchAcceptSelectedResult.loading
  const matchesError =
    introductionMatchToggleAcceptanceResult.error ||
    introductionMatchToggleIgnoranceResult.error ||
    introductionMatchOverrideMatchResult.error ||
    introductionMatchAcceptSelectedResult.error

  return (
    <UserDialogContextProvider>
      <div>
        <AdminHeader>
          <div className="flex gap-3 items-center text-xs">
            <People height={20} width={20} />
            Introduction Cycle: {data?.introductionCycle?.name}
          </div>
        </AdminHeader>
        <div className="px-5 pb-10 mt-4">
          {fetchLoading ? (
            <LoadingIndicatorCentered />
          ) : fetchError ? (
            <Error message="Error loading data." />
          ) : (
            <div>
              {!locked && (
                <div className="flex gap-3">
                  <Button
                    onClick={onSelectAllToggle}
                    disabled={matchesLoading}
                    size="sm"
                  >
                    {allSelected ? "Unselect All" : "Select All"}
                  </Button>
                  <Button
                    onClick={onAcceptSelectedMatches}
                    disabled={matchesLoading || selectedMatchIds.length <= 0}
                    size="sm"
                  >
                    Accept {selectedMatchIds.length} Selected Matches
                  </Button>
                </div>
              )}
              {matchesError ? (
                <Error message="Error loading matches." />
              ) : (
                <table className="bg-white table-auto mt-2">
                  <thead>
                    <tr>
                      <HeaderCell>Select</HeaderCell>
                      <HeaderCell>#</HeaderCell>
                      <HeaderCell>User</HeaderCell>
                      <HeaderCell>Match</HeaderCell>
                      <HeaderCell>Value</HeaderCell>
                      <HeaderCell>Accepted</HeaderCell>
                      <HeaderCell>Ignored</HeaderCell>
                    </tr>
                  </thead>
                  <tbody>
                    {matches.map((match, index) => (
                      <IntroductionMatchRow
                        key={match.id}
                        index={index}
                        match={match}
                        locked={locked}
                        overriding={overridingMatchId === match.id}
                        loading={matchesLoading}
                        selected={selectedMatchIds.includes(match.id)}
                        onToggleAcceptanceClick={() =>
                          runIntroductionMatchToggleAcceptance({
                            variables: {
                              input: {
                                introductionMatchId: match.id,
                              },
                            },
                          })
                        }
                        onToggleIgnoranceClick={() =>
                          runIntroductionMatchToggleIgnorance({
                            variables: {
                              input: {
                                introductionMatchId: match.id,
                              },
                            },
                          })
                        }
                        onToggleOverrideClick={() => {
                          if (overridingMatchId === match.id) {
                            setOverridingMatchId(null)
                          } else {
                            setOverridingMatchId(match.id)
                          }
                        }}
                        onOverrideMatch={(userId: string) => {
                          runIntroductionMatchOverrideMatch({
                            variables: {
                              input: {
                                introductionMatchId: match.id,
                                matchedWithUserId: userId,
                              },
                            },
                          })
                          setOverridingMatchId(null)
                        }}
                        onToggleSelectClick={() => {
                          if (selectedMatchIds.includes(match.id)) {
                            setSelectedMatchIds(
                              selectedMatchIds.filter((id) => id !== match.id)
                            )
                          } else {
                            setSelectedMatchIds([...selectedMatchIds, match.id])
                          }
                        }}
                      />
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </div>
    </UserDialogContextProvider>
  )
}
