import { InView } from "react-intersection-observer"
import { LoadingIndicator } from "./LoadingIndicator"
import { cn } from "~/common/shadcn-utils"

export const InfiniteLoadMore = ({
  onEndReached,
  canLoadMore,
  loadingText,
  loading,
  className = "mt-8",
}: {
  onEndReached: () => void
  canLoadMore: boolean
  loadingText: string
  loading: boolean
  className?: string
}) => {
  return (
    <>
      {loading && (
        <div className={cn("flex items-center", className)}>
          <LoadingIndicator />
          {loadingText}
        </div>
      )}

      <InView
        onChange={(inView) => {
          if (inView && canLoadMore) {
            console.log("infinite scroll loading more")
            onEndReached()
          }
        }}
      />

      {/* Backup button */}
      {canLoadMore && (
        <div className={cn("cursor-pointer", className)} onClick={onEndReached}>
          Load More
        </div>
      )}
    </>
  )
}
