import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "~/shadcn/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/shadcn/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { RadioGroup, RadioGroupItem } from "~/shadcn/ui/radio-group"
import { useWizard } from "~/ui/Wizard"
import { Alert } from "~/shadcn/ui/alert"
import { Separator } from "~/shadcn/ui/separator"
import { stripePortalPath } from "~/common/paths"
import { useEffect, useRef, useState } from "react"
import { useSubscription } from "../SubscriptionProvider"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { getMetaVar } from "~/common/getMetaVar"
import { useLogEvent } from "~/analytics/EventsContext"
import {
  AhoyEventTypeEnum,
  TierIntervalEnum,
  TierLevelEnum,
} from "~/__generated__/graphql"
import { SubscriptionOverview } from "../SubscriptionOverview"
import { useTiers } from "~/tiers/TiersProvider"
import { useCurrentUser } from "~/auth/CurrentUserContext"

enum IntakeGoal {
  UpgradeMembership = "UpgradeMembership",
  UpdatePaymentMethod = "UpdatePaymentMethod",
  UpdateBillingInformation = "UpdateBillingInformation",
  CancelSubscription = "CancelSubscription",
  ResumeSubscription = "ResumeSubscription",
}

const intakeFormSchema = z.object({
  goal: z.enum([
    IntakeGoal.UpgradeMembership,
    IntakeGoal.UpdatePaymentMethod,
    IntakeGoal.UpdateBillingInformation,
    IntakeGoal.CancelSubscription,
    IntakeGoal.ResumeSubscription,
  ]),
})
type IntakeFormValues = z.infer<typeof intakeFormSchema>

export const IntakeStep = () => {
  const { logEvent } = useLogEvent()
  const formRef = useRef<HTMLFormElement>(null)
  const { goToStep, close, addToMeta } = useWizard()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const { subscription, setSubscription } = useSubscription()
  const [runResumeSubscription] = useSafeMutation(RESUME_SUBSCRIPTION_MUTATION)
  const { tiers, freeTier, formatTierName } = useTiers()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    logEvent(AhoyEventTypeEnum.ManageSubscriptionModalViewed)
  }, [logEvent])

  const { handleSubmit, ...form } = useForm<IntakeFormValues>({
    resolver: zodResolver(intakeFormSchema),
  })

  const preHandleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { goal } = form.getValues()

    if (goal === IntakeGoal.CancelSubscription) {
      logEvent(AhoyEventTypeEnum.CancelMembershipSelected)
    } else if (goal === IntakeGoal.UpdateBillingInformation) {
      logEvent(AhoyEventTypeEnum.UpdateBillingInformationSelected)
    } else if (goal === IntakeGoal.UpdatePaymentMethod) {
      logEvent(AhoyEventTypeEnum.UpdatePaymentMethodSelected)
    } else if (goal === IntakeGoal.UpgradeMembership) {
      logEvent(AhoyEventTypeEnum.ChangeMembershipSelected)
    }

    if (
      goal === IntakeGoal.UpdatePaymentMethod ||
      goal === IntakeGoal.UpdateBillingInformation
    ) {
      setIsRedirecting(true)
      formRef.current!.action = stripePortalPath.pattern
      formRef.current!.method = "POST"
      goToStep("LoadingStep", "forward", false)
      return
    }

    return handleSubmit(onSubmit)(event)
  }

  const onSubmit = (values: IntakeFormValues) => {
    if (values.goal === IntakeGoal.CancelSubscription) {
      addToMeta(
        "selectedTier",
        tiers.find((tier) => tier.level === TierLevelEnum.Free)
      )
      addToMeta("selectedInterval", TierIntervalEnum.Year)
      goToStep("CancellationReasonStep")
    } else if (values.goal === IntakeGoal.ResumeSubscription) {
      goToStep("LoadingStep", "forward", false)
      setTimeout(async () => {
        const { data, errors } = await runResumeSubscription({
          variables: {
            input: {
              stripeSubscriptionId: subscription!.id,
            },
          },
        })

        if (errors) {
          console.log(errors)
          toast.error(
            "An unexpected error has occurred. Please try again later."
          )
          return
        }

        if (data?.stripeSubscriptionResume.stripeSubscription) {
          setSubscription(data.stripeSubscriptionResume.stripeSubscription)
        }
        goToStep("ResumedStep")
      }, 300)
    } else if (values.goal === IntakeGoal.UpgradeMembership) {
      addToMeta("showAllTiers", true)
      addToMeta("disableSelectCurrentTier", false)
      goToStep("PricingTableStep")
    }
  }

  const csrfToken = getMetaVar("csrf-token")

  if (!subscription) return null

  return (
    <>
      <DialogHeader>
        <DialogTitle>Manage Your Subscription</DialogTitle>
      </DialogHeader>
      <Alert>
        <SubscriptionOverview />
      </Alert>

      <Form {...form} handleSubmit={handleSubmit}>
        <form
          onSubmit={preHandleSubmit}
          className="flex flex-col gap-8"
          ref={formRef}
        >
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <input type="hidden" name="return_url" value={window.location.href} />
          {form.getValues().goal === IntakeGoal.UpdatePaymentMethod && (
            <input
              type="hidden"
              name="flow_type"
              value="payment_method_update"
            />
          )}
          <FormField
            control={form.control}
            name="goal"
            render={({ field }) => (
              <FormItem className="space-y-4">
                <FormLabel required className="font-semibold">
                  What can we help you with?
                </FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormItem variant="radio">
                      <FormControl>
                        <RadioGroupItem
                          value={IntakeGoal.UpdatePaymentMethod}
                        />
                      </FormControl>
                      <FormLabel>Update Payment Method</FormLabel>
                    </FormItem>
                    <FormItem variant="radio">
                      <FormControl>
                        <RadioGroupItem
                          value={IntakeGoal.UpdateBillingInformation}
                        />
                      </FormControl>
                      <FormLabel>Update Billing Information</FormLabel>
                    </FormItem>

                    <FormItem variant="radio">
                      <FormControl>
                        <RadioGroupItem value={IntakeGoal.UpgradeMembership} />
                      </FormControl>
                      <FormLabel>Change Plan</FormLabel>
                    </FormItem>
                    {currentUser.upcomingTier?.level !== TierLevelEnum.Free && (
                      <FormItem variant="radio">
                        <FormControl>
                          <RadioGroupItem
                            value={IntakeGoal.CancelSubscription}
                          />
                        </FormControl>
                        <FormLabel>
                          {subscription.cancelAt ? (
                            <>
                              Switch to{" "}
                              {formatTierName(freeTier, TierIntervalEnum.Year)}
                            </>
                          ) : (
                            <>Cancel Membership</>
                          )}
                        </FormLabel>
                      </FormItem>
                    )}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Separator />

          <DialogFooter>
            <Button type="button" variant="link" size="inline" onClick={close}>
              Cancel
            </Button>
            <Button type="submit" disabled={isRedirecting}>
              {isRedirecting ? "Loading..." : "Next"}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </>
  )
}

IntakeStep.displayName = "IntakeStep"

const RESUME_SUBSCRIPTION_MUTATION = gql(`
  mutation StripeSubscriptionResume($input: StripeSubscriptionResumeInput!) {
    stripeSubscriptionResume(input: $input) {
      stripeSubscription {
        ...StripeSubscriptionWithoutStatusHistory
      }
    }
  }
`)
