import { useMemo, useState } from "react"
import { gql } from "~/__generated__"
import {
  MarkReadEnum,
  UserSortEnum,
  User_CardFragment,
  AhoyEventTypeEnum,
} from "~/__generated__/graphql"
import { useQuery } from "@apollo/client"
import { PageWithRightSidebar } from "~/layouts/PageWithRightSidebar"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { UserCard, type UserCardFormat } from "~/directory/UserCard"
import { UserDialog } from "~/directory/UserDialog"
import { cn } from "~/common/shadcn-utils"
import { useDebounce } from "use-debounce"
import { TagFilter } from "~/feed/TagFilter"
import { useTagFilter } from "~/feed/useTagFilter"
import pluralize from "pluralize"
import Select from "react-select"
import { iso31661, iso31662 } from "iso-3166"
import { DirectoryMap } from "./directory/DirectoryMap"
import { FeatureFlag } from "~/common/FeatureFlag"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/shadcn/ui/dropdown-menu"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { Tabs, TabsList, TabsTrigger } from "~/shadcn/ui/tabs"
import { Button } from "~/shadcn/ui/button"

import LocationPin from "~/images/icons/location-pin.svg?react"
import DropdownArrow from "~/images/dropdown-arrow.svg?react"
import ChevronDown from "~/images/icons/chevron-down.svg?react"
import MinusCircle from "~/images/icons/minus-circle.svg?react"
import AddCircle from "~/images/icons/add-circle.svg?react"

import { type SingleValue } from "react-select"
import { useLogEvent } from "~/analytics/EventsContext"
import { useReadIndicators } from "~/content/useReadIndicators"
import { SearchInput } from "~/ui/SearchInput"
import { IntroductionsModule } from "./directory/IntroductionsModule"

export const DirectoryScreen = () => {
  const { logEvent } = useLogEvent()
  const [cardFormat, setCardFormat] = useState<UserCardFormat>("card")
  const [sort, setSort] = useState<UserSortEnum>(UserSortEnum.Recent)
  const [sortMenuOpen, setSortMenuOpen] = useState<boolean>(false)
  const [dialogUser, setDialogUser] = useState<User_CardFragment | null>(null)
  const [userDialogOpen, setUserDialogOpen] = useState<boolean>(false)
  const [query, setQuery] = useState<string>("")
  const [debouncedQuery, debounced] = useDebounce(query, 200)
  const { unviewedIds } = useReadIndicators({ target: MarkReadEnum.Directory })

  const { selectedTagIds, setSelectedTagIds } = useTagFilter()

  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)
  const [userLocationMapExpanded, setUserLocationMapExpanded] =
    useState<boolean>(false)
  const [selectedLocation, setSelectedLocation] =
    useState<SingleValue<{ value: string; label: string } | null>>(null)

  const {
    data: currentData,
    previousData,
    loading,
    error,
    fetchMore,
  } = useQuery(DIRECTORY_QUERY_DOCUMENT, {
    variables: {
      search: debouncedQuery,
      sort: sort,
      location: selectedLocation?.value,
      expertise: selectedTagIds,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })

  const data = currentData || previousData

  const users = useMemo(() => {
    return data?.users.edges.map((e) => e.node) || []
  }, [data])

  const locationOptions = useMemo(
    () =>
      iso31662
        .filter((s) => s.parent === "US")
        .map((s) => ({
          value: `state-${s.code.slice(3)}`,
          label: s.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
        .concat(
          iso31661
            .map((c) => ({ value: `country-${c.alpha2}`, label: c.name }))
            .sort((a, b) => a.label.localeCompare(b.label))
        ),
    []
  )

  const clearFilters = () => {
    setSelectedLocation(null)
    setSelectedTagIds([])
    setQuery("")
  }

  if (!data && loading)
    return (
      <div className="mt-8 flex items-center">
        <LoadingIndicator /> Loading directory...
      </div>
    )
  if (error || !data) return <Error message="Error loading directory." />

  const DirectoryFilters = ({
    className,
    expandable,
  }: {
    className: string
    expandable: boolean
  }) => {
    const toggleExpanded = () => {
      if (filtersExpanded) {
        clearFilters()
        setFiltersExpanded(false)
      } else {
        setFiltersExpanded(true)
      }
    }

    return (
      <Card className={cn("[box-shadow:none]", className)}>
        <CardHeader
          className={cn(
            "flex-row justify-between items-center",
            expandable && !filtersExpanded && "border-b-0"
          )}
        >
          <CardTitle>Filter Data</CardTitle>
          {expandable && (
            <div className="cursor-pointer" onClick={toggleExpanded}>
              {filtersExpanded ? (
                <MinusCircle className="text-highlight" />
              ) : (
                <AddCircle className="text-primary" />
              )}
            </div>
          )}
        </CardHeader>
        {(!expandable || filtersExpanded) && (
          <CardContent className="p-4 pb-3">
            <div className="relative">
              <Select
                classNamePrefix="react-select"
                placeholder="Select state or country..."
                options={locationOptions}
                value={selectedLocation}
                onChange={(data) => {
                  if (data?.label) {
                    logEvent(AhoyEventTypeEnum.LocationFilterApplied, {
                      location: data.label,
                    })
                  }

                  setSelectedLocation(data)
                }}
                isClearable={true}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                classNames={{
                  container: () => "tracking-[0.5px] font-medium text-2xs",
                  control: () =>
                    "!border-borderColor !rounded-lg !text-foreground pl-6",
                  placeholder: () => "!text-placeholder",
                }}
              />
              <LocationPin className="absolute top-[12px] left-[15px]" />
            </div>
            <TagFilter
              allTags={data.tags}
              selectedTagIds={selectedTagIds}
              setTagIds={setSelectedTagIds}
              variant="directory"
              title=""
              placeholder="Filter by expertise..."
            />
            <div className="mt-2 flex justify-center">
              <Button
                variant="empty"
                className="text-2xs tracking-[0.5px] py-3"
                onClick={() => {
                  const tags = data?.tags?.nodes.filter((t) =>
                    selectedTagIds.includes(t.id)
                  )

                  logEvent(AhoyEventTypeEnum.FiltersCleared, {
                    location: selectedLocation?.label,
                    topics: tags.map(({ name }) => name).join(", "),
                  })

                  clearFilters()
                }}
              >
                Clear Filters
              </Button>
            </div>
          </CardContent>
        )}
      </Card>
    )
  }

  const MenuItem = ({
    value,
    textValue,
  }: {
    value: UserSortEnum
    textValue: string
  }) => (
    <DropdownMenuItem
      className={cn(
        "px-8 text-2xs rounded-full tracking-[0.5px] text-placeholder cursor-pointer",
        value === sort && "!text-primary !bg-porcelain"
      )}
      onSelect={() => setSort(value)}
    >
      {textValue}
    </DropdownMenuItem>
  )

  return (
    <PageWithRightSidebar
      sidebar={
        <div className="flex flex-col gap-4">
          <IntroductionsModule />
          <DirectoryFilters className="hidden md:block" expandable={false} />
          {FeatureFlag.get("directoryUserMap") && (
            <DirectoryMap
              expandable={false}
              isExpanded={userLocationMapExpanded}
              setIsExpanded={setUserLocationMapExpanded}
              className="hidden md:block"
            />
          )}
        </div>
      }
    >
      <UserDialog
        isOpen={userDialogOpen}
        onClose={() => setUserDialogOpen(false)}
        user={dialogUser}
      />
      <DirectoryFilters className="md:hidden mb-4" expandable={true} />
      {FeatureFlag.get("directoryUserMap") && (
        <DirectoryMap
          expandable={true}
          isExpanded={userLocationMapExpanded}
          setIsExpanded={setUserLocationMapExpanded}
          className="md:hidden mb-4"
        />
      )}
      <Card>
        <CardContent className="p-0 tracking-[0.5px]">
          <div className="p-4 xl:px-6">
            <div className="flex justify-between gap-2 items-center flex-col xl:flex-row">
              <label className="relative block flex-1 w-full">
                <SearchInput
                  placeholder="Search by name, company, or title..."
                  searchTerm={query}
                  setSearchTerm={setQuery}
                />
              </label>
              <Tabs
                className="w-full xl:w-auto"
                value={cardFormat}
                onValueChange={(value) =>
                  setCardFormat(value as UserCardFormat)
                }
              >
                <TabsList className="w-full">
                  <TabsTrigger className="flex-1" value="card">
                    Card View
                  </TabsTrigger>
                  <TabsTrigger className="flex-1" value="list">
                    List View
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
            <div className="font-medium mt-4 flex justify-between items-center">
              <div className="text-[13px]">
                {!debounced.isPending() &&
                  !loading &&
                  (query.length > 0 ||
                    selectedTagIds.length > 0 ||
                    selectedLocation?.value) && (
                    <>
                      <span>
                        {pluralize("Match", data.users.totalCount, true)}
                      </span>{" "}
                      <span className="text-placeholder">
                        for your search criteria
                      </span>
                    </>
                  )}
              </div>
              <div>
                <DropdownMenu
                  open={sortMenuOpen}
                  onOpenChange={setSortMenuOpen}
                >
                  <DropdownMenuTrigger className="text-2xs tracking-[0.5px]">
                    Sort <span className="hidden sm:inline">Results </span>
                    <ChevronDown
                      className={cn(
                        "inline-block ml-1 relative top-[-1px]",
                        sortMenuOpen && "rotate-180"
                      )}
                    />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    align="end"
                    avoidCollisions={false}
                    sideOffset={10}
                    alignOffset={-12}
                    onCloseAutoFocus={(e) => e.preventDefault()}
                    className="!animate-none p-2 text-center font-semibold flex flex-col gap-1"
                  >
                    <MenuItem
                      value={UserSortEnum.Recent}
                      textValue="Most Recent"
                    />
                    <MenuItem
                      value={UserSortEnum.Name}
                      textValue="Alphabetical"
                    />
                    <DropdownArrow className="absolute right-[10px] top-[-7px]" />
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
          {users.length > 0 && (
            <div
              className={cn(
                "p-4 grid gap-3 border-t grid-cols-1",
                cardFormat === "card" && "xl:grid-cols-3"
              )}
            >
              {users.map((user) => (
                <UserCard
                  user={user}
                  format={cardFormat}
                  key={`user-${user.id}`}
                  onClick={() => {
                    setDialogUser(user)
                    setUserDialogOpen(true)
                  }}
                  highlight={unviewedIds.includes(user.id)}
                />
              ))}
              <InfiniteLoadMore
                onEndReached={() =>
                  fetchMore({
                    variables: { usersCursor: data.users.pageInfo.endCursor },
                  })
                }
                canLoadMore={!loading && data.users.pageInfo.hasNextPage}
                loadingText="Loading more users..."
                loading={
                  loading && users.length > 0 && data.users.pageInfo.hasNextPage
                }
              />
            </div>
          )}
        </CardContent>
      </Card>
    </PageWithRightSidebar>
  )
}

gql(`
  fragment User_Card on User {
    id
    name
    pronouns
    companyName
    jobTitle
    onboarded
    bio
    admin
    coach
    createdAt
    currentUserIntroducedAt
    ...User_Avatar
    place {
      ...Place_UserLocation
    }
    interests {
      id
      name
    }
    expertise {
      id
      name
    }
    linkedin
    twitter
    instagram
    anonymous
    pinnedPost {
      ...Post_Preview
    }
    celebrations {
      id
      celebrationType
      customCelebrationType
      date
    }
  }
`)

gql(`
  fragment Place_UserLocation on Place {
    id
    city
    state
    country
    full
    lat
    lng
  }
`)

// TODO: refactor to avoid querying tags on each page load
export const DIRECTORY_QUERY_DOCUMENT = gql(`
  query Directory($search: String, $usersCursor: String, $sort: UserSortEnum, $location: String, $expertise: [ID!]) {
    users(activeOnly: true, onboardedOnly: true, showHidden: false, sort: $sort, search: $search, location: $location, expertise: $expertise, first: 18, after: $usersCursor) {
      totalCount
      edges {
        node {
          ...User_Card
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    tags {
      ...TagConnection_Filter
    }
  }
`)
