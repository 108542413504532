import { CommunitySlug, Post_CardFragment } from "~/__generated__/graphql"
import { ReactNode, useEffect, useState } from "react"
import { postDateDisplay } from "./postDateDisplay"
import { PostMenu } from "./PostMenu"
import { ImageAttachments } from "./ImageAttachments"
import { FileAttachments } from "./FileAttachments"
import { PostActions } from "./PostActions"
import { gql } from "~/__generated__"
import Messages from "../images/icons/messages.svg?react"
import { PostContentSection } from "./PostContentSection"
import { createSearchParams, Link, useParams } from "react-router-dom"
import { JustBookmarkedCardHeader } from "~/bookmarks/JustBookmarkedCardHeader"
import { channelPath, postPath, userProfilePath } from "~/common/paths"
import { VideoSection } from "./VideoSection"
import { TagBubble } from "~/ui/TagBubble"
import {
  DetectInternalContentShareSection,
  RenderEmbeddedPost,
  RenderEmbeddedArticle,
} from "./PostInternalContentSection"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { filterPostAttachments } from "~/common/postUtils"
import { HighlightContent } from "~/components/HighlightContent"

import { Card, cardVariants } from "~/shadcn/ui/card"
import { useUserDialogContext } from "~/directory/UserDialogContext"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/shadcn/ui/tooltip"
import { cn } from "~/common/shadcn-utils"
import { useReactions } from "~/post-composer/useReactions"
import { Separator } from "~/shadcn/ui/separator"
import { VariantProps } from "class-variance-authority"
import { useInView } from "react-intersection-observer"
import { useReadTracking } from "./useReadTracking"
import { usePostHighlighting } from "./usePostHighlighting"
import { usePostInViewport } from "./PostInViewportContext"
import { Badge } from "~/shadcn/ui/badge"
import { useCommunityClassname } from "~/community/useCommunity"
import { Poll } from "~/polls/Poll"
import { UserName } from "~/directory/UserName"

const AUTHOR_TRUNCATION = 100
const AUTHOR_LINE_LIMIT = 1
// const CONTENT_TRUNCATION = 100
// const CONTENT_LINE_LIMIT = 3

interface PostCardProps extends VariantProps<typeof cardVariants> {
  post: Post_CardFragment
  header?: ReactNode
  isReply?: boolean
  replyDisabled?: boolean
  cardClickable?: boolean
  onSinglePostPage?: boolean
  hidePostMenu?: boolean
  hidePostActions?: boolean
  trackReadStates?: boolean
  query?: string
}

export const PostCard = ({
  post,
  header,
  isReply = false,
  replyDisabled = false,
  cardClickable = false,
  onSinglePostPage = false,
  hidePostMenu = false,
  hidePostActions = false,
  query = "",
  trackReadStates = false,
  ...cardVariantProps
}: PostCardProps) => {
  const { channelSlug } = useParams()
  const [imageAttachments, otherAttachments] = filterPostAttachments(post)
  const { openUserDialog } = useUserDialogContext()
  const { addReaction, removeReaction } = useReactions({ postId: post.id })
  const [showJustBookmarkedHeader, setShowJustBookmarkedHeader] =
    useState(false)
  const { updateInView } = usePostInViewport() || {}
  const ccls = useCommunityClassname()

  const { inView, ref } = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  })
  useReadTracking({ inView, post, enabled: trackReadStates })
  const { isHighlighted } = usePostHighlighting({
    inView,
    post,
    enabled: trackReadStates,
  })

  useEffect(() => {
    updateInView && updateInView({ post, inView })
  }, [inView, post, updateInView])

  // useEffect(() => {
  //   console.log("inView changed", post.id, inView)
  // }, [inView, post.id])

  if (post.deleted) {
    return (
      <Card
        {...cardVariantProps}
        className={cn(
          isHighlighted && "border-highlight bg-card-highlight-background"
        )}
      >
        <div className="p-4 text-2xs italic tracking-wide text-[#666666]">
          This post has been deleted
        </div>
      </Card>
    )
  }

  return (
    <>
      <Card
        {...cardVariantProps}
        ref={ref}
        className={cn(
          isHighlighted && "border-highlight bg-card-highlight-background"
        )}
      >
        {showJustBookmarkedHeader && <JustBookmarkedCardHeader />}
        {header}
        <div className="flex p-4">
          <div
            onClick={() => openUserDialog(post.user.id)}
            className="mr-3 card-clickable cursor-pointer shrink-0"
          >
            <AvatarWithFallback user={post.user} />
          </div>

          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-center">
              <Link
                to={userProfilePath({ id: post.user.id })}
                className="text-sm tracking-wide font-semibold mt-0.5 mr-2 card-clickable hover:underline flex gap-2 items-center"
                onClick={(e) => {
                  e.preventDefault()
                  openUserDialog(post.user.id)
                }}
              >
                <UserName
                  user={post.user}
                  formatter={(userName) => (
                    <HighlightContent
                      query={query}
                      content={userName}
                      truncationLimit={AUTHOR_TRUNCATION}
                      lineLimit={AUTHOR_LINE_LIMIT}
                    />
                  )}
                />
              </Link>
              <div className="text-xs tracking-wide text-gray-400 mt-0.5">
                {postDateDisplay(post.createdAt)}
              </div>
              {!hidePostMenu && (
                <div className="ml-auto">
                  <PostMenu post={post} onSinglePostPage={onSinglePostPage} />
                </div>
              )}
            </div>

            {post.channel && (
              <div className="flex">
                {post.channel.active ? (
                  <Link to={channelPath({ channelSlug: post.channel.slug })}>
                    <Badge
                      variant="channel"
                      className={cn(
                        "card-clickable",
                        ccls({
                          [CommunitySlug.Marketingland]:
                            "text-marketingland-blue border-marketingland-blue hover:bg-marketingland-blue/10",
                          default: "hover:bg-highlight/10",
                        })
                      )}
                    >
                      <Messages height="14px" width="14px" />
                      {post.channel.name}
                    </Badge>
                  </Link>
                ) : (
                  <Badge
                    variant="channel"
                    className={cn(
                      ccls({
                        [CommunitySlug.Marketingland]:
                          "text-marketingland-blue border-marketingland-blue",
                        default: "",
                      })
                    )}
                  >
                    <Messages height="14px" width="14px" />
                    {post.channel.name}
                  </Badge>
                )}
              </div>
            )}

            <PostContentSection post={post} />
            <VideoSection videoUrl={post.videoUrl} />
            <ImageAttachments attachments={imageAttachments} />
            <FileAttachments attachments={otherAttachments} />
            {post.retweetPostId && (
              <RenderEmbeddedPost
                postId={post.retweetPostId}
                parentPost={post}
              />
            )}
            {post.retweetArticleId && (
              <RenderEmbeddedArticle
                articleId={post.retweetArticleId}
                parentPost={post}
              />
            )}
            <DetectInternalContentShareSection post={post} />

            {post.poll && (
              <>
                <Separator />
                <Poll poll={post.poll} className="card-clickable" />
              </>
            )}

            {post.tag && (
              <div>
                <TagBubble tagName={post.tag.name} />
              </div>
            )}

            <div className="flex gap-2">
              {post.reactionEntries.map((entry) => (
                <TooltipProvider key={entry.id}>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <div
                        className={cn(
                          "rounded-full border border-mercury p-2 flex items-center text-2xs cursor-pointer card-clickable",
                          {
                            "border-blue-500 bg-blue-50":
                              entry.currentUserReacted,
                          }
                        )}
                        onClick={(e) => {
                          entry.currentUserReacted
                            ? removeReaction(entry.emoji)
                            : addReaction(entry.emoji)
                        }}
                      >
                        {entry.emoji}
                        <span className="ml-1">{entry.count}</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      {entry.userNames.map((name, index) => (
                        <p key={index} className="text-center">
                          {name}
                        </p>
                      ))}
                      {entry.userNames.length < entry.count && (
                        <p className="text-center">...and others</p>
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ))}
            </div>

            {!hidePostActions && (
              <>
                {!isReply && <Separator />}

                <PostActions
                  post={post}
                  isReply={isReply}
                  replyDisabled={replyDisabled}
                  onSinglePostPage={onSinglePostPage}
                  onBookmarkToggle={(bookmarkExists) =>
                    setShowJustBookmarkedHeader(bookmarkExists)
                  }
                />
              </>
            )}
          </div>
        </div>

        {cardClickable && (
          <Link
            to={{
              pathname: postPath({ postId: post.id }),
              search: channelSlug
                ? createSearchParams({ from: "channel" }).toString()
                : "",
            }}
            state={{ useBrowserBack: true }}
            className="absolute top-0 left-0 right-0 bottom-0"
          />
        )}
      </Card>
    </>
  )
}

gql(`
  fragment Post_Card on Post {
    id
    content
    createdAt
    isReply
    repliesCount
    pinnable
    videoUrl
    retweetPostId
    retweetArticleId
    currentUserUpvoted
    currentUserFlagged
    currentUserBookmark {
      ...Bookmark
    }
    currentUserCanDestroy {
      value
    }
    currentUserCanFlag {
      value
    }
    currentUserCanUpdate {
      value
    }
    currentUserCanPin {
      value
    }
    deleted
    parentPost {
      id
    }
    article {
      id
      approvedRevision {
        id
        title
      }
    }
    user {
      ...User_Avatar
    }
    channel {
      id
      name
      slug
      active
    }
    tag {
      id
      name
    }
    attachments {
      id
      editorUrl
      contentType
      byteSize
      filename
    }
    currentUserHasRead
    orderedCommenters {
      ...User_Avatar
    }
    commentersCount
    reactionEntries {
      id
      emoji
      count
      userNames
      currentUserReacted
    }
    poll {
      ...PollDisplay
    }
  }
`)
