import { zodResolver } from "@hookform/resolvers/zod"
import { formatInTimeZone, toDate } from "date-fns-tz"
import { useForm, SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { Button } from "~/shadcn/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"

const formSchema = z.object({
  name: z.string().min(1, { message: "Required" }),
  sendAt: z.string(),
})

export const INTRODUCTIONS_FIXED_TIMEZONE = "America/New_York"

export type IntroductionCycleFormValues = z.infer<typeof formSchema>

export const IntroductionCycleForm = ({
  defaultValues,
  onSubmit,
  loading,
}: {
  defaultValues: IntroductionCycleFormValues
  onSubmit: SubmitHandler<IntroductionCycleFormValues>
  loading: boolean
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  })
  const navigate = useNavigate()

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel required>Name</FormLabel>
              <div>
                <FormControl>
                  <input type="text" className="bg-white w-full" {...field} />
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="sendAt"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel required>Send Date</FormLabel>
              <div>
                {formatInTimeZone(
                  parseIntroductionsDateField(field.value),
                  INTRODUCTIONS_FIXED_TIMEZONE,
                  "M/d/yyyy p zzz"
                )}
                <FormControl>
                  <input
                    type="datetime-local"
                    className="bg-white w-full"
                    {...field}
                    value={field.value}
                    onChange={(e) => {
                      form.setValue("sendAt", e.target.value)
                    }}
                  />
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="h-[1px] bg-gray-300 my-8" />

        <div className="flex justify-end items center gap-4">
          <Button
            type="button"
            className="px-10"
            variant="outline"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="px-10"
            disabled={loading}
            isLoading={loading}
          >
            Save Cycle
          </Button>
        </div>
      </form>
    </Form>
  )
}

export const parseIntroductionsDateField = (dateString: string) => {
  return toDate(dateString, { timeZone: INTRODUCTIONS_FIXED_TIMEZONE })
}

export const utcDateToIntroductionsDateField = (date: Date) => {
  return formatInTimeZone(
    date,
    INTRODUCTIONS_FIXED_TIMEZONE,
    "yyyy-MM-dd'T'HH:mm"
  )
}
