import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/shadcn/ui/tooltip"
import { AvatarWithFallback } from "./AvatarWithFallback"
import { cn } from "~/common/shadcn-utils"
import { X } from "lucide-react"
import { UserName } from "~/directory/UserName"

interface UserPillUser {
  id: string
  firstName?: string | null
  lastName?: string | null
  name?: string | null
  photoUrl?: string | null
}

export const UserPill = ({
  user,
  canRemove,
  onRemove,
  pending = false,
}: {
  user: UserPillUser
  canRemove: boolean
  pending?: boolean
  onRemove: () => void
}) => (
  <TooltipProvider>
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <div
          className={cn(
            "flex gap-2 items-center px-2 py-1 bg-white rounded-full border border-primary",
            { "pr-3": !canRemove }
          )}
        >
          <div
            className={cn("flex gap-2 items-center", { "opacity-50": pending })}
          >
            <AvatarWithFallback user={user} size="post-autocomplete" />
            <div className="text-xs font-medium">
              <UserName user={user} />
            </div>
          </div>
          {canRemove && (
            <div className="cursor-pointer text-gray-300" onClick={onRemove}>
              <X size={20} />
            </div>
          )}
        </div>
      </TooltipTrigger>
      {pending && (
        <TooltipContent>
          {user.firstName} has not yet accepted the invitation to collaborate.
        </TooltipContent>
      )}
    </Tooltip>
  </TooltipProvider>
)
