import { useCurrentUser } from "~/auth/CurrentUserContext"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import MessageCompose from "~/images/icons/message-compose.svg?react"
import { MessageSectionHeader } from "./MessageSectionHeader"
import { MyRoomsQuery, Room_MessageListFragment } from "~/__generated__/graphql"
import { QueryResult } from "@apollo/client"
import { Error } from "~/ui/Error"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { postDateDisplay } from "~/feed/postDateDisplay"
import { cn } from "~/common/shadcn-utils"
import { Link, useParams } from "react-router-dom"
import { dmPath, roomPath } from "~/common/paths"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { ArrowLeft, Lock } from "lucide-react"
import { StyledDropdown, StyledDropdownItem } from "~/ui/StyledDropdown"
import { FeatureFlag } from "~/common/FeatureFlag"
import { roomDisplayName } from "./utils"

export const RoomsPane = ({
  openComposeModal,
  openNewRoomModal,
  roomsResult,
  orderedRooms,
  setMobileConversationsOpen,
}: {
  openComposeModal: () => void
  openNewRoomModal: () => void
  roomsResult: QueryResult<MyRoomsQuery>
  orderedRooms: Room_MessageListFragment[]
  setMobileConversationsOpen: (open: boolean) => void
}) => {
  const { currentUser } = useCurrentUser()

  return (
    <div className="flex-1-1-auto h-[0px] overflow-y-scroll relative">
      <MessageSectionHeader className="sticky top-0 z-30">
        <AvatarWithFallback
          user={currentUser}
          size="header"
          className="mr-4 hidden xl:block"
        />
        <div
          className="xl:hidden mr-4 cursor-pointer"
          onClick={() => setMobileConversationsOpen(false)}
        >
          <ArrowLeft />
        </div>
        <div className="flex-1 text-xs font-semibold">{currentUser.name}</div>

        <StyledDropdown trigger={<MessageCompose />}>
          <StyledDropdownItem
            title="Send a DM"
            onClick={openComposeModal}
            icon={
              currentUser.permissions.canInitiateDms ? undefined : (
                <Lock className="w-4 h-4" />
              )
            }
          />
          {FeatureFlag.get("groupChats") && (
            <StyledDropdownItem
              title="Create a group chat"
              onClick={openNewRoomModal}
              icon={
                currentUser.permissions.canInitiateDms ? undefined : (
                  <Lock className="w-4 h-4" />
                )
              }
            />
          )}
        </StyledDropdown>
      </MessageSectionHeader>

      <div className="p-4">
        {roomsResult.error ? (
          <Error message="Error loading conversations." />
        ) : roomsResult.loading && orderedRooms.length === 0 ? (
          <LoadingIndicator />
        ) : roomsResult.data ? (
          <div className="flex flex-col gap-4">
            {orderedRooms.map((room) => (
              <RoomEntry
                room={room}
                key={room.id}
                setMobileConversationsOpen={setMobileConversationsOpen}
              />
            ))}
            <InfiniteLoadMore
              onEndReached={() =>
                roomsResult.fetchMore({
                  variables: {
                    roomsCursor: roomsResult.data?.myRooms.pageInfo.endCursor,
                  },
                })
              }
              canLoadMore={
                !roomsResult.loading &&
                roomsResult.data.myRooms.pageInfo.hasNextPage
              }
              loadingText="Loading posts..."
              loading={roomsResult.loading && orderedRooms.length > 0}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const RoomEntry = ({
  room,
  setMobileConversationsOpen,
}: {
  room: Room_MessageListFragment

  setMobileConversationsOpen: (open: boolean) => void
}) => {
  const { currentUser } = useCurrentUser()
  const otherUser = room.dmOtherUser
  const latestPost = room.latestPost

  const { otherUserId, roomId } = useParams()
  const isSelected =
    (otherUserId && otherUserId === otherUser?.id) ||
    (roomId && room.id === roomId)

  const path = otherUser?.id
    ? dmPath({ otherUserId: otherUser.id })
    : roomPath({ roomId: room.id })

  return (
    <Link
      to={path}
      onClick={(e) => {
        setMobileConversationsOpen(false)
      }}
      className={cn("flex items-center tracking-wide rounded-lg -m-2 p-2", {
        "bg-porcelain": isSelected,
      })}
    >
      <AvatarWithFallback
        hasIndicator={room.hasUnreadPosts}
        size="header"
        user={otherUser || { id: room.id }}
        className="mr-3"
        textOverride={otherUser ? undefined : room.memberCount.toString()}
      />
      <div>
        <div className="font-medium text-xs mb-1">{roomDisplayName(room)}</div>
        {latestPost && (
          <div className="text-2xs">
            {latestPost.user.id === currentUser.id
              ? "You replied"
              : "Sent a message"}
            &nbsp;&middot;&nbsp;
            {postDateDisplay(latestPost.createdAt)}
          </div>
        )}
      </div>
    </Link>
  )
}
