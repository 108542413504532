import { useManualHrisDataContext } from "~/merge/manualData"

import { Dialog, DialogContent } from "~/shadcn/ui/dialog"

export const EditDialog = ({
  dialogOpen,
  setDialogOpen,
  editDialog,
  children,
}: {
  dialogOpen: boolean
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  editDialog?: React.ReactNode
  children?: React.ReactNode
}) => {
  const { resetFormToUserData } = useManualHrisDataContext()

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={(open) => {
        if (!open) {
          // This is needed to prevent someone setting an invalid value
          // in one dialog, closing it, and then trying to submit an
          // update from another dialog.
          setDialogOpen(false)
          resetFormToUserData()
        }
      }}
    >
      <div
        role="button"
        onClick={() => {
          setDialogOpen(true)
        }}
      >
        {children ? (
          children
        ) : (
          <div className="text-xs text-primary">Manually Edit Data</div>
        )}
      </div>
      <DialogContent
        className="p-0 w-2/3"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        {editDialog}
      </DialogContent>
    </Dialog>
  )
}
