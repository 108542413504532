import { Wizard, WizardHandle, WizardProps } from "~/ui/Wizard"
import { IntakeStep } from "./wizard/IntakeStep"
import { CancellationReasonStep } from "./wizard/CancellationReasonStep"
import { LoadingStep } from "./wizard/LoadingStep"
import { SuccessStep } from "./wizard/SuccessStep"
import { ConfirmCancelStep } from "./wizard/ConfirmCancelStep"
import { CancelledStep } from "./wizard/CancelledStep"
import { ResumedStep } from "./wizard/ResumedStep"
import { TakeAnExpenseTemplateStep } from "./wizard/TakeAnExpenseTemplateStep"
import { SentAnExpenseTemplateStep } from "./wizard/SentAnExpenseTemplateStep"
import { SpecialOfferStep } from "./wizard/SpecialOfferStep"
import { SafeSpaceBeforeYouMakeTheCallStep } from "./wizard/SafeSpaceBeforeYouMakeTheCallStep"
import { BoardRoomBeforeYouMakeTheCallStep } from "./wizard/BoardRoomBeforeYouMakeTheCallStep"
import { PreSpecialOfferStep } from "./wizard/PreSpecialOfferStep"
import { SoGladYouStayedStep } from "./wizard/SoGladYouStayedStep"
import { PricingTableStep } from "./wizard/PricingTableStep"
import { forwardRef } from "react"
import { PostUpgradeStep } from "./wizard/PostUpgradeStep"
import { SameTierSuccessStep } from "./wizard/SameTierSuccessStep"
import { MigrateToTierStep } from "./wizard/MigrateToTierStep"
import { MarketinglandBeforeYouMakeTheCallStep } from "./wizard/MarketinglandBeforeYouMakeTheCallStep"

interface ManageSubscriptionWizardProps extends Omit<WizardProps, "children"> {}

export const ManageSubscriptionWizard = forwardRef<
  WizardHandle,
  ManageSubscriptionWizardProps
>((props, ref) => {
  return (
    <Wizard {...props} ref={ref}>
      <IntakeStep />

      <SafeSpaceBeforeYouMakeTheCallStep />
      <BoardRoomBeforeYouMakeTheCallStep />
      <MarketinglandBeforeYouMakeTheCallStep />

      <CancellationReasonStep />
      <CancelledStep />
      <PreSpecialOfferStep />
      <SpecialOfferStep />
      <ConfirmCancelStep />
      <LoadingStep />
      <ResumedStep />
      <SentAnExpenseTemplateStep />
      <SoGladYouStayedStep />
      <SuccessStep />
      <TakeAnExpenseTemplateStep />

      <PricingTableStep />
      <PostUpgradeStep />
      <SameTierSuccessStep />
      <MigrateToTierStep />
    </Wizard>
  )
})
