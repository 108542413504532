import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

gql(`
  fragment User_CurrentUser on User {
    id
    email
    name
    firstName
    lastName
    pronouns
    companyName
    jobTitle
    onboarded
    fitWaiting
    photoUrl
    bio
    place {
      id
      city
      state
      country
      full
      lat
      lng
    }
    deviceLocationEnabled
    unreadMessageCount
    unreadNotificationCount
    unreadChannelPostsCount
    unviewedLibraryCount
    unviewedDirectoryCount
    unviewedEventsCount
    introductionsSnoozedUntil
    introductionsMadeCount
    interests {
      id
      name
    }
    expertise {
      id
      name
    }
    linkedin
    twitter
    instagram
    admin
    coach
    active
    fit
    fitnessReviewedAt
    stripeId
    activeStripeSubscription {
      id
      status
      currentPeriodStart
      currentPeriodEnd
      startDate
      endedAt
      cancelAt
      canceledAt
      cancelAtPeriodEnd
      discounts
      plan {
        id
        amount
        interval
        intervalCount
        product
      }
      latestInvoice {
        id
        attemptCount
        created
        nextPaymentAttempt
        paid
        amountPaid
        lines {
          periodStart
          periodEnd
        }
      }
      statusHistory {
        id
        status
        createdAt
      }
    }
    referralCode
    pinnedPostId
    hrisIntegration {
      name
      slug
      category
      identifier
      industry
      companySize
      lastSync
      nextSync
      initialSyncComplete
    }
    manualHrisData {
      hrisBackend
      industry
      companySize
      avgCostPerEmployeeDollars
      avgTenureMonths
      avgRetentionPercent
      avgTurnoverPercent
      updatedAt
    }
    celebrations {
      id
      celebrationType
      date
      customCelebrationType
    }
    newCourseAnnouncement {
      id
      approvedRevision {
        id
        title
      }
    }
    fitProfile {
      id
      yearsOfExperience
      industry
      linkedinProfileUrl
      companySize
      healthcareVertical
      jobFunction
    }

    permissions
    tier {
      id
      level
      name
      position
      waitPeriodHours
    }

    tierInterval
    upcomingTier {
      id
      level
      name
      position
      quarterlyStripePriceId
      yearlyStripePriceId
    }
    upcomingTierInterval

    __typename
  }
`)

export const CURRENT_USER_QUERY_DOCUMENT = gql(`
  query CurrentUserProvider {
    currentUser {
      ...User_CurrentUser
    }
  }
`)
