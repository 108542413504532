import { Events } from "~/admin/Events"

export const AdminEventsScreen = () => {
  return (
    <div className="container mx-auto">
      <h1 className="text-2xl mt-16 mb-8">Events</h1>
      <Events />
    </div>
  )
}
