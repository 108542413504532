import { zodResolver } from "@hookform/resolvers/zod"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/shadcn/ui/alert-dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Input } from "~/shadcn/ui/input"
import { Textarea } from "~/shadcn/ui/textarea"

type Prompt = {
  label: string
  placeholder: string
  required: boolean
  value?: string
  type?: "text" | "textarea"
}

export type ConfirmContextProviderProps = {
  body?: string
  cancelText?: string
  confirmText?: string
  onCancel: () => void
  onConfirm: (promptValue?: string) => void
  open: boolean
  prompt?: Prompt
  setBody: (body: string) => void
  setCancelText: (cancelText: string) => void
  setConfirmText: (confirmText: string) => void
  setOnCancel: (onCancel: () => void) => void
  setOnConfirm: (onConfirm: (promptValue?: string) => void) => void
  setOpen: (open: boolean) => void
  setPrompt: (prompt?: Prompt) => void
  setTitle: (title: string) => void
  title?: string
}

const ConfirmContext = createContext<ConfirmContextProviderProps>({
  body: "",
  cancelText: "Cancel",
  confirmText: "Confirm",
  onCancel: () => {},
  onConfirm: () => {},
  open: false,
  setBody: () => {},
  setCancelText: () => {},
  setConfirmText: () => {},
  setOnCancel: () => {},
  setOnConfirm: () => {},
  setOpen: () => {},
  setPrompt: () => {},
  setTitle: () => {},
  title: "Are you sure?",
})

let confirmPromise: Promise<boolean> | null = null

export const useConfirm = () => {
  const context = useContext(ConfirmContext)

  const showConfirm = useCallback(
    async (props: Partial<ConfirmContextProviderProps>) => {
      if (confirmPromise) await confirmPromise

      confirmPromise = new Promise<boolean>((resolve) => {
        context.setBody(props.body || "")
        context.setCancelText(props.cancelText || "Cancel")
        context.setConfirmText(props.confirmText || " Confirm")
        context.setOnCancel(() => () => {
          resolve(false)
          if (props.onCancel) props.onCancel()
          context.setOpen(false)
          confirmPromise = null
        })
        context.setOnConfirm(() => (promptValue?: string) => {
          resolve(true)
          if (props.onConfirm) props.onConfirm(promptValue)
          context.setOpen(false)
          confirmPromise = null
        })
        context.setTitle(props.title || "Are you sure?")

        if (props.prompt) {
          context.setPrompt({
            label: props.prompt.label,
            placeholder: props.prompt.placeholder,
            required: props.prompt.required,
            value: props.prompt.value,
            type: props.prompt.type || "textarea",
          })
        } else {
          context.setPrompt(undefined)
        }

        context.setOpen(true)
      })

      return confirmPromise
    },
    [context]
  )

  return showConfirm
}

type ConfirmProviderProps = {
  children: React.ReactNode
}

export const ConfirmProvider = ({ children }: ConfirmProviderProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [onConfirm, setOnConfirm] = useState<(promptValue?: string) => void>(
    () => {}
  )
  const [onCancel, setOnCancel] = useState<() => void>(() => {})
  const [title, setTitle] = useState<string>("Are you sure?")
  const [body, setBody] = useState<string>("")
  const [prompt, setPrompt] = useState<Prompt | undefined>(undefined)
  const [cancelText, setCancelText] = useState<string>("Cancel")
  const [confirmText, setConfirmText] = useState<string>("Confirm")

  const formSchema = z.object({
    prompt: z
      .string()
      .refine((value) => !prompt?.required || value.length > 0, {
        message: "Please enter a message",
      }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { prompt: prompt?.value || "" },
  })

  useEffect(() => {
    if (prompt?.value) {
      form.setValue("prompt", prompt.value)
    }
  }, [prompt, form])

  useEffect(() => {
    if (!open) {
      form.reset()
    }
  }, [form, open])

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      onConfirm(values.prompt)
    },
    [onConfirm]
  )

  return (
    <ConfirmContext.Provider
      value={{
        body,
        cancelText,
        confirmText,
        onCancel,
        onConfirm,
        open,
        prompt,
        setBody,
        setCancelText,
        setConfirmText,
        setOnCancel,
        setOnConfirm,
        setOpen,
        setPrompt,
        setTitle,
        title,
      }}
    >
      {children}
      <AlertDialog open={open}>
        <AlertDialogContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <AlertDialogHeader>
                <AlertDialogTitle>{title}</AlertDialogTitle>
              </AlertDialogHeader>
              <div className="flex flex-col gap-4 mb-4">
                {body && (
                  <AlertDialogDescription>{body}</AlertDialogDescription>
                )}
                {prompt && (
                  <div>
                    <FormField
                      control={form.control}
                      name="prompt"
                      render={({ field }) => (
                        <FormItem className="mb-4">
                          <FormLabel required={prompt.required}>
                            {prompt.label}
                          </FormLabel>
                          <FormControl>
                            {prompt.type === "text" ? (
                              <Input
                                placeholder={prompt.placeholder}
                                {...field}
                              />
                            ) : (
                              <Textarea
                                className="bg-white"
                                placeholder={prompt.placeholder}
                                {...field}
                              />
                            )}
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}
              </div>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={onCancel}>
                  {cancelText}
                </AlertDialogCancel>
                <AlertDialogAction type="submit">
                  {confirmText}
                </AlertDialogAction>
              </AlertDialogFooter>
            </form>
          </Form>
        </AlertDialogContent>
      </AlertDialog>
    </ConfirmContext.Provider>
  )
}
