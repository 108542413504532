import { useQuery } from "@apollo/client"
import { useState } from "react"
import { Trans } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import {
  onboardingInterestsPath,
  onboardingIntroductionsPath,
} from "~/common/paths"
import { TAGS_QUERY_DOCUMENT } from "~/common/queries"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { displayErrors } from "~/common/validations"
import { OnboardingCard } from "~/components/onboarding/OnboardingCard"
import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { Button } from "~/shadcn/ui/button"
import { Error } from "~/ui/Error"
import { OnboardingInterestToggle, OnboardingTitle } from "~/ui/Onboarding"
import { OnboardingQuestionCount } from "~/ui/OnboardingQuestionCount"

export const OnboardingExpertiseScreen = () => {
  const { data, loading, error } = useQuery(TAGS_QUERY_DOCUMENT)

  const [runUserUpdate, { loading: updating }] =
    useSafeMutation(USER_UPDATE_MUTATION)

  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  const [selectedExpertise, setSelectedExpertise] = useState<string[]>(
    currentUser.expertise.map((tag) => tag.id)
  )

  const updateUser = async () => {
    return await runUserUpdate({
      variables: {
        input: {
          expertiseIds: selectedExpertise,
        },
      },
    })
  }

  const onSubmit = async () => {
    const { errors } = await updateUser()

    if (errors) {
      displayErrors(errors)
      return false
    } else {
      navigate(onboardingIntroductionsPath.pattern)
    }
  }

  const onBack = async () => {
    await updateUser()
    navigate(onboardingInterestsPath.pattern)
  }

  const toggleExpertise = (tagId: string) => {
    if (selectedExpertise.includes(tagId)) {
      setSelectedExpertise(selectedExpertise.filter((id) => id !== tagId))
    } else if (
      !selectedExpertise.includes(tagId) &&
      selectedExpertise.length < 3
    ) {
      setSelectedExpertise([...selectedExpertise, tagId])
    }
  }

  return (
    <OnboardingLayout step={2}>
      <OnboardingTitle>
        <Trans i18nKey="expertiseScreenTitle" ns="onboarding" />
      </OnboardingTitle>
      <OnboardingCard
        user={currentUser}
        formData={{
          id: currentUser.id,
          expertise: selectedExpertise
            .map(
              (tagId) =>
                data?.tags.nodes.find((tag) => tag.id === tagId)?.name as string
            )
            .filter((value) => value),
        }}
      />
      {!loading &&
        (error || !data ? (
          <Error message="Error loading tags." />
        ) : (
          <>
            <div className="onboarding-question">
              <div>
                <OnboardingQuestionCount position={3} />
              </div>
              <div className="onboarding-question-sub">
                Choose between 2-3 areas
              </div>
            </div>
            <div className="mr-[-8px]">
              {data.tags.nodes.map((tag) => (
                <OnboardingInterestToggle
                  key={tag.id}
                  checked={selectedExpertise.includes(tag.id)}
                  onClick={() => toggleExpertise(tag.id)}
                >
                  {tag.name}
                </OnboardingInterestToggle>
              ))}
            </div>
            <div className="onboarding-footer mt-6">
              <div
                className="hidden md:block onboarding-footer-link"
                onClick={onBack}
              >
                Back to Your Experience -{" "}
                <OnboardingQuestionCount position={2} />
              </div>
              <Button
                variant="onboarding"
                type="submit"
                disabled={selectedExpertise.length < 2 || updating}
                onClick={onSubmit}
              >
                Continue
              </Button>
              <div
                className="md:hidden onboarding-footer-link"
                onClick={onBack}
              >
                Back to Your Experience -{" "}
                <OnboardingQuestionCount position={2} />
              </div>
            </div>
          </>
        ))}
    </OnboardingLayout>
  )
}
