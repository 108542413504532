import { DialogProps } from "@radix-ui/react-dialog"
import { useCallback, useEffect, useMemo } from "react"
import { gql } from "~/__generated__"
import { Dialog, DialogContent } from "~/shadcn/ui/dialog"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Input } from "~/shadcn/ui/input"
import { Button } from "~/shadcn/ui/button"
import { SavedOrUnsavedSection, UnsavedSection, generateTempId } from "~/types"
import { Textarea } from "~/shadcn/ui/textarea"
import { useCourseEditorContext } from "./CourseEditor"

const createSectionModalFormSchema = z.object({
  title: z.string().min(1, {
    message: "Title is required",
  }),
  description: z.string().min(1, {
    message: "Description is required",
  }),
})
export type CreateSectionModalFormValues = z.infer<
  typeof createSectionModalFormSchema
>

export type CreateSectionModalProps = {
  onCreateSection?: (section: SavedOrUnsavedSection) => void
} & DialogProps

export const CreateSectionModal = ({
  onCreateSection,
  ...dialogProps
}: CreateSectionModalProps) => {
  const { course, sections, setSections } = useCourseEditorContext()
  const courseId = useMemo(() => course?.id, [course])

  const form = useForm<z.infer<typeof createSectionModalFormSchema>>({
    resolver: zodResolver(createSectionModalFormSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  })

  useEffect(() => {
    if (!dialogProps.open) {
      form.reset()
    }
  }, [dialogProps.open, form])

  const onSubmit = useCallback(
    async (values: CreateSectionModalFormValues) => {
      if (!courseId) {
        return
      }

      const newSection: UnsavedSection = {
        tempId: generateTempId("section"),
        title: values.title,
        position: sections.length,
        lessons: [],
        hidden: false,
        description: values.description,
      }

      setSections((sections: SavedOrUnsavedSection[]) => [
        ...sections,
        newSection,
      ])
      onCreateSection && onCreateSection(newSection)
    },
    [onCreateSection, courseId, sections, setSections]
  )

  const cancel = useCallback(() => {
    if (dialogProps.onOpenChange) {
      dialogProps.onOpenChange(false)
    }
  }, [dialogProps])

  return (
    <Dialog {...dialogProps}>
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="text-center mb-2 font-serif text-3xl">Add Module</div>
        <div className="text-center mb-6 text-2xs">
          Modules are sections that help organize a course. You will be able to
          add individual Lessons within Modules.
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel required>Module Name</FormLabel>
                  <FormControl>
                    <Input
                      className="bg-white"
                      placeholder="Module name goes here..."
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel required>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      className="bg-white"
                      placeholder="Add a short description about this module..."
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-between">
              <Button variant="subtle" onClick={cancel}>
                Cancel
              </Button>

              <Button type="submit">Continue</Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

gql(`
  fragment Section_Full on Section {
    id
    title
    position
    hidden
    description

    lessons {
      ...Lesson_EditorContent
    }
  }
`)
