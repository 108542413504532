import React, { useEffect, useState, useRef, useCallback, useMemo } from "react"
import { useDebounce } from "use-debounce"
import { useQuery } from "@apollo/client"

import { gql } from "~/__generated__"
import { SearchFeedback } from "~/common/SearchFeedback"
import { ContributeToCommunityCallout } from "~/articles/ContributeToCommunityCallout"
import { Card, CardHeader, CardTitle } from "~/shadcn/ui/card"
import {
  LibraryArticleSortTypeEnum,
  AhoyEventTypeEnum,
  ArticleContentTypeEnum,
  MarkReadEnum,
  CommunitySlug,
} from "~/__generated__/graphql"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { DropdownMenuCheckboxes } from "~/common/DropdownMenuCheckboxes"
import {
  DropdownMenuBadges,
  BadgeOptionType,
} from "~/common/DropdownMenuBadges"
import { ContentTypeIcon } from "~/ui/TagBubble"
import { HorizontalContainer } from "~/ui/HorizontalContainer"
import { ArticleCard } from "~/ui/ArticleCard"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { useLogEvent } from "~/analytics/EventsContext"
import { useSearchParams } from "react-router-dom"
import { ARTICLE_CONTENT_TYPE_LABELS } from "~/common/enumTranslations"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { CONTENT_TYPES_FOR_COMMUNITY } from "~/library/contentTypes"
import { useReadIndicators } from "~/content/useReadIndicators"
import { SearchInput } from "~/ui/SearchInput"
import ChevronsRight from "../images/icons/chevrons-right.svg?react"

const contentTypeOptions = (
  contentTypes: ArticleContentTypeEnum[],
  iconClassName: string
) => {
  return contentTypes.map((value) => {
    return {
      value: value,
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <ContentTypeIcon contentType={value} className={iconClassName} />
          <div>{ARTICLE_CONTENT_TYPE_LABELS[value]}</div>
        </div>
      ),
    }
  })
}

type SortOptionType = {
  label: string
  value: LibraryArticleSortTypeEnum
}

type ContentOptionType = {
  label: string | React.ReactNode
  value: string
}

const sortOptions: SortOptionType[] = [
  {
    value: LibraryArticleSortTypeEnum.Alpha,
    label: "Alphabetically",
  },
  {
    value: LibraryArticleSortTypeEnum.CreatedAt,
    label: "Newest to Oldest",
  },
]

const PER_PAGE = 45

export const LibraryScreen = () => {
  const { logEvent } = useLogEvent()
  const [query, setQuery] = useState<string>("")
  const [debouncedQuery] = useDebounce(query, 200)
  const [topics, setTopics] = useState<BadgeOptionType[]>([])
  const [contentTypes, setContentTypes] = useState<ContentOptionType[]>([])
  const [sort, setSort] = useState<SortOptionType[]>([])
  const [initialCollaborators, setInitialCollaborators] = useState<string[]>([])
  const [paramsProcessed, setParamsProcessed] = useState(false)
  const community = useCommunity()
  const { unviewedIds } = useReadIndicators({ target: MarkReadEnum.Library })
  const ccls = useCommunityClassname()

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!paramsProcessed) {
      const searchParam = searchParams.get("search")
      const collabParam = searchParams.get("collaborators")

      if (searchParam) {
        setQuery(searchParam)
      }

      if (collabParam) {
        setInitialCollaborators(collabParam.split(","))
        searchParams.delete("collaborators")
        setSearchParams(searchParams)
      }

      setParamsProcessed(true)
    }
  }, [paramsProcessed, searchParams, setSearchParams])

  const {
    data: currentData,
    previousData,
    loading,
    fetchMore,
  } = useQuery(LIBRARY_QUERY_DOCUMENT, {
    variables: {
      query: debouncedQuery,
      topicIds: topics.length > 0 ? topics.map((t) => t.value) : undefined,
      contentTypes:
        contentTypes.length > 0
          ? contentTypes.map((t) => t.value)
          : CONTENT_TYPES_FOR_COMMUNITY[community.slug],
      sort: sort[0] ? sort[0].value : undefined,
      first: PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
    onCompleted: () => {
      if (debouncedQuery.length !== 0) {
        logEvent(AhoyEventTypeEnum.ContentSearched, {
          search_query: debouncedQuery,
        })
      }
    },
  })

  const data = currentData || previousData

  const { data: topTenArticles, loading: topTenArticlesLoading } = useQuery(
    LIBRARY_QUERY_DOCUMENT,
    {
      variables: {
        sort: LibraryArticleSortTypeEnum.TopViewed,
        first: 10,
      },
    }
  )

  const topicOptions = data?.tags.nodes
    ? data.tags.nodes.map((topic) => ({
        value: topic.id,
        label: topic.name,
      }))
    : []

  const { data: newlyAddedArticlesQuery, loading: newlyAddedArticlesLoading } =
    useQuery(LIBRARY_QUERY_DOCUMENT, {
      variables: {
        sort: LibraryArticleSortTypeEnum.CreatedAt,
        first: 10,
        contentTypes: CONTENT_TYPES_FOR_COMMUNITY[community.slug],
        newlyAdded: true,
      },
    })

  const newlyAddedArticles = useMemo(
    () => newlyAddedArticlesQuery?.libraryArticles.edges.map((e) => e.node),
    [newlyAddedArticlesQuery]
  )

  const articleContentLoaded = useCallback(() => {
    return !newlyAddedArticlesLoading && !topTenArticlesLoading && !loading
  }, [newlyAddedArticlesLoading, topTenArticlesLoading, loading])

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (
      articleContentLoaded() &&
      inputRef.current &&
      searchParams.get("search")
    ) {
      inputRef.current.scrollIntoView()
      searchParams.delete("search")
    }
  }, [articleContentLoaded, inputRef, searchParams])

  const clearSearch = () => {
    setQuery("")
    setTopics([])
    setContentTypes([])
    setSort([])
  }

  const hasNewlyAddedArticles =
    newlyAddedArticles && newlyAddedArticles.length > 0
  const top10Title = `Top 10 in ${community.name}`

  const searchActive =
    debouncedQuery !== "" ||
    topics.length > 0 ||
    contentTypes.length > 0 ||
    sort.length > 0

  return (
    <div className="flex flex-col w-full min-w-[300px] mt-5 pb-16">
      {paramsProcessed && (
        <ContributeToCommunityCallout
          includeMyContentLink
          startWritingWithCollaborators={initialCollaborators}
        />
      )}

      <Card className="mb-4 pb-5">
        <CardHeader className="mb-3">
          <CardTitle className="flex items-center justify-between">
            {hasNewlyAddedArticles ? "Newly Added" : top10Title}
            <ChevronsRight className="ml-1" />
          </CardTitle>
        </CardHeader>

        {(newlyAddedArticlesLoading ||
          (newlyAddedArticles && newlyAddedArticles.length > 0)) && (
          <HorizontalContainer headerHidden>
            {!newlyAddedArticles && newlyAddedArticlesLoading ? (
              <div className="flex items-center">
                <LoadingIndicator /> Loading Newly Added
              </div>
            ) : (
              <>
                {newlyAddedArticles?.map((article) => {
                  return (
                    <div key={article.id} className="flex-shrink-0 gap-2">
                      <ArticleCard
                        article={article}
                        highlight={unviewedIds.includes(article.id)}
                      />
                    </div>
                  )
                })}
              </>
            )}
          </HorizontalContainer>
        )}

        <div className="mt-3" />

        <HorizontalContainer
          headerHidden={!hasNewlyAddedArticles}
          title={top10Title}
        >
          {!topTenArticles && topTenArticlesLoading ? (
            <div className="flex items-center">
              <LoadingIndicator /> Loading Top 10...
            </div>
          ) : (
            <>
              {topTenArticles?.libraryArticles.edges
                .map((e) => e.node)
                .map((article) => {
                  return (
                    <div key={article.id} className="flex-shrink-0 gap-2">
                      <ArticleCard
                        article={article}
                        highlight={unviewedIds.includes(article.id)}
                      />
                    </div>
                  )
                })}
            </>
          )}
        </HorizontalContainer>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Community Library</CardTitle>
        </CardHeader>

        <div className="p-4 flex flex-col gap-2">
          <div className="flex flex-row flex-wrap md:flex-nowrap md:space-x-2">
            <div className="w-full md:flex-1">
              <div className="relative text-sm" ref={inputRef}>
                <SearchInput
                  placeholder="Search our library..."
                  searchTerm={query}
                  setSearchTerm={setQuery}
                />
              </div>
            </div>
            <div className="md:w-36 md:ml-auto w-full md:mt-0 mt-1">
              <DropdownMenuBadges
                title={
                  topics.length > 0
                    ? `${topics.length} Selected`
                    : "Filter Topic"
                }
                optionTitle="FILTER BY TOPIC"
                options={topicOptions}
                selected={topics}
                onChange={(data) => {
                  const topicNames = (data || []).map((t) => t.label)

                  if (topicNames.length !== 0) {
                    logEvent(AhoyEventTypeEnum.TopicFilterApplied, {
                      topics: topicNames.join(", "),
                    })
                  }

                  setTopics(data)
                }}
              />
            </div>
            <div className="md:w-36 md:ml-auto w-full md:mt-0 mt-1">
              <DropdownMenuCheckboxes
                title={
                  contentTypes.length > 0
                    ? `${contentTypes.length} Selected`
                    : "Filter Type"
                }
                optionTitle="FILTER BY TYPE"
                options={contentTypeOptions(
                  CONTENT_TYPES_FOR_COMMUNITY[community.slug],
                  ccls({
                    [CommunitySlug.Marketingland]: "text-primary",
                    default: "text-library-dropdown-badges",
                  })
                )}
                selected={contentTypes}
                onChange={(data) => {
                  const contentTypeNames = (data || []).map((t) => t.label)

                  if (contentTypeNames.length !== 0) {
                    logEvent(AhoyEventTypeEnum.ContentTypeFilterApplied, {
                      content_types: contentTypeNames.join(", "),
                    })
                  }

                  setContentTypes(data)
                }}
              />
            </div>
            <div className="md:w-36 md:ml-auto w-full md:mt-0 mt-1">
              <DropdownMenuCheckboxes
                title={"Sort"}
                options={sortOptions}
                selected={sort}
                onChange={setSort}
                exclusive={true}
              />
            </div>
          </div>
          {searchActive && (
            <SearchFeedback
              totalCount={data?.libraryArticles?.totalCount || 0}
              onClearSearch={() => {
                logEvent(AhoyEventTypeEnum.ContentSearchCleared, {
                  search_query: query,
                  topics: (topics || []).map((t) => t.label).join(", "),
                  types: (contentTypes || []).map((t) => t.value).join(", "),
                })
                clearSearch()
              }}
            />
          )}
        </div>

        <div className="border-t border-mercury" />

        <h3 className="p-4 text-sm font-semibold tracking-wide">
          Library Content
        </h3>

        <div className="container mx-auto p-6">
          <div
            className="mt-1 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 items-start min-h-[800px]"
            style={{ gridAutoRows: "min-content" }}
          >
            {!data && loading ? (
              <div className="flex items-center">
                <LoadingIndicator /> Loading articles...
              </div>
            ) : data ? (
              <>
                {data.libraryArticles.edges
                  .map((e) => e.node)
                  .map((article) => {
                    return (
                      <ArticleCard
                        query={query}
                        article={article}
                        key={article.id}
                        highlight={unviewedIds.includes(article.id)}
                      />
                    )
                  })}
                <InfiniteLoadMore
                  onEndReached={() =>
                    fetchMore({
                      variables: {
                        after: data.libraryArticles.pageInfo.endCursor,
                      },
                    })
                  }
                  canLoadMore={data.libraryArticles.pageInfo.hasNextPage}
                  loadingText="Loading articles..."
                  loading={false}
                />
              </>
            ) : null}
          </div>
        </div>
      </Card>
    </div>
  )
}

export const LIBRARY_QUERY_DOCUMENT = gql(`
  query LibraryArticles($query: String, $creatorsOnly: Boolean, $collaborators: [ID!], $topicIds: [ID!], $contentTypes: [String!], $sort: LibraryArticleSortTypeEnum, $first: Int, $after: String, $newlyAdded: Boolean) {
    libraryArticles(query: $query, creatorsOnly: $creatorsOnly, collaborators: $collaborators, topicIds: $topicIds, contentTypes: $contentTypes, sort: $sort, first: $first, after: $after, newlyAdded: $newlyAdded) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          ...Article_Card
        }
      }
    }

    tags {
      nodes {
        id
        name
      }
    }
  }
`)
