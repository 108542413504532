import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/ui/select"

type SelectEntry = {
  label: string
  value: string
}

export const SelectField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  label,
  labelClassName,
  containerClassName,
  required,
  selectEntries,
  placeholder,
}: {
  label: string
  labelClassName?: string
  containerClassName?: string
  description?: string
  control: Control<TFieldValues> | undefined
  name: TName
  required?: boolean
  selectEntries: SelectEntry[]
  placeholder?: string
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={containerClassName}>
          <FormLabel className={labelClassName} required={required}>
            {label}
          </FormLabel>

          <Select onValueChange={field.onChange} value={field.value || ""}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {selectEntries.map((entry, index) => (
                <SelectItem value={entry.value} key={index}>
                  {entry.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
