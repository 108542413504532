import { z } from "zod"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { Button } from "~/shadcn/ui/button"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { displayErrors } from "~/common/validations"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useTranslation } from "react-i18next"
import { useCommunity } from "~/community/useCommunity"
import {
  CommunitySlug,
  User_CurrentUserFragment,
} from "~/__generated__/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import pickBy from "lodash.pickby"
import { TextField } from "~/components/forms/TextField"
import { SelectField } from "~/components/forms/SelectField"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { camelCase } from "change-case"
import { Card } from "~/shadcn/ui/card"
import { useMemo } from "react"
import { FIT_PROFILE_INDUSTRIES } from "~/common/fitProfileIndustries"
import { SearchableSelectField } from "~/components/forms/SearchableSelectField"

export enum ExperienceFields {
  YearsOfExperience = "yearsOfExperience",
  Industry = "industry",
  LinkedinProfileUrl = "linkedinProfileUrl",
  CompanySize = "companySize",
  HealthcareVertical = "healthcareVertical",
  JobFunction = "jobFunction",
}

export const FieldsForCommunity: Record<CommunitySlug, ExperienceFields[]> = {
  [CommunitySlug.Safespace]: [
    ExperienceFields.CompanySize,
    ExperienceFields.Industry,
    ExperienceFields.LinkedinProfileUrl,
  ],
  [CommunitySlug.Boardroom]: [
    ExperienceFields.JobFunction,
    ExperienceFields.HealthcareVertical,
    ExperienceFields.LinkedinProfileUrl,
  ],
  [CommunitySlug.Marketingland]: [
    ExperienceFields.YearsOfExperience,
    ExperienceFields.CompanySize,
    ExperienceFields.LinkedinProfileUrl,
  ],
}

export const useMissingOnboardingExperienceFields = () => {
  const { currentUser } = useCurrentUser()
  const { slug } = useCommunity()
  const fields = FieldsForCommunity[slug]

  const missingFields = useMemo(() => {
    if (!currentUser || !currentUser.fitProfile) {
      return fields
    }

    return fields.filter((field) => {
      const fitProfileField = camelCase(
        field
      ) as keyof typeof currentUser.fitProfile
      return !currentUser.fitProfile![fitProfileField]
    })
  }, [currentUser, fields])

  return missingFields
}

const experienceFormSchema = z
  .object({
    yearsOfExperience: z.string(),
    yearsOfExperienceRequired: z.boolean(),
    industry: z.string().optional(),
    industryRequired: z.boolean(),
    linkedinProfileUrl: z.string().optional(),
    linkedinProfileUrlRequired: z.boolean(),
    companySize: z.string().optional(),
    companySizeRequired: z.boolean(),
    healthcareVertical: z.string().optional(),
    healthcareVerticalRequired: z.boolean(),
    jobFunction: z.string().optional(),
    jobFunctionRequired: z.boolean(),
  })
  .superRefine((data, ctx) => {
    const requirementKeys = Object.keys(data).filter(
      // @ts-ignore
      (k) => k.endsWith("Required") && data[k]
    )
    requirementKeys.forEach((requirementKey) => {
      const associatedPath = requirementKey.replace(/Required$/, "")
      // @ts-ignore
      const pathData = data[associatedPath]
      if (!pathData || pathData.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "This field is required",
          path: [associatedPath],
        })
      }
    })
  })
type ExperienceFormValues = z.infer<typeof experienceFormSchema>

interface OnboardingExperienceQuestionsProps {
  onSave: (formValues: ExperienceFormValues) => void
  footer?: (loading: boolean) => React.ReactNode
  withCard?: boolean
  fitProfileDefaults?: User_CurrentUserFragment["fitProfile"]
}

export const OnboardingExperienceQuestions = ({
  onSave,
  footer,
  withCard = true,
  fitProfileDefaults,
}: OnboardingExperienceQuestionsProps) => {
  const [runUserUpdate, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)
  const { slug } = useCommunity()
  const fields = FieldsForCommunity[slug]
  const { t } = useTranslation("onboarding")

  const defaultValues: ExperienceFormValues = {
    yearsOfExperience: fitProfileDefaults?.yearsOfExperience || "",
    yearsOfExperienceRequired: fields.includes(
      ExperienceFields.YearsOfExperience
    ),
    industry: fitProfileDefaults?.industry || "",
    industryRequired: fields.includes(ExperienceFields.Industry),
    linkedinProfileUrl: fitProfileDefaults?.linkedinProfileUrl || "",
    linkedinProfileUrlRequired: fields.includes(
      ExperienceFields.LinkedinProfileUrl
    ),
    companySize: fitProfileDefaults?.companySize || "",
    companySizeRequired: fields.includes(ExperienceFields.CompanySize),
    healthcareVertical: fitProfileDefaults?.healthcareVertical || "",
    healthcareVerticalRequired: fields.includes(
      ExperienceFields.HealthcareVertical
    ),
    jobFunction: fitProfileDefaults?.jobFunction || "",
    jobFunctionRequired: fields.includes(ExperienceFields.JobFunction),
  }
  const form = useForm<ExperienceFormValues>({
    defaultValues,
    resolver: zodResolver(experienceFormSchema),
  })

  const onSubmit: SubmitHandler<ExperienceFormValues> = async (formData) => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          fitProfile: pickBy(formData, (_, key) => !key.endsWith("Required")),
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      return false
    } else {
      onSave(formData)
    }
  }

  const Comp = withCard ? Card : "div"

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Comp className={withCard ? "p-6 pb-2" : ""}>
          {fields.includes(ExperienceFields.YearsOfExperience) && (
            <SelectField
              control={form.control}
              name="yearsOfExperience"
              containerClassName="mb-4"
              label="How many years of marketing experience do you have?"
              placeholder="Select years of experience..."
              required
              selectEntries={YEARS_OF_EXPERIENCE_OPTIONS.map((option) => ({
                label: option,
                value: option,
              }))}
            />
          )}

          {fields.includes(ExperienceFields.CompanySize) && (
            <SelectField
              control={form.control}
              name="companySize"
              containerClassName="mb-4"
              label={t("experienceScreen.companySize")}
              placeholder="Select company size..."
              required
              selectEntries={COMPANY_SIZE_OPTIONS.map((option) => ({
                label: option,
                value: option,
              }))}
            />
          )}

          {fields.includes(ExperienceFields.Industry) && (
            <SearchableSelectField
              control={form.control}
              name="industry"
              containerClassName="mb-4"
              label={t("experienceScreen.industry")}
              placeholder="Select industry..."
              required
              selectEntries={FIT_PROFILE_INDUSTRIES.map((option) => ({
                label: option,
                value: option,
              }))}
            />
          )}

          {fields.includes(ExperienceFields.JobFunction) && (
            <SelectField
              control={form.control}
              name="jobFunction"
              containerClassName="mb-4"
              label="What is your job function?"
              placeholder="Select job function..."
              required
              selectEntries={JOB_FUNCTION_OPTIONS.map((option) => ({
                label: option,
                value: option,
              }))}
            />
          )}

          {fields.includes(ExperienceFields.HealthcareVertical) && (
            <SelectField
              control={form.control}
              name="healthcareVertical"
              containerClassName="mb-4"
              label="Which healthcare vertical do you work in?"
              placeholder="Select healthcare vertical..."
              required
              selectEntries={HEALTHCARE_VERTICAL_OPTIONS.map((option) => ({
                label: option,
                value: option,
              }))}
            />
          )}

          {fields.includes(ExperienceFields.LinkedinProfileUrl) && (
            <TextField
              control={form.control}
              name="linkedinProfileUrl"
              containerClassName="mb-4"
              required
              label={t("experienceScreen.linkedinProfileUrl")}
              startAdornment={
                <span className="h-full flex items-center justify-center text-foreground text-2xs p-2 bg-mercury whitespace-nowrap">
                  https://linkedin.com/in/
                </span>
              }
            />
          )}
        </Comp>

        {footer ? (
          footer(loading)
        ) : (
          <div className="flex justify-center items-center pt-4">
            <Button type="submit" disabled={loading} size="lg">
              Submit
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  )
}

const JOB_FUNCTION_OPTIONS = [
  "Strategy",
  "Operations",
  "Business",
  "Sales",
  "Innovation",
  "Marketing",
  "Finance",
  "M&A",
  "Corporate",
  "Accounting",
  "Growth",
  "Other",
]

const HEALTHCARE_VERTICAL_OPTIONS = [
  "Hospital / health system",
  "Payor / Health Plan",
  "Other Risk-Bearing Organization (ACO/CIN/Enablement)",
  "Physician Practice",
  "Ambulatory Surgery Center",
  "Behavioral Health",
  "Tech-Enabled Services",
  "Health IT / Software / Health Tech",
  "Consulting / Advisor",
  "Banking / Private Equity",
  "Legal",
  "Academia",
]

const YEARS_OF_EXPERIENCE_OPTIONS = ["0-1", "2-5", "6-10", "11-15", "15+"]

export const COMPANY_SIZE_OPTIONS = [
  "1 - 10",
  "11 - 50",
  "51 - 250",
  "251 - 1k",
  "1k - 5k",
  "5k - 50k",
  "50k - 100k",
]
