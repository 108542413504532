import { CELEBRATION_CREATE_MUTATION } from "~/celebrations/celebrationCreateMutation"
import { useSafeMutation } from "~/common/useSafeMutation"

type CelebrationCreateInput = {
  date: string
  celebrationType: string
  customCelebrationType?: string
}

export const useCreateCelebration = () => {
  const [createCelebration, { loading }] = useSafeMutation(
    CELEBRATION_CREATE_MUTATION
  )

  const create = async ({
    date,
    celebrationType,
    customCelebrationType,
  }: CelebrationCreateInput) => {
    return await createCelebration({
      variables: {
        input: {
          date,
          celebrationType,
          customCelebrationType,
        },
      },
    })
  }
  return { create, loading }
}
