import Blocks from "editorjs-blocks-react-renderer"
import { WarningRenderer } from "./renderers/WarningRenderer"
import { HeaderRenderer } from "./renderers/HeaderRenderer"
import { ImageRenderer } from "./renderers/ImageRenderer"
import { AttachmentRenderer } from "./renderers/AttachmentRenderer"
import { LinkRenderer } from "./renderers/LinkRenderer"
import { EmbeddedPdfRenderer } from "./renderers/EmbeddedPdfRenderer"
import { PollRenderer } from "./renderers/PollRenderer"
import { AirTableFormEmbedRenderer } from "./renderers/AirTableFormEmbedRenderer"
import { cn } from "~/common/shadcn-utils"

export const RenderedArticle = ({
  editorData,
  preview = false,
}: {
  editorData: any
  preview: boolean
}) => {
  return (
    <div className={cn("editorjs-article", { preview })}>
      <Blocks
        data={editorData}
        config={{
          code: {
            className: "language-js",
          },
          delimiter: {
            className: "h-[1px] bg-border my-10",
          },
          embed: {
            className: "border-0",
          },
          header: {
            className: "font-normal mb-6 font-serif",
          },
          image: {
            className: "",
            actionsClassNames: {
              withBorder: "border border-2",
              withBackground: "p-2",
            },
          },
          list: {
            className: "list-outside ml-3 tracking-wide mb-6",
          },
          paragraph: {
            className: "text-base mb-6 min-h-[12px] tracking-wide",
            actionsClassNames: {
              alignment: "text-{alignment}", // This is a substitution placeholder: left or center.
            },
          },
          quote: {
            className: "editor-quote",
          },
          table: {
            className: "table-auto",
          },
          warning: {
            className: "mb-6",
          },
        }}
        renderers={{
          warning: WarningRenderer,
          header: HeaderRenderer,
          // @ts-ignore
          image: ImageRenderer,
          attaches: AttachmentRenderer,
          linkTool: LinkRenderer,
          pdfEmbed: EmbeddedPdfRenderer,
          poll: PollRenderer,
          airTableFormEmbed: AirTableFormEmbedRenderer,
          // raw: RawRenderer,
        }}
      />
    </div>
  )
}
