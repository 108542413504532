import React from "react"
import { cn } from "~/common/shadcn-utils"

type TexasToastProps = {
  open: boolean
  children: React.ReactNode
}

export const TexasToast = ({ open, children }: TexasToastProps) => {
  return (
    <div
      className={cn(
        "fixed bottom-0 right-0 left-0 p-4 w-full bg-white border border-mercury shadow-lg z-50 transition-transform duration-300 ease-in-out transform translate-y-full",
        { "translate-y-0": open }
      )}
    >
      {children}
    </div>
  )
}
