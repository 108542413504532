import { useLazyQuery } from "@apollo/client"
import { DataFunc, SuggestionDataItem } from "react-mentions"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { User } from "~/__generated__/graphql"

export type CustomDataItem = SuggestionDataItem &
  Pick<User, "id" | "photoUrl" | "firstName" | "lastName" | "coach" | "admin">

export const useMentionDataSource = ({
  mentionsDisabled,
}: { mentionsDisabled?: boolean } = {}) => {
  const [getMentionUsers] = useLazyQuery(MENTION_USER_QUERY_DOCUMENT, {
    variables: { excludeSelf: true },
  })

  const fetchMentionUsers: DataFunc = async (query, callback) => {
    if (!query || mentionsDisabled) return

    const { data, error } = await getMentionUsers({ variables: { query } })

    if (error) {
      callback([])
    } else {
      invariant(data)
      const dataItems: CustomDataItem[] = data.users.nodes
        .filter((u) => u.name && u.name.length > 0)
        .map((u) => ({
          display: u.name || " ",
          ...u,
        }))
      callback(dataItems)
    }
  }

  return { fetchMentionUsers }
}

export const MENTION_USER_QUERY_DOCUMENT = gql(`
  query MentionUsers($query: String, $excludeSelf: Boolean) {
    users(
      query: $query,
      excludeSelf: $excludeSelf,
      fitOnly: true,
      activeOnly: true,
      onboardedOnly: true,
      first: 20
    ) {
      nodes {
        ...User_Avatar
      }
    }
  }
`)
