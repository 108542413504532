import { useCurrentUser } from "~/auth/CurrentUserContext"
import { CardFeed } from "~/common/CardFeed"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { Status } from "~/benchmarks/Status"
import { Filters } from "~/benchmarks/Filters"
import { MergeLinkButton } from "~/merge/MergeLinkButton"
import clsx from "clsx"

export const BenchmarkSidebar = () => {
  const { currentUser } = useCurrentUser()

  const noHrisIntegration =
    !currentUser.manualHrisData && !currentUser.hrisIntegration
  const showConnect = currentUser.manualHrisData || noHrisIntegration
  const showManualSetup = noHrisIntegration

  return (
    <CardFeed>
      <Filters />

      <Card>
        <CardHeader>
          <CardTitle>
            <div className="flex justify-between">
              Details
              <div className={clsx({ hidden: !showConnect })}>
                <MergeLinkButton showManualSetup={showManualSetup}>
                  <button className="text-primary text-xs font-medium">
                    Connect HRIS
                  </button>
                </MergeLinkButton>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className="py-4 px-0">
          <div className="px-6 py-4">
            <Status user={currentUser} />
          </div>
        </CardContent>
      </Card>
    </CardFeed>
  )
}
