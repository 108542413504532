import { Alert } from "~/shadcn/ui/alert"
import { Button } from "~/shadcn/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/shadcn/ui/dialog"
import { Separator } from "~/shadcn/ui/separator"
import { useWizard } from "~/ui/Wizard"
import { useCommunity } from "~/community/useCommunity"
import { useLogEvent } from "~/analytics/EventsContext"
import { useEffect } from "react"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

export const SentAnExpenseTemplateStep = () => {
  const { close, meta } = useWizard()
  const community = useCommunity()
  const { logEvent } = useLogEvent()

  useEffect(() => {
    logEvent(AhoyEventTypeEnum.ExpenseTemplateViewed, {
      cancellation_reason: meta.cancellationReason,
      other_cancellation_reason: meta.otherReason,
    })
  }, [logEvent, meta.cancellationReason, meta.otherReason])

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          Sweet! We’ve also sent the expense template to your email.
        </DialogTitle>
      </DialogHeader>
      <Alert>
        Let us know if there's anything else we can support you with as you try
        to expense your {community.name} membership.
      </Alert>

      <Separator />

      <DialogFooter className="justify-center">
        <Button onClick={close}>Back to Membership</Button>
      </DialogFooter>
    </>
  )
}

SentAnExpenseTemplateStep.displayName = "SentAnExpenseTemplateStep"
