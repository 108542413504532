import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { useWizard } from "~/ui/Wizard"
import { useEffect } from "react"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"

export const PreSpecialOfferStep = () => {
  const { goToStep, addToMeta } = useWizard()
  const { data, loading } = useQuery(SAVE_BEFORE_LOSE_OFFER_QUERY_DOCUMENT)

  useEffect(() => {
    if (loading) return
    const offer = data?.saveBeforeLoseOffer
    if (!offer) {
      goToStep("ConfirmCancelStep")
      return
    }
    addToMeta("offer", offer)
    goToStep("SpecialOfferStep")
  }, [loading, data, goToStep, addToMeta])

  return (
    <div className="h-72 flex justify-center items-center">
      <LoadingIndicator />
    </div>
  )
}

PreSpecialOfferStep.displayName = "PreSpecialOfferStep"

const SAVE_BEFORE_LOSE_OFFER_QUERY_DOCUMENT = gql(`
  query SaveBeforeLoseOffer {
    saveBeforeLoseOffer {
      id
      name
      events
      tier {
        id
        name
        level
      }
      stripeCoupon {
        id
        amountOff
        percentOff
      }
    }
  }
`)
