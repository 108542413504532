import React from "react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/shadcn/ui/tooltip"

type SimpleTooltipProps = {
  content?: React.ReactNode | string | null
  align?: "start" | "center" | "end" | undefined
  children: React.ReactNode | string
  sideOffset?: number
  alignOffset?: number
}

export const SimpleTooltip = ({
  content,
  children,
  align,
  sideOffset,
  alignOffset,
}: SimpleTooltipProps) =>
  !content ? (
    <>{children}</>
  ) : (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild className="!cursor-help">
          {typeof children === "string" ? <span>{children}</span> : children}
        </TooltipTrigger>
        <TooltipContent
          sideOffset={sideOffset}
          alignOffset={alignOffset}
          align={align}
        >
          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
