import clsx from "clsx"
import { Button } from "~/shadcn/ui/button"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { INDUSTRIES } from "~/merge/industries"
import { COMPANY_SIZES } from "~/merge/company_sizes"
import { useFilterContext } from "~/benchmarks/filterContext"
import { MultiSelect } from "~/common/MultiSelect"
import AddCircle from "../images/icons/add-circle.svg?react"
import MinusCirlce from "../images/icons/minus-circle.svg?react"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

const industryOptions = INDUSTRIES.map(({ value, label }) => {
  return { value: value, label: label }
})

const companySizeOptions = COMPANY_SIZES.map(({ value, label }) => {
  return { value: value, label: label }
})

export const Filters = () => {
  const {
    resetFilters,
    industries,
    setIndustries,
    companySizes,
    setCompanySizes,
    updateFilters,
    filtersExpanded,
    setFiltersExpanded,
  } = useFilterContext()

  const { currentUser } = useCurrentUser()

  const disabled = !currentUser.hrisIntegration && !currentUser.manualHrisData

  const handleExpandClick = () => {
    setFiltersExpanded(!filtersExpanded)
  }

  const { logEvent } = useLogEvent()

  return (
    <>
      <Card>
        <CardHeader className={clsx({ "border-none": !filtersExpanded })}>
          <div className="flex justify-between">
            <CardTitle>Filter Data</CardTitle>
            <div
              role="button"
              className="md:hidden sm:block text-[#292D32]"
              onClick={handleExpandClick}
            >
              {filtersExpanded ? <MinusCirlce /> : <AddCircle />}
            </div>
          </div>
        </CardHeader>
        <div
          className={clsx("overflow-hidden", {
            "h-0": !filtersExpanded,
            "h-auto": filtersExpanded,
            "md:h-auto": !filtersExpanded,
          })}
        >
          <CardContent className="pt-4 flex flex-col space-y-2 items-center">
            <MultiSelect
              options={industryOptions}
              disabled={disabled}
              selected={industries}
              onChange={setIndustries}
              placeholder="Industries"
              className="w-full"
            />
            <MultiSelect
              options={companySizeOptions}
              disabled={disabled}
              selected={companySizes}
              onChange={setCompanySizes}
              placeholder="Company Size"
              className="w-full"
            />
            <Button
              className="w-full"
              variant="outline"
              disabled={disabled}
              onClick={() => {
                updateFilters()
                logEvent(AhoyEventTypeEnum.BenchmarkFilterApplied, {
                  industries: industries.join(", "),
                  company_sizes: companySizes.join(", "),
                })
              }}
            >
              Filter
            </Button>

            <Button
              className="w-full"
              disabled={disabled}
              variant="ghost"
              onClick={() => {
                logEvent(AhoyEventTypeEnum.BenchmarkFilterCleared, {
                  industries: industries.join(", "),
                  company_sizes: companySizes.join(", "),
                })
                resetFilters()
              }}
            >
              Clear Filter
            </Button>
          </CardContent>
        </div>
      </Card>
    </>
  )
}
