import { Repeat } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { Article_LiveContentFragment } from "~/__generated__/graphql"
import { feedPath } from "~/common/paths"
import { IconButton } from "~/ui/IconButton"

export const RetweetIcon = ({
  article,
}: {
  article: Article_LiveContentFragment
}) => {
  const navigate = useNavigate()

  return (
    <IconButton
      className="hover:text-black text-foreground"
      aria-label="Retweet article"
      onClick={() => {
        navigate(
          feedPath.pattern + `?post=focus&share_article_id=${article.id}`
        )
      }}
    >
      <Repeat className="w-4 h-4" />
    </IconButton>
  )
}
