import { formatDate } from "date-fns"
import { useMemo } from "react"
import { gql } from "~/__generated__"
import { CommunityStatistic_AdminFragment } from "~/__generated__/graphql"
import { Card, CardContent, CardHeader } from "~/shadcn/ui/card"

interface DataSummaryModulesProps {
  communityStatistic?: CommunityStatistic_AdminFragment
  loading: boolean
}

export const DataSummaryModules = ({
  communityStatistic,
  loading,
}: DataSummaryModulesProps) => {
  const lastWeek = useMemo(() => {
    // From Monday at 00:00:00 Eastern Standard to Sunday at 23:59:59 Eastern Standard of the previous week
    const now = new Date()
    const day = now.getDay()
    const diff = now.getDate() - day + (day === 0 ? -6 : 1) - 7
    const lastMonday = new Date(now.setDate(diff))
    lastMonday.setHours(0, 0, 0, 0)
    const lastSunday = new Date(lastMonday)
    lastSunday.setDate(lastSunday.getDate() + 6)
    return [lastMonday, lastSunday]
  }, [])

  const thisWeek = useMemo(() => {
    // From Monday at 00:00:00 Eastern Standard to Sunday at 23:59:59 Eastern Standard of the current week
    const now = new Date()
    const day = now.getDay()
    const diff = now.getDate() - day + (day === 0 ? -6 : 1)
    const thisMonday = new Date(now.setDate(diff))
    thisMonday.setHours(0, 0, 0, 0)
    const thisSunday = new Date(thisMonday)
    thisSunday.setDate(thisSunday.getDate() + 6)
    return [thisMonday, thisSunday]
  }, [])

  return (
    <>
      {loading && <div>Loading...</div>}
      {communityStatistic && !loading && (
        <div className="grid grid-cols-7 gap-4 mb-8">
          <Card className="col-span-3">
            <CardHeader className="text-center font-bold">Overview</CardHeader>
            <CardContent className="text-center grid grid-cols-3 gap-4 pt-4">
              <div className="flex flex-col flex-grow">
                <div className="text-2xl font-bold">
                  {communityStatistic.applicationsReceivedCount}
                </div>
                <div className="text-xs">
                  Applications Received
                  <br />
                  (All-Time)
                </div>
              </div>
              <div className="flex flex-col flex-grow">
                <div className="text-2xl font-bold">
                  {communityStatistic.unfitUsersCount}
                </div>
                <div className="text-xs">
                  Marked Unfit
                  <br />
                  (All-Time)
                </div>
              </div>
              <div className="flex flex-col flex-grow">
                <div className="text-2xl font-bold">
                  {communityStatistic.fitUsersCount}
                </div>
                <div className="text-xs">
                  Accepted
                  <br />
                  (All-Time)
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="col-span-2">
            <CardHeader className="text-center font-bold">
              {formatDate(lastWeek[0], "MMM d")} -{" "}
              {formatDate(lastWeek[1], "MMM d")}
            </CardHeader>
            <CardContent className="text-center grid grid-cols-2 gap-4 pt-4">
              <div className="flex flex-col flex-grow">
                <div className="text-2xl font-bold">
                  {communityStatistic.priorWeekApplicationsReceivedCount}
                </div>
                <div className="text-xs">Applications Received</div>
              </div>
              <div className="flex flex-col flex-grow">
                <div className="text-2xl font-bold">
                  {communityStatistic.priorWeekActivatedUsersCount}
                </div>
                <div className="text-xs">New Members Activated</div>
              </div>
            </CardContent>
          </Card>
          <Card className="col-span-2">
            <CardHeader className="text-center font-bold">
              {formatDate(thisWeek[0], "MMM d")} -{" "}
              {formatDate(thisWeek[1], "MMM d")}
            </CardHeader>
            <CardContent className="text-center grid grid-cols-2 gap-4 pt-4">
              <div className="flex flex-col flex-grow">
                <div className="text-2xl font-bold">
                  {communityStatistic.currentWeekApplicationsReceivedCount}
                </div>
                <div className="text-xs">Applications Received</div>
              </div>
              <div className="flex flex-col flex-grow">
                <div className="text-2xl font-bold">
                  {communityStatistic.currentWeekActivatedUsersCount}
                </div>
                <div className="text-xs">New Members Activated</div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  )
}

gql(`
  fragment CommunityStatistic_Admin on CommunityStatistic {
    id
    usersCount
    applicationsReceivedCount
    unfitUsersCount
    fitUsersCount
    priorWeekActivatedUsersCount
    priorWeekApplicationsReceivedCount
    currentWeekActivatedUsersCount
    currentWeekApplicationsReceivedCount
  }
`)

export const COMMUNITY_STATISTIC_QUERY_DOCUMENT = gql(`
  query CommunityStatistic($communityId: ID) {
    communityStatistic(communityId: $communityId) {
      ...CommunityStatistic_Admin
    }
  }
`)
