import { gql } from "~/__generated__"

export const CELEBRATION_UPDATE_MUTATION = gql(`
  mutation CelebrationUpdate($input: CelebrationUpdateInput!) {
    subject: celebrationUpdate(input: $input) {
      celebration {
        id
        celebrationType
        customCelebrationType
        date
      }
    }
  }
`)
