import { useQuery } from "@apollo/client"
import { AdminHeader } from "~/admin/AdminHeader"
import { HeaderCell, TableCell } from "~/admin/Table"
import CalendarGroup from "~/images/icons/calendar-group.svg?react"
import { LinkButton } from "~/shadcn/ui/button"
import { Error } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { adminProEventNewPath, adminProEventEditPath } from "~/common/paths"
import {
  PRO_EVENTS_ADMIN_LIST_QUERY_DOCUMENT,
  PRO_EVENT_FIXED_TIMEZONE,
} from "~/pro-events/pro-events"
import { PublishedToggle } from "~/pro-events/PublishedToggle"
import { formatInTimeZone } from "date-fns-tz"

export const AdminProEventsScreen = () => {
  const { data, loading, error, fetchMore } = useQuery(
    PRO_EVENTS_ADMIN_LIST_QUERY_DOCUMENT,
    {
      variables: {
        after: null,
      },
    }
  )

  const proEvents = data?.proEvents?.edges.map((e) => e.node) || []

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading pro events." />

  return (
    <div>
      <AdminHeader>
        <div className="flex gap-3 items-center text-xs">
          <CalendarGroup height={20} width={20} />
          Pro Events
        </div>
      </AdminHeader>
      <div className="px-5 pb-10 mt-4">
        <div className="flex justify-end pb-5">
          <LinkButton to={adminProEventNewPath({})}>New Pro Event</LinkButton>
        </div>
        <table className="bg-white table-auto w-full">
          <tbody>
            <tr>
              <HeaderCell>Title</HeaderCell>
              <HeaderCell>Event Date</HeaderCell>
              <HeaderCell>Published</HeaderCell>
              <HeaderCell className="text-right">Actions</HeaderCell>
            </tr>

            {proEvents.map((proEvent) => (
              <tr key={proEvent.id}>
                <TableCell>{proEvent.title}</TableCell>
                <TableCell>
                  {formatInTimeZone(
                    proEvent.date,
                    PRO_EVENT_FIXED_TIMEZONE,
                    "M/dd/yyyy zzz"
                  )}
                </TableCell>
                <TableCell>
                  <PublishedToggle
                    proEventId={proEvent.id}
                    published={proEvent.published}
                  />
                </TableCell>
                <TableCell className="text-right">
                  <LinkButton
                    variant="default"
                    size="sm"
                    to={adminProEventEditPath({ proEventId: proEvent.id })}
                  >
                    Edit
                  </LinkButton>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </table>
        {data.proEvents && (
          <InfiniteLoadMore
            onEndReached={() => {
              if (!data.proEvents) {
                return
              }
              fetchMore({
                variables: {
                  after: data.proEvents.pageInfo.endCursor,
                },
              })
            }}
            canLoadMore={!loading && data.proEvents.pageInfo.hasNextPage}
            loadingText="Loading pro events..."
            loading={loading && proEvents.length > 0}
          />
        )}
      </div>
    </div>
  )
}
