import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import toast from "react-hot-toast"
import { useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Editor, EditorHandle } from "~/editor/Editor"
import clsx from "clsx"
import { RenderedArticle } from "~/editor/RenderedArticle"
import { Article_EditorContentFragment } from "~/__generated__/graphql"
import { EditorMetadataSection } from "~/editor/EditorMetadataSection"
import { Card, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { ArticleActionToolbar } from "~/articles/ArticleActionToolbar"
import { useDirtyTracking } from "~/editor/useDirtyTracking"
import { ArticleLockedBanner } from "~/editor/ArticleLockedBanner"
import { useArticleLocking } from "~/editor/useArticleLocking"
import { editArticlePath, myContentPath } from "~/common/paths"

export const ArticleEditor = ({
  article,
}: {
  article: Article_EditorContentFragment
}) => {
  const editorRef = useRef<EditorHandle>(null)
  const { articleId } = useParams()
  invariant(articleId)

  const [searchParams] = useSearchParams()
  const [previewData, setPreviewData] = useState<any>(null)
  const [previewSelected, setPreviewSelected] = useState<boolean>(
    searchParams.get("preview") !== null
  )

  const [currentTrackedObject, setCurrentTrackedObject] = useState(
    getBlocks(article)
  )
  const dirtyTracking = useDirtyTracking({
    savedTrackedObject: getBlocks(article),
    currentTrackedObject,
  })
  const articleLocking = useArticleLocking({
    articleId,
    isDirty: dirtyTracking.isDirty,
  })

  const [runSaveArticle, saveArticleResult] = useSafeMutation(
    UPDATE_ARTICLE_MUTATION
  )

  const previewArticle = useCallback(async () => {
    const content = await editorRef?.current?.getContent()
    console.log("previewing", content)
    setPreviewData(content)
    setPreviewSelected(true)
  }, [setPreviewSelected, setPreviewData])

  useEffect(() => {
    if (article && !previewData) {
      setPreviewData(article.draftRevision.editorjsContent)
    }
  }, [article, previewData])

  const saveArticle = async () => {
    const content = await editorRef?.current?.getContent()

    const { data, errors } = await runSaveArticle({
      variables: {
        input: {
          articleId,
          editorjsContent: content,
          contentVersion: article.draftRevision.contentVersion,
        },
      },
    })

    dirtyTracking.onSave(errors)

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else if (data?.articleUpdate) {
      toast.success("Article saved")
    }
  }

  const onChange = useCallback(async () => {
    const content = await editorRef?.current?.getContent()
    setCurrentTrackedObject(content.blocks)
    articleLocking.onChange()
  }, [articleLocking])

  const isPreviewing = useMemo(
    () => previewSelected || !!article.isLockedBySomeoneElse,
    [previewSelected, article.isLockedBySomeoneElse]
  )

  return (
    <div className="flex-1">
      <ArticleActionToolbar
        isPreviewing={isPreviewing}
        isLocked={article.isLockedBySomeoneElse}
        save={saveArticle}
        preview={previewArticle}
        edit={() => setPreviewSelected(false)}
        article={article}
        isSaving={saveArticleResult.loading}
        isDirty={dirtyTracking.isDirty}
        options={{
          approve: !article.draftLesson,
          submitForReview: !article.draftLesson,
          requestEdits: !article.draftLesson,
          toggleHidden: !!article.draftLesson,
          changeVisibility: !article.draftLesson,
          edit: true,
          preview: true,
          toggleFeatured: !article.draftLesson,
          togglePremium: !article.draftLesson,
        }}
        backLink={{
          label: !!article.draftLesson ? "Back to course" : "Back to library",
          path: !!article.draftLesson
            ? editArticlePath({
                articleId: article.draftLesson.section.course.articleId,
              })
            : myContentPath.pattern,
        }}
      />

      {article.isLockedBySomeoneElse && article.lockedByUser && (
        <ArticleLockedBanner lockedByUser={article.lockedByUser} />
      )}

      <Card className="mt-0 mb-12 flex-1">
        <CardHeader>
          <CardTitle>
            {!!article.draftLesson ? "Lesson" : "Content"} Editor
          </CardTitle>
        </CardHeader>

        <EditorMetadataSection
          article={article}
          revisionHistoryDefaultOpen={searchParams.get("revisions") !== null}
        />

        <div className={clsx("py-12", { hidden: isPreviewing })}>
          <Editor
            ref={editorRef}
            articleId={articleId}
            initialData={article.draftRevision.editorjsContent}
            onChange={onChange}
          />
        </div>
        <div className={clsx({ hidden: !isPreviewing })}>
          {previewData && (
            <div className="mx-20 my-12">
              <RenderedArticle editorData={previewData} preview={false} />
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}

const getBlocks = (article: Article_EditorContentFragment) =>
  article.draftRevision.editorjsContent?.blocks

export const UPDATE_ARTICLE_MUTATION = gql(`
  mutation UpdateDemoArticle($input: ArticleUpdateInput!) {
    articleUpdate(input: $input) {
      article {
        ...Article_EditorContent
      }
    }
  }
`)
