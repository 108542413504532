import { useMemo, useState } from "react"
import { useCommunity } from "~/community/useCommunity"
import { Celebration } from "~/__generated__/graphql"
import { useCreateCelebration } from "./useCreateCelebration"
import { Button } from "~/shadcn/ui/button"
import { celebrationTypes, otherCelebrationType } from "./celebrationTypes"
import { CelebrationForm } from "./CelebrationForm"
import { displayErrors } from "~/common/validations"

type AddCelebrationProps = {
  onCelebrationAdded: (celebration: Celebration) => void
  currentCelebrations: Partial<Celebration>[]
}

export const AddCelebration = ({
  onCelebrationAdded,
  currentCelebrations,
}: AddCelebrationProps) => {
  const community = useCommunity()
  const [date, setDate] = useState<Date>()
  const { create, loading } = useCreateCelebration()

  const [celebrationType, setCelebrationType] = useState(celebrationTypes[0])

  const enabledCelebrationTypes = useMemo(() => {
    const enabled = celebrationTypes.filter(
      (type) =>
        community.enabledCelebrationTypes.includes(type.value) &&
        !currentCelebrations.some(
          (celebration) => celebration.celebrationType === type.value
        )
    )

    // If enabled does not contain celebrationType, change celebrationType to enabled[0]
    if (
      enabled.length &&
      !enabled.some((type) => type.value === celebrationType?.value) &&
      celebrationType?.value !== otherCelebrationType.value
    ) {
      setCelebrationType(enabled[0])
    }

    return enabled
  }, [community, currentCelebrations, celebrationType])

  const onAdd = async () => {
    const { data, errors } = await create({
      date: date?.toISOString() ?? "",
      celebrationType: celebrationType.value,
      customCelebrationType:
        celebrationType.value === otherCelebrationType.value
          ? celebrationType.customLabel
          : undefined,
    })
    if (data?.subject?.celebration) {
      onCelebrationAdded(data.subject.celebration as Celebration)
      setDate(undefined)
      setCelebrationType(enabledCelebrationTypes[0])
    } else {
      displayErrors(errors)
    }
  }

  return enabledCelebrationTypes.length === 0 ? (
    <></>
  ) : (
    <>
      <div className="flex flex-row space-x-4 items-center">
        <CelebrationForm
          celebrationType={celebrationType}
          date={date}
          isDisabled={loading}
          availableTypes={enabledCelebrationTypes}
          onChangeCelebrationType={setCelebrationType}
          onChangeDate={setDate}
        />
        <div>
          <Button type="submit" onClick={onAdd} disabled={loading || !date}>
            Add
          </Button>
        </div>
      </div>
    </>
  )
}
