import { TierIntervalEnum, TierLevelEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { LoadingStep } from "./LoadingStep"
import { PricingTableTier } from "./PricingTableStep"
import toast from "react-hot-toast"

export const MigrateToTierStep = () => {
  const { currentUser } = useCurrentUser()
  const { goToStep, meta, addToMeta } = useWizard()
  const { setSubscription, migrateToTier, redirectToStripe } = useSubscription()

  const { selectedTier, selectedInterval } = useMemo(() => {
    return {
      selectedTier: meta.selectedTier as PricingTableTier | null,
      selectedInterval: meta.selectedInterval as TierIntervalEnum | null,
    }
  }, [meta.selectedTier, meta.selectedInterval])

  const isDowngrading = useMemo(() => {
    if (!selectedTier) return false
    return selectedTier.position < (currentUser.tier?.position || 0)
  }, [currentUser, selectedTier])

  const isCrossgrading = useMemo(() => {
    if (!selectedTier) return false
    return selectedTier.level === currentUser.tier?.level
  }, [currentUser, selectedTier])

  const isUpgrading = useMemo(() => {
    if (!selectedTier) return false
    return selectedTier.position > (currentUser.tier?.position || 0)
  }, [currentUser, selectedTier])

  const isCancellationConfirmed = useMemo(() => {
    return !!meta.isCancellationConfirmed
  }, [meta.isCancellationConfirmed])

  const initialized = useRef(false)

  const changeTier = useCallback(async () => {
    if (
      !(
        selectedTier &&
        selectedInterval &&
        (isDowngrading || isCrossgrading || isUpgrading)
      )
    ) {
      toast.error("An unexpected error has occurred. Please try again later.")
      return
    }

    if (isDowngrading && !isCancellationConfirmed) {
      if (selectedTier.level === TierLevelEnum.Free) {
        goToStep("CancellationReasonStep")
      } else {
        goToStep("PreSpecialOfferStep", "forward", false)
      }
      return
    }

    const { data, errors, needsPaymentMethod } = await migrateToTier(
      selectedTier.level,
      selectedInterval
    )

    if (errors && !needsPaymentMethod) return

    if (data) {
      setSubscription(data)
    }

    if (needsPaymentMethod) {
      toast.success("Redirecting to Stripe to update payment method...")
      redirectToStripe(selectedTier.level, selectedInterval)
      return
    }

    if (isCrossgrading) {
      goToStep("SameTierSuccessStep")
    } else if (isDowngrading) {
      goToStep("CancelledStep")
    } else {
      addToMeta("latestTier", selectedTier)

      if (data?.latestInvoice) {
        addToMeta("latestInvoice", data.latestInvoice)
      }

      if (data?.upcomingInvoice) {
        addToMeta("upcomingInvoice", data.upcomingInvoice)
      }

      goToStep("PostUpgradeStep")
    }
  }, [
    selectedTier,
    selectedInterval,
    isDowngrading,
    isCrossgrading,
    isUpgrading,
    migrateToTier,
    setSubscription,
    goToStep,
    addToMeta,
    isCancellationConfirmed,
    redirectToStripe,
  ])

  const isReady = useMemo(() => {
    return (
      selectedTier &&
      selectedInterval &&
      (isDowngrading || isCrossgrading || isUpgrading)
    )
  }, [
    selectedTier,
    selectedInterval,
    isDowngrading,
    isCrossgrading,
    isUpgrading,
  ])

  useEffect(() => {
    if (isReady && !initialized.current) {
      initialized.current = true
      changeTier()
    }
  }, [changeTier, isReady])

  return <LoadingStep />
}

MigrateToTierStep.displayName = "MigrateToTierStep"
