import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { Popover, PopoverTrigger } from "~/shadcn/ui/popover"
import { PopoverContent } from "@radix-ui/react-popover"
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandList,
} from "~/shadcn/ui/command"
import { CommandEmpty, CommandItem } from "cmdk"
import { Check, ChevronDown } from "lucide-react"
import { cn } from "~/common/shadcn-utils"
import { useState } from "react"

type SelectEntry = {
  label: string
  value: string
}

export const SearchableSelectField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  label,
  labelClassName,
  containerClassName,
  required,
  selectEntries,
  placeholder,
}: {
  label: string
  labelClassName?: string
  containerClassName?: string
  description?: string
  control: Control<TFieldValues> | undefined
  name: TName
  required?: boolean
  selectEntries: SelectEntry[]
  placeholder?: string
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={containerClassName}>
          <FormLabel className={labelClassName} required={required}>
            {label}
          </FormLabel>

          <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <div
                  className={cn(
                    "flex h-10 w-full items-center justify-between rounded-md border border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-placeholder focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 cursor-pointer"
                  )}
                >
                  <div>{field.value}</div>
                  <ChevronDown className="opacity-50" size={16} />
                </div>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-full min-w-[8rem] relative z-50 max-h-96 overflow-hidden rounded-md border border-slate-200 shadow-md popover-content-width-same-as-its-trigger">
              <Command>
                <CommandInput placeholder="Search language..." />
                <CommandList>
                  <CommandEmpty>No results found.</CommandEmpty>
                  <CommandGroup>
                    {selectEntries.map((entry) => (
                      <CommandItem
                        value={entry.label}
                        key={entry.value}
                        onSelect={() => {
                          field.onChange(entry.value)
                          setPopoverOpen(false)
                        }}
                        className="flex items-center gap-2 py-2 hover:bg-slate-100 rounded"
                      >
                        <Check
                          className={cn(
                            "ml-1 h-4 w-4",
                            entry.value === field.value
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {entry.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>

          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
