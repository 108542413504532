import { Outlet, ScrollRestoration } from "react-router-dom"
import { EventsProvider } from "~/analytics/EventsContext"
import { CurrentUserProvider } from "~/auth/CurrentUserContext"
import { NewFeedPostProvider } from "~/feed/NewFeedPostContext"
import { PostReadProvider } from "~/feed/PostReadContext"
import { TiersProvider } from "~/tiers/TiersProvider"
import { ConfirmProvider } from "~/ui/Confirm"

export const RootLayout = () => {
  return (
    <EventsProvider>
      <TiersProvider>
        <CurrentUserProvider>
          <ConfirmProvider>
            <PostReadProvider>
              <NewFeedPostProvider>
                <Outlet />
                <ScrollRestoration />
              </NewFeedPostProvider>
            </PostReadProvider>
          </ConfirmProvider>
        </CurrentUserProvider>
      </TiersProvider>
    </EventsProvider>
  )
}
