import { useEffect, useMemo, useState } from "react"
import { useCommunity } from "~/community/useCommunity"
import { Celebration } from "~/__generated__/graphql"
import { useUpdateCelebration } from "./useUpdateCelebration"
import { Button } from "~/shadcn/ui/button"
import {
  celebrationTypes,
  celebrationTypesByValue,
  otherCelebrationType,
} from "./celebrationTypes"
import { CelebrationForm } from "./CelebrationForm"
import { displayErrors } from "~/common/validations"
import { toCalendarDate } from "~/common/formatDate"

type UpdateCelebrationProps = {
  celebration: Celebration
  currentCelebrations: Partial<Celebration>[]
  onCelebrationUpdated: (celebration: Celebration) => void
}

export const UpdateCelebration = ({
  celebration,
  onCelebrationUpdated,
  currentCelebrations,
}: UpdateCelebrationProps) => {
  const community = useCommunity()
  const { update, loading } = useUpdateCelebration()

  const [celebrationType, setCelebrationType] = useState(
    celebration.celebrationType === otherCelebrationType.value
      ? {
          ...otherCelebrationType,
          customLabel: celebration.customCelebrationType!,
        }
      : celebrationTypesByValue[celebration.celebrationType]
  )
  const [date, setDate] = useState<Date | undefined>(
    toCalendarDate(new Date(celebration.date))
  )

  useEffect(() => {
    setCelebrationType(
      celebration.celebrationType === otherCelebrationType.value
        ? {
            ...otherCelebrationType,
            customLabel: celebration.customCelebrationType!,
          }
        : celebrationTypesByValue[celebration.celebrationType]
    )
  }, [celebration.customCelebrationType, celebration.celebrationType])

  useEffect(() => {
    setDate(toCalendarDate(new Date(celebration.date)))
  }, [celebration.date])

  const enabledCelebrationTypes = useMemo(() => {
    const enabled = celebrationTypes.filter((type) => {
      const isEnabled = community.enabledCelebrationTypes.includes(type.value)
      const isInCurrentCelebrations = currentCelebrations.some(
        (celebration) => celebration.celebrationType === type.value
      )
      const isCurrentCelebrationType =
        type.value === celebration.celebrationType

      return isEnabled && (isCurrentCelebrationType || !isInCurrentCelebrations)
    })
    return enabled
  }, [community, currentCelebrations, celebration.celebrationType])

  const onUpdate = async () => {
    const { data, errors } = await update({
      id: celebration.id,
      date: date?.toISOString() ?? "",
      celebrationType: celebrationType.value,
      customCelebrationType:
        celebrationType.value === otherCelebrationType.value
          ? celebrationType.customLabel
          : "",
    })

    if (data?.subject?.celebration) {
      onCelebrationUpdated(data.subject.celebration as Celebration)
    } else {
      displayErrors(errors)
    }
  }

  return enabledCelebrationTypes.length === 0 ? (
    <></>
  ) : (
    <>
      <div className="flex space-x-4 items-center">
        <CelebrationForm
          celebrationType={celebrationType}
          date={date}
          isDisabled={loading}
          availableTypes={enabledCelebrationTypes}
          onChangeCelebrationType={setCelebrationType}
          onChangeDate={setDate}
        />
        <div>
          <Button type="submit" onClick={onUpdate} disabled={loading || !date}>
            Save
          </Button>
        </div>
      </div>
    </>
  )
}
