import { useState, ChangeEvent } from "react"
import { useManualHrisDataContext } from "~/merge/manualData"

import { Input } from "~/shadcn/ui/input"

import { Base } from "./Base"
import { getMetaVarMaybe } from "~/common/getMetaVar"

const formatAsCurrency = (input: string) => {
  return `$${input.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}

const ARTICLE_ID = getMetaVarMaybe("hris-cost-per-employee-article-id")

export const ManualCostPerEmployee = ({
  editContext = false,
  closeDialog,
}: {
  editContext?: boolean
  closeDialog?: () => void
}) => {
  const { onSuccessCallback, next, prev, costPerEmployee, setCostPerEmployee } =
    useManualHrisDataContext()

  const [inputValue, setInputValue] = useState(
    costPerEmployee ? formatAsCurrency(costPerEmployee.toString()) : ""
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replaceAll(/[$.,]/g, "")
    updateInputs(value)
  }

  const updateInputs = (input: string) => {
    let newValue

    if (/^\d*$/.test(input)) {
      newValue = input === "" ? "" : formatAsCurrency(input)

      setInputValue(newValue)
      setCostPerEmployee(input === "" ? undefined : Number(input))
    }
  }

  return (
    <Base
      header="Cost Per Employee"
      title="Cost Per Employee (dollar amount)"
      prev={prev}
      next={next}
      editContext={editContext}
      submit={() => {
        onSuccessCallback()
        closeDialog && closeDialog()
      }}
      articleId={ARTICLE_ID && ARTICLE_ID.length > 0 ? ARTICLE_ID : null}
      disabled={costPerEmployee === undefined}
    >
      <div className="text-xs font-medium tracking-wide text-textLight mb-1">
        Over the past 6 months, what has your company's cost per employee been?
        <span className="text-red-400">*</span>
      </div>
      <div className="mb-6">
        <Input
          type="text"
          value={inputValue}
          placeholder={"$50,000"}
          onChange={handleChange}
        />
      </div>
    </Base>
  )
}
