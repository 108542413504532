import { Dialog, DialogContent } from "~/shadcn/ui/dialog"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Button } from "~/shadcn/ui/button"
import { useEffect } from "react"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import toast from "react-hot-toast"
import { USER_ADMIN_UPDATE_MUTATION } from "~/screens/admin/AdminMembersScreen"

const formSchema = z.object({
  email: z.string().regex(/^[^@\s]+@[^@\s]+$/),
})

export type ChangeEmailFormValues = z.infer<typeof formSchema>

export const ChangeEmailModal = ({
  isOpen,
  setIsOpen,
  userId,
  email,
  name,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  email: string
  userId: string
  name?: string | null
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: email,
    },
  })

  const [runUserUpdate, userUpdateResult] = useSafeMutation(
    USER_ADMIN_UPDATE_MUTATION
  )

  const onSubmit = async (values: ChangeEmailFormValues) => {
    const { errors } = await runUserUpdate({
      variables: { input: { userId, email: values.email } },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      toast.success("Updated user email address")
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen === false) form.reset()
  }, [isOpen, form])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="mb-2 font-serif text-3xl">Update Email</div>
        <div className="mb-6 text-xs">
          Update a user's email. Stripe and Sailthru will receive these updates.
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel required>{name && `${name}'s `} email</FormLabel>
                  <div>
                    <FormControl>
                      <input
                        type="text"
                        className="bg-white w-full"
                        placeholder="email@example.com"
                        {...field}
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="h-[1px] bg-gray-300 my-8" />

            <div className="flex justify-end items center gap-4">
              <Button
                type="button"
                className="px-10"
                onClick={() => setIsOpen(false)}
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="px-10"
                disabled={userUpdateResult.loading}
                isLoading={userUpdateResult.loading}
              >
                Save Email
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
