import {
  ArticleContentTypeEnum,
  ArticleStateEnum,
  ArticleVisibilityEnum,
} from "~/__generated__/graphql"

export const ARTICLE_CONTENT_TYPE_LABELS: Record<
  ArticleContentTypeEnum,
  string
> = {
  COURSE: "Course",
  LESSON: "Lesson",
  GUIDE: "Guide",
  REPLAY: "Replay",
  TEMPLATE: "Template",
  EXERCISE: "Exercise",
  REPORT: "Report",
  FINANCIAL_ANALYSIS: "Financial Analysis",
  RESEARCH: "Research",
  DEEP_DIVE: "Deep Dive",
  NOTE: "Note",
  SWIPE_FILE: "Swipe File",
  PLAYBOOK: "Playbook",
  CASE_STUDY: "Case Study",
}

export const ARTICLE_VISIBILITY_LABELS: Record<ArticleVisibilityEnum, string> =
  {
    LIVE: "Live",
    HIDDEN: "Hidden",
  }

export const ARTICLE_STATE_LABELS: Record<ArticleStateEnum, string> = {
  DRAFT: "Draft",
  SUBMITTED: "Submitted",
  APPROVED: "Approved",
  EDITS_REQUESTED: "Edits Requested",
  HAS_PENDING_EDITS: "Approved; Pending edits",
}
