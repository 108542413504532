import { add, parseISO, startOfDay } from "date-fns"
import { forwardRef, useState } from "react"
import Toggle from "react-toggle"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { displayErrors } from "~/common/validations"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { CalendarDateField } from "~/ui/CalendarDateField"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { Button } from "~/shadcn/ui/button"
import { Lock } from "lucide-react"

export const useIntroductionsSnoozedUntilMutation = () => {
  const { currentUser } = useCurrentUser()
  const [date, setDate] = useState<Date | null>(
    currentUser.introductionsSnoozedUntil
      ? parseISO(currentUser.introductionsSnoozedUntil)
      : null
  )

  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const { logEvent } = useLogEvent()

  const updateIntroductionsSnoozedUntil = async (newDate: Date | null) => {
    const previousDate = date
    setDate(newDate)
    const introductionsSnoozedUntilVal = newDate ? startOfDay(newDate) : null

    const { errors } = await runUserUpdate({
      variables: {
        input: {
          introductionsSnoozedUntil: introductionsSnoozedUntilVal,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      setDate(previousDate)
    } else {
      if (introductionsSnoozedUntilVal) {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "ACTIVE",
        })
      } else {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "SNOOZED",
        })
      }
    }
  }

  return { updateIntroductionsSnoozedUntil, date, setDate }
}

export const IntroductionsSettingsModule = forwardRef(
  (_, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { updateIntroductionsSnoozedUntil, date } =
      useIntroductionsSnoozedUntilMutation()
    const { openSubscriptionWizard } = useSubscription()
    const { currentUser } = useCurrentUser()
    const canUseIntroductions = currentUser.permissions.canUseIntroductions

    return (
      <Card ref={ref}>
        <CardHeader>
          <CardTitle>Introductions Settings</CardTitle>
        </CardHeader>
        <CardContent className="pt-4">
          <div className="mb-6">
            Manage your introductions to other members of the community.
          </div>
          {canUseIntroductions ? (
            <>
              <div className="text-textLight font-semibold mb-2">
                Snooze Introductions?
              </div>
              <div className="flex items-center gap-4 mb-4">
                <Toggle
                  defaultChecked={!!date}
                  icons={false}
                  onChange={(e) => {
                    e.target.checked
                      ? updateIntroductionsSnoozedUntil(
                          add(new Date(), { months: 1 })
                        )
                      : updateIntroductionsSnoozedUntil(null)
                  }}
                />
                <div className="text-xs text-textTimestamp">
                  Snooze {date ? "ON" : "OFF"}
                </div>
              </div>
            </>
          ) : (
            <Button
              type="button"
              onClick={() =>
                openSubscriptionWizard("PricingTableStep", {
                  source: "AccountSettingsScreen",
                })
              }
            >
              Enable Introductions <Lock className="w-4 h-4 ml-1" />
            </Button>
          )}
          {canUseIntroductions && date && (
            <>
              <div className="text-textLight font-semibold mb-2">
                Snooze Until?
              </div>
              <CalendarDateField
                date={date}
                onChangeDate={(date) => {
                  if (date) {
                    updateIntroductionsSnoozedUntil(date || null)
                  }
                }}
                fromYear={new Date().getFullYear()}
                toYear={new Date().getFullYear() + 2}
                disabled={{
                  before: new Date(),
                  after: add(new Date(), { years: 2 }),
                }}
              />
            </>
          )}
        </CardContent>
      </Card>
    )
  }
)
