import { DialogProps } from "@radix-ui/react-dialog"
import { useCallback, useEffect, useState } from "react"
import { Dialog, DialogContent } from "~/shadcn/ui/dialog"
import { OnboardingTitle } from "~/ui/Onboarding"
import { Trans } from "react-i18next"
import {
  OnboardingExperienceQuestions,
  useMissingOnboardingExperienceFields,
} from "./OnboardingExperienceQuestions"
import { useCurrentUser } from "~/auth/CurrentUserContext"

interface OnboardingExperienceModalProps
  extends Omit<DialogProps, "open" | "onOpenChange"> {}

export const OnboardingExperienceModal = ({
  ...dialogProps
}: OnboardingExperienceModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser } = useCurrentUser()

  const onSave = useCallback(() => {
    setIsOpen(false)
  }, [])

  const missingFields = useMissingOnboardingExperienceFields()
  useEffect(() => {
    setIsOpen(currentUser.onboarded && missingFields.length > 0)
  }, [currentUser, missingFields])

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen} {...dialogProps}>
      <DialogContent className="w-2/3 max-w-xl gap-0" noCloseButton>
        <OnboardingTitle className="mb-2">
          <Trans i18nKey="experienceScreen.title" ns="onboarding" />
        </OnboardingTitle>

        <div className="text-center mb-6 text-2xs font-medium">
          <Trans i18nKey="experienceScreen.oops" ns="onboarding" />
        </div>

        <OnboardingExperienceQuestions onSave={onSave} withCard={false} />
      </DialogContent>
    </Dialog>
  )
}
