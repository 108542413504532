import * as React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { XCircle } from "lucide-react"

import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "~/common/shadcn-utils"

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

type DialogOverlayProps = React.ComponentPropsWithoutRef<
  typeof DialogPrimitive.Overlay
> & {
  darkenBackground?: boolean
}

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  DialogOverlayProps
>(({ className, darkenBackground = false, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
      {
        "bg-black/80": darkenBackground,
      }
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const dialogContentVariants = cva(
  [
    "dialog bg-card text-foreground",
    "fixed left-[50%] top-[50%] z-50 grid w-full max-w-2xl translate-x-[-50%] translate-y-[-50%] gap-4 shadow-lg",
    "duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0",
    "data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
    "data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]",
    "data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]",
    "bg-background text-foreground",
  ].join(" "),
  {
    variants: {
      variant: {
        default: "border border-slate-200 rounded-lg p-6",
        ghost: "bg-transparent",
        tight: "border border-slate-200 rounded-lg",
        wizard:
          "border border-slate-200 rounded-lg transition-all duration-300 ease-in overflow-y-auto max-w-screen md:max-w-screen w-fit max-h-screen p-0 box-content",
        gallery:
          "bg-transparent p-0 border-none rounded-none shadow-none w-fit h-fit outline-none",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

interface DialogContentProps
  extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>,
    VariantProps<typeof dialogContentVariants> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  noCloseButton?: boolean
  darkenBackground?: boolean
}

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(
  (
    {
      className,
      variant,
      children,
      setOpen,
      noCloseButton,
      darkenBackground = true,
      ...props
    },
    ref
  ) => {
    return (
      <DialogPortal>
        <DialogOverlay darkenBackground={darkenBackground} />
        <DialogPrimitive.Content
          ref={ref}
          className={cn(dialogContentVariants({ variant, className }))}
          {...props}
        >
          {children}
          {!noCloseButton && (
            <DialogPrimitive.Close className="absolute right-4 top-4 text-slate-500 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500 dark:data-[state=open]:bg-slate-800 dark:data-[state=open]:text-slate-400">
              <XCircle
                className="h-4 w-4"
                onClick={() => {
                  setOpen && setOpen(false)
                }}
              />
              <span className="sr-only">Close</span>
            </DialogPrimitive.Close>
          )}
        </DialogPrimitive.Content>
      </DialogPortal>
    )
  }
)
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn("flex flex-col space-y-1.5 text-center py-4", className)}
    {...props}
  />
)
DialogHeader.displayName = "DialogHeader"

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-row justify-between items-center space-x-2",
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter"

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-3xl font-semibold text-foreground font-serif",
      className
    )}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-slate-500 dark:text-slate-400", className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}
