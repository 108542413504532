import { DotsHorizontalIcon, EyeNoneIcon } from "@radix-ui/react-icons"
import { formatDate } from "date-fns"
import { useRef, useState } from "react"
import {
  User_AdminFragment,
  ExportFormatTypeEnum,
  TierLevelEnum,
  TierIntervalEnum,
  UserSortEnum,
} from "~/__generated__/graphql"
import { UserDialog, userLocation } from "~/directory/UserDialog"
import { Badge } from "~/shadcn/ui/badge"
import { Button } from "~/shadcn/ui/button"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from "~/shadcn/ui/context-menu"
import { TableCell, TableRow } from "~/shadcn/ui/table"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { ChangeEmailModal } from "../ChangeEmailModal"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_ADMIN_UPDATE_MUTATION } from "~/screens/admin/AdminMembersScreen"
import { displayErrors } from "~/common/validations"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/shadcn/ui/tooltip"
import { formatCalendarDate } from "~/common/formatDate"
import { celebrationTypesByValue } from "~/celebrations"
import { UserTableHeader, UsersTable } from "./UsersTable"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { useConfirm } from "~/ui/Confirm"
import { USER_CHANGE_TIER_MUTATION } from "~/subscriptions/SubscriptionProvider"
import { UserName } from "~/directory/UserName"
import { CopyToClipboard } from "~/ui/CopyToClipboard"
import copy from "copy-to-clipboard"

const HEADERS: UserTableHeader[] = [
  { label: "Name", isPinned: true },
  { label: "Date Joined" },
  { label: "Homebase" },
  { label: "Title" },
  { label: "Company" },
  { label: "Subscription" },
  { label: "Tier" },
  { label: "Expertise" },
  { label: "Interests" },
  { label: "Celebrations" },
  { label: "Type" },
  { label: "Hidden" },
  { label: "Onboarded" },
  { label: "Actions" },
]

const pinnedColumnStyles =
  "sticky left-0 bg-gradient-to-r from-white from-80% to-transparent group-hover:from-muted group-hover:from-80% group-hover:to-transparent"

const UserRow = ({ user }: { user: User_AdminFragment }) => {
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false)
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const showConfirm = useConfirm()
  const [runUserUpdate] = useSafeMutation(USER_ADMIN_UPDATE_MUTATION)
  const [runUserChangeTier] = useSafeMutation(USER_CHANGE_TIER_MUTATION)

  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)

  const toggleHidden = async () => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          userId: user.id,
          hidden: !user.hidden,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    }
  }

  const toggleCoach = async () => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          userId: user.id,
          coach: !user.coach,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    }
  }

  const migrateToTier = async (
    tier: TierLevelEnum,
    interval: TierIntervalEnum
  ) => {
    await showConfirm({
      title: `Migrate ${user.name} to ${tier} tier?`,
      body: `⚠️ This will charge the customer the new tier price. Are you sure you want to continue?`,
      confirmText: "Yes, migrate",
      cancelText: "Cancel",
      onConfirm: async () => {
        const { errors } = await runUserChangeTier({
          variables: {
            input: {
              userId: user.id,
              tierLevel: tier,
              interval: interval,
            },
          },
        })

        if (errors) {
          displayErrors(errors)
        }
      },
    })
  }

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  return (
    <>
      {changeEmailModalOpen && (
        <ChangeEmailModal
          userId={user.id}
          email={user.email || ""}
          isOpen={changeEmailModalOpen}
          setIsOpen={setChangeEmailModalOpen}
          name={user.name}
        />
      )}

      {userDialogOpen && (
        <UserDialog
          isOpen={userDialogOpen}
          onClose={() => setUserDialogOpen(false)}
          user={user}
        />
      )}

      <ContextMenu>
        <ContextMenuContent>
          <ContextMenuItem onClick={toggleHidden} className="cursor-pointer">
            {user.hidden ? "Unhide" : "Hide"}
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() =>
              user.email && copy(user.email) && toast.success("Email copied")
            }
            className="cursor-pointer"
            disabled={!user.email}
          >
            Copy Email Address
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() => setChangeEmailModalOpen(true)}
            className="cursor-pointer"
          >
            Change Email
          </ContextMenuItem>
          <ContextMenuSub>
            <ContextMenuSubTrigger>
              Migrate to Tier (Charges Customer)
            </ContextMenuSubTrigger>
            <ContextMenuSubContent className="w-48">
              <ContextMenuItem
                onClick={() =>
                  migrateToTier(TierLevelEnum.Free, TierIntervalEnum.Year)
                }
              >
                Free
              </ContextMenuItem>
              <ContextMenuItem
                onClick={() =>
                  migrateToTier(TierLevelEnum.Plus, TierIntervalEnum.Quarter)
                }
              >
                Plus (Quarterly)
              </ContextMenuItem>
              <ContextMenuItem
                onClick={() =>
                  migrateToTier(TierLevelEnum.Plus, TierIntervalEnum.Year)
                }
              >
                Plus (Yearly)
              </ContextMenuItem>
              <ContextMenuItem
                onClick={() =>
                  migrateToTier(TierLevelEnum.Pro, TierIntervalEnum.Year)
                }
              >
                Pro
              </ContextMenuItem>
            </ContextMenuSubContent>
          </ContextMenuSub>
          <ContextMenuItem onClick={toggleCoach} className="cursor-pointer">
            {user.coach ? "Unmark as Coach" : "Mark as Coach"}
          </ContextMenuItem>
        </ContextMenuContent>
        <ContextMenuTrigger asChild>
          <TableRow ref={contextMenuTriggerRef} className="group">
            <TableCell className={pinnedColumnStyles}>
              <div className="flex gap-2">
                <div className="w-[30px] h-[30px]">
                  <AvatarWithFallback user={user} size="post-autocomplete" />
                </div>
                <div className="flex flex-col gap-1 items-start">
                  <div className="flex gap-2 items-center">
                    <Button
                      variant="link"
                      size="inline"
                      className="text-primary"
                      onClick={() => setUserDialogOpen(true)}
                    >
                      <UserName user={user} />
                    </Button>
                    {user.hidden && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <EyeNoneIcon className="text-pretext-grey" />
                          </TooltipTrigger>
                          <TooltipContent>
                            User is hidden from the directory
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                  {user.email && (
                    <CopyToClipboard
                      className="text-2xs text-gray-500"
                      text={user.email}
                    >
                      <div className="truncate max-w-[140px]">{user.email}</div>
                    </CopyToClipboard>
                  )}
                </div>
              </div>
            </TableCell>
            <TableCell>
              <span className="text-xs">
                {formatDate(user.createdAt, "MMM d, yyyy")}
              </span>
            </TableCell>
            <TableCell>
              <div className="whitespace-nowrap overflow-hidden text-ellipsis text-xs">
                {userLocation(user.place) || "New York, NY"}
              </div>
            </TableCell>
            <TableCell>
              <div className="whitespace-nowrap overflow-hidden text-ellipsis text-xs">
                {user.jobTitle || "N/A"}
              </div>
            </TableCell>
            <TableCell>
              <div className="whitespace-nowrap overflow-hidden text-ellipsis text-xs">
                {user.companyName || "N/A"}
              </div>
            </TableCell>
            <TableCell>
              <span className="text-xs">
                {user.activeStripeSubscription?.status || "N/A"}
              </span>
            </TableCell>
            <TableCell>
              <span className="text-xs">
                {user.tier ? (
                  <>
                    {user.tier.name}
                    {user.tier.level !== TierLevelEnum.Free &&
                      user.activeStripeSubscription?.plan &&
                      `-${
                        user.activeStripeSubscription.plan.interval === "year"
                          ? "Yearly"
                          : "Quarterly"
                      }`}
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap gap-1">
                {user.expertise.map((tag) => (
                  <Badge key={tag.id} variant="secondary" className="text-3xs">
                    {tag.name}
                  </Badge>
                ))}
              </div>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap gap-1">
                {user.interests.map((tag) => (
                  <Badge key={tag.id} variant="highlight" className="text-3xs">
                    {tag.name}
                  </Badge>
                ))}
              </div>
            </TableCell>
            <TableCell>
              <div className="flex flex-wrap gap-1">
                {user.celebrations.map(
                  ({ id, celebrationType, customCelebrationType, date }) => (
                    <Badge
                      key={id}
                      variant="outline"
                      className="text-3xs flex gap-2"
                    >
                      <span>
                        {celebrationTypesByValue[celebrationType].emoji}
                      </span>
                      {celebrationType === "other" && (
                        <span>{customCelebrationType}</span>
                      )}
                      {date && (
                        <span>{formatCalendarDate(date, "MMM d, yyyy")}</span>
                      )}
                    </Badge>
                  )
                )}
              </div>
            </TableCell>
            <TableCell>
              {user.admin ? (
                <Badge variant="primary">Admin</Badge>
              ) : user.coach ? (
                <Badge variant="success">Coach</Badge>
              ) : (
                <Badge variant="default">User</Badge>
              )}
            </TableCell>
            <TableCell>
              <Badge variant={user.hidden ? "warning" : "success"}>
                {user.hidden ? "Hidden" : "Visible"}
              </Badge>
            </TableCell>
            <TableCell>
              <Badge variant={user.onboarded ? "success" : "warning"}>
                {user.onboarded ? "Onboarded" : "Not Onboarded"}
              </Badge>
            </TableCell>
            <TableCell>
              <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
                <DotsHorizontalIcon />
              </Button>
            </TableCell>
          </TableRow>
        </ContextMenuTrigger>
      </ContextMenu>
    </>
  )
}

export const AllMembersTable = () => {
  const [runUsersExport, { loading }] = useSafeMutation(USERS_EXPORT_MUTATION)

  return (
    <UsersTable
      withFilters={["place", "expertises", "interests", "subscriptionStatus"]}
      withSearch
      withExport
      withExportLoading={loading}
      defaultSort={UserSortEnum.Recent}
      headers={HEADERS}
      queryOptions={{ activeOnly: true, fitOnly: true }}
      onExport={() => {
        runUsersExport({
          variables: {
            input: { format: ExportFormatTypeEnum.Csv },
          },
        }).then((data) => {
          if (data.errors) {
            displayErrors(data.errors)
          } else {
            toast.success(
              "Export users in progress. You'll recieve an email when the export is complete"
            )
          }
        })
      }}
    >
      {(user) => <UserRow key={user.id} user={user} />}
    </UsersTable>
  )
}

const USERS_EXPORT_MUTATION = gql(`
  mutation UsersExport($input: UsersExportInput!) {
    usersExport(input: $input) {
      success
    }
  }
`)
