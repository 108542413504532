import { useEffect, useMemo, useState } from "react"
import { cn } from "~/common/shadcn-utils"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { LatLng, NullLatLng } from "~/common/useDeviceLocation"

import { APIProvider } from "@vis.gl/react-google-maps"
import { getMetaVar } from "~/common/getMetaVar"
import { Switch } from "~/shadcn/ui/switch"
import { Dialog, DialogContent } from "~/shadcn/ui/dialog"
import AddCircle from "~/images/icons/add-circle.svg?react"
import MinusCircle from "~/images/icons/minus-circle.svg?react"
import { UserLocationMap } from "./UserLocationMap"
import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { CurrentLocation } from "~/__generated__/graphql"
import { Button } from "~/shadcn/ui/button"
import { useUserDialogContext } from "~/directory/UserDialogContext"

const CITY_ZOOM_LEVEL = 12
const REGION_ZOOM_LEVEL = 11
const MapDialog = ({
  isOpen,
  setIsOpen,
  mapCenter,
  zoomLevel,
  userLocations,
}: {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  mapCenter: LatLng
  zoomLevel: number
  userLocations: CurrentLocation[] | null
}) => {
  const { userDialogOpen } = useUserDialogContext()

  return (
    <Dialog open={isOpen} onOpenChange={(val) => setIsOpen(val)}>
      <DialogContent
        onEscapeKeyDown={() => setIsOpen(false)}
        className="p-0 outline-0 bg-transparent border-0"
        darkenBackground={!userDialogOpen}
      >
        <Card className={cn("rounded-lg [box-shadow:none]")}>
          <CardHeader>
            <CardTitle>Peers Near You</CardTitle>
          </CardHeader>
          <CardContent className={cn("p-0")}>
            <div className="flex-grow rounded-b-lg overflow-hidden">
              {mapCenter && (
                <UserLocationMap
                  aspectClass="aspect-video"
                  mapCenter={mapCenter}
                  zoomLevel={zoomLevel}
                  userLocations={userLocations}
                />
              )}
            </div>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

export const DirectoryMap = ({
  className,
  expandable,
  isExpanded,
  setIsExpanded,
}: {
  className: string
  expandable: boolean
  isExpanded: boolean
  setIsExpanded: (open: boolean) => void
}) => {
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const {
    currentUser,
    locationData: {
      updateUserLocation,
      enableLocation,
      setEnableLocationAndDevicePreference,
      deviceLatLng,
      fetchingDeviceLatLng,
      fetchingPermissions,
      locationServicesEnabled,
    },
  } = useCurrentUser()

  const apiKey = getMetaVar("maps-api-key")

  const currentUserPlaceLatLng = useMemo<LatLng | NullLatLng>(() => {
    return {
      lat: currentUser.place?.lat || null,
      lng: currentUser.place?.lng || null,
    }
  }, [currentUser.place])

  const { data, refetch } = useQuery(USER_LOCATIONS_QUERY_DOCUMENT, {
    pollInterval: 30_000,
  })

  useEffect(() => {
    refetch()
  }, [deviceLatLng, enableLocation, refetch])

  const mapCenter = useMemo(() => {
    if (
      locationServicesEnabled &&
      enableLocation &&
      deviceLatLng &&
      !fetchingDeviceLatLng
    ) {
      return deviceLatLng
    } else {
      return currentUser.place ? (currentUserPlaceLatLng as LatLng) : null
    }
  }, [
    locationServicesEnabled,
    enableLocation,
    deviceLatLng,
    fetchingDeviceLatLng,
    currentUser,
    currentUserPlaceLatLng,
  ])

  const usersForMap = useMemo<CurrentLocation[] | null>(() => {
    if (data?.userCurrentLocations) {
      return data.userCurrentLocations
    } else if (currentUserPlaceLatLng) {
      // Default to the current users place location
      return [
        {
          __typename: "CurrentLocation",
          id: currentUser.id,
          name: currentUser.name ?? "Unknown",
          lat: currentUserPlaceLatLng.lat as number,
          lng: currentUserPlaceLatLng.lng as number,
          live: false,
        },
      ]
    } else {
      return null
    }
  }, [currentUser, data, currentUserPlaceLatLng])

  const [mapEnlarged, setMapEnlarged] = useState<boolean>(false)

  return (
    <Card className={cn("[box-shadow:none]", className)}>
      <CardHeader
        className={cn(
          "flex-row justify-between items-center",
          expandable && !isExpanded && "border-b-0"
        )}
      >
        <CardTitle>Peers Near You</CardTitle>
        {expandable && (
          <div className="cursor-pointer" onClick={toggleExpanded}>
            {isExpanded ? (
              <MinusCircle className="text-ceriseRed" />
            ) : (
              <AddCircle className="text-primary" />
            )}
          </div>
        )}
      </CardHeader>
      {(!expandable || isExpanded) && (
        <CardContent className="p-4 pb-3 flex flex-col">
          <div className="flex justify-between items-center justify-center mb-2">
            <div className="flex space-x-2">
              <Switch
                variant="green"
                checked={enableLocation}
                disabled={
                  fetchingDeviceLatLng ||
                  fetchingPermissions ||
                  locationServicesEnabled === false
                }
                onCheckedChange={(val) => {
                  setEnableLocationAndDevicePreference(val)

                  if (val) {
                    updateUserLocation({ checkPermissions: false })
                  }
                }}
              />

              <div className="font-semibold text-xs tracking-wide">
                Enable Location?
              </div>
            </div>

            {!expandable && (
              <Button
                variant="link"
                size="tight"
                onClick={() => {
                  setMapEnlarged(true)
                }}
                className="text-xs text-primary tracking-wide"
              >
                enlarge
              </Button>
            )}
          </div>

          {mapCenter && (
            <APIProvider apiKey={apiKey}>
              <>
                <MapDialog
                  setIsOpen={setMapEnlarged}
                  isOpen={mapEnlarged}
                  mapCenter={mapCenter}
                  zoomLevel={REGION_ZOOM_LEVEL}
                  userLocations={usersForMap}
                />
                <div className="flex-grow rounded-lg overflow-hidden aspect-square">
                  <UserLocationMap
                    aspectClass="aspect-square"
                    mapCenter={mapCenter}
                    zoomLevel={CITY_ZOOM_LEVEL}
                    userLocations={usersForMap}
                  />
                </div>
              </>
            </APIProvider>
          )}
        </CardContent>
      )}
    </Card>
  )
}

const USER_LOCATIONS_QUERY_DOCUMENT = gql(`
  query UserCurrentLocations {
    userCurrentLocations {
      live
      name
      id
      lat
      lng
    }
  }
`)
