import { useState } from "react"
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { cn } from "~/common/shadcn-utils"
import { useLazyQuery } from "@apollo/client"
import AsyncSelect from "react-select/async"
import { useCallback } from "react"
import { MENTION_USER_QUERY_DOCUMENT } from "~/post-composer/useMentionDataSource"
import { useDebouncedCallback } from "use-debounce"

type UserOption = {
  value: string
  label: string
}

export const UserSelectField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  required,
  label,
  labelClassName,
  inputClassName,
  containerClassName,
  initialValue,
  placeholder,
}: {
  label: string
  labelClassName?: string
  inputClassName?: string
  containerClassName?: string
  description?: string
  required?: boolean
  control: Control<TFieldValues> | undefined
  name: TName
  initialValue?: { value: string; label: string }
  placeholder?: string
}) => {
  const [selectedUser, setSelectedUser] = useState<UserOption | null>(
    initialValue || null
  )

  const [getCollaboratorSearch] = useLazyQuery(MENTION_USER_QUERY_DOCUMENT, {
    variables: { excludeSelf: false },
  })

  const _loadOptions = useCallback(
    (query: string, callback: (options: UserOption[]) => void) => {
      getCollaboratorSearch({
        variables: { query },
      }).then(({ data }) => {
        if (!data) {
          callback([])
        } else {
          callback(
            data.users.nodes.map((u) => ({
              value: u.id,
              label: u.name || "",
            }))
          )
        }
      })
    },
    [getCollaboratorSearch]
  )
  const loadOptions = useDebouncedCallback(_loadOptions, 400)

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={containerClassName}>
          <FormLabel className={labelClassName}>
            {label}
            {required && <span className="text-red-500 pl-1">*</span>}
          </FormLabel>
          <FormControl>
            <AsyncSelect
              classNamePrefix="select"
              className={cn("flex-1", inputClassName)}
              placeholder={placeholder}
              value={selectedUser}
              loadOptions={loadOptions}
              onChange={(selection) => {
                if (selection?.value) {
                  field.onChange(selection.value)
                  setSelectedUser(selection)
                }
              }}
              styles={{
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "unset",
                }),
                control: (baseStyles, _state) => ({
                  ...baseStyles,
                  outline: "none !important",
                  boxShadow: "none",
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 6,
                  borderColor: "#6b7280",
                  borderRadius: 4,
                }),
              }}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
